import {
  BackgroundHeader,
  Container,
  DivBackgroundHeader,
  DivImgProfile,
  DivProfileDetail,
  ImgProfile,
  Role,
} from "./HeaderElements";
import header from "../../Images/headers/profile.svg";
import defaultProfile from "../../Images/profilePage/defualtProfile.svg";

const Header = ({ user }) => {
  return (
    <>
      <Container>
        <BackgroundHeader src={header} />
        <DivBackgroundHeader>
          <DivImgProfile>
            <ImgProfile src={defaultProfile} />
          </DivImgProfile>
          <DivProfileDetail>
            {user?.name || user?.family
              ? user?.name + " " + user?.family
              : "بدون نام"}
            <Role>
              {user?.trade_units?.[0]?.title
                ? user?.trade_units?.[0]?.title
                : "بدون واحد صنفی"}
            </Role>
          </DivProfileDetail>
        </DivBackgroundHeader>
      </Container>
    </>
  );
};

export default Header;
