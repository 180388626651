import React, { useEffect, useState } from "react";
import Header from "../../Components/Cart/Header";
import BodyShoppingCart from "../../Components/Cart/Body";
import BodyReadyCart from "../../Components/Cart/BodyReadyCart";
import axiosConfig from "../../ApiCall/axiosConfig";
import { useLocation, useOutletContext } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
const Index = () => {
  const [items, setItems] = useState([]);
  const [readyCartItems, setReadyCartItems] = useState([]);
  const [cart, setCart] = useState("shoppingCart");
  const [shoppingCartItemsId, setShoppingCartItemsId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countCart, setCountCart] = useState(null);
  let { updateBottomNavigation, updateCountCart } = useOutletContext();

  useEffect(() => {
    updateBottomNavigation(3);
    setLoading(true);
    let haveError = false;
    async function fetchData() {
      async function fetchReadyCart() {
        const token = localStorage.getItem("tokenManiarApp");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        try {
          const { data } = await axiosConfig.get("/ready_cart/list", config);
          setReadyCartItems(data.data);
        } catch (error) {
          console.log("Error fetching products:", error);
          haveError = true;
        }
      }
      async function fetchCart() {
        const token = localStorage.getItem("tokenManiarApp");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        try {
          const { data } = await axiosConfig.get(
            "/shopping_cart/item/list",
            config
          );
          setItems(data.data);
          setShoppingCartItemsId({
            ids: data.data?.items.map((item) => item.id),
          });
          setCountCart(data.data.items.length);
          updateCountCart(data.data?.items.length);
          localStorage.setItem("countCartItems", data.data?.items.length);
          setLoading(false);
        } catch (error) {
          console.log("Error fetching products:", error);
          haveError = true;
        }
      }
      await fetchReadyCart();
      await fetchCart();
      if (haveError) {
        showError();
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const updateData = (type) => {
    if (type === "addShoppingCartToReadyCart") {
      async function fetchShoppingCart() {
        const token = localStorage.getItem("tokenManiarApp");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        try {
          const { data } = await axiosConfig.get(
            "/shopping_cart/item/list",
            config
          );
          setItems(data.data);
          setCountCart(data.data.items.length);
          updateCountCart(data.data?.items.length);
          localStorage.setItem("countCartItems", data.data?.items.length);
          enqueueSnackbar("سبد آماده با موفقیت به سبد خرید اضافه شد.", {
            autoHideDuration: 2000,
            style: { backgroundColor: "#478620" },
          });
        } catch (error) {
          console.log("Error fetching products:", error);
          showError();
        }
      }
      fetchShoppingCart();
    } else if (type === "deleteCart") {
      setItems([]);
      setShoppingCartItemsId(null);
      setCountCart(0);
      updateCountCart(0);
      localStorage.setItem("countCartItems", 0);
    } else {
      async function fetchReadyCart() {
        const token = localStorage.getItem("tokenManiarApp");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        try {
          const { data } = await axiosConfig.get("/ready_cart/list", config);
          setReadyCartItems(data.data);
          if (type === "deleteReadyCart") {
            enqueueSnackbar("سبد آماده با موفقیت حذف شد.", {
              autoHideDuration: 2000,
              style: { backgroundColor: "#478620" },
            });
          } else if (type === "addProductToNewReadyCart") {
            enqueueSnackbar("محصول با موفقیت به سبد آماده جدید اضافه شد.", {
              autoHideDuration: 2000,
              style: { backgroundColor: "#478620" },
            });
          } else if (type === "addShoppingCartToNewReadyCart") {
            enqueueSnackbar("سبد خرید با موفقیت به سبد آماده جدید اضافه شد.", {
              autoHideDuration: 2000,
              style: { backgroundColor: "#478620" },
            });
          } else if (type === "addProductToReadyCart") {
            enqueueSnackbar("محصول با موفقیت به سبد آماده اضافه شد.", {
              autoHideDuration: 2000,
              style: { backgroundColor: "#478620" },
            });
          } else if (type === "addShoppingCartToReadyCart") {
            enqueueSnackbar("سبد خرید با موفقیت به سبد آماده اضافه شد.", {
              autoHideDuration: 2000,
              style: { backgroundColor: "#478620" },
            });
          } else if (type === "addNewReadyCart") {
            enqueueSnackbar("سبد آماده با موفقیت ایجاد شد.", {
              autoHideDuration: 2000,
              style: { backgroundColor: "#478620" },
            });
          }
        } catch (error) {
          console.log("Error fetching products:", error);
          showError();
        }
      }
      fetchReadyCart();
    }
  };
  const updateShoppingCartData = () => {
    async function fetchShoppingCart() {
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        const { data } = await axiosConfig.get(
          "/shopping_cart/item/list",
          config
        );
        setItems(data.data);
        setCountCart(data.data.items.length);
        updateCountCart(data.data?.items.length);
        localStorage.setItem("countCartItems", data.data?.items.length);
      } catch (error) {
        console.log("Error fetching products:", error);
        showError();
      }
    }
    fetchShoppingCart();
  };
  const changeCart = (cart) => {
    setCart(cart);
  };
  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  const showErrorQuantity = (text) => {
    enqueueSnackbar(text, {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  return (
    <>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />
      <Header
        shoppingCartItemsId={shoppingCartItemsId}
        countCart={countCart}
        changeCart={changeCart}
        readyCartData={readyCartItems}
        updateData={updateData}
        showError={showError}
        data={items}
      />
      {loading ? (
        <div className="loadingList">
          <div className="spinner"></div>
        </div>
      ) : cart === "shoppingCart" ? (
        <BodyShoppingCart
          data={items}
          readyCartData={readyCartItems}
          updateData={updateData}
          showError={showError}
          updateShoppingCartData={updateShoppingCartData}
          showErrorQuantity={showErrorQuantity}
        />
      ) : (
        <>
          <BodyReadyCart
            data={readyCartItems}
            updateData={updateData}
            showError={showError}
          />
        </>
      )}
    </>
  );
};

export default Index;
