import {
  Container,
  DivHeaderBtn,
  DivTitleTopHeader,
  ImgHeaderBtn,
  TitleTopHeader,
  TopHeader,
} from "./InvoicesHeaderElements";
import back from "../../Images/headerIcons/back.svg";
import add from "../../Images/headerIcons/add.svg";
import { useEffect } from "react";

const Header = () => {
  useEffect(() => {}, []);

  return (
    <>
      <Container>
        <TopHeader>
          <DivHeaderBtn to="/app/profile">
            <ImgHeaderBtn src={back} />
          </DivHeaderBtn>
          <DivTitleTopHeader>
            <TitleTopHeader>سفارش ها</TitleTopHeader>
          </DivTitleTopHeader>
          <DivHeaderBtn to="add" hide>
            <ImgHeaderBtn src={add} />
          </DivHeaderBtn>
        </TopHeader>
      </Container>
    </>
  );
};

export default Header;
