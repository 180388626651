import { useTranslation } from "react-i18next";

import {
  Container,
  Item,
  Card,
  Title,
  DivItems,
  Btn,
  DivDetails,
  Description,
  TextNoItems,
} from "./InvoicesElement";
import card from "../../Images/cards/order.svg";
import { useEffect, useState } from "react";
const Body = ({ data }) => {
  const [items, setItems] = useState([]);
  const { t } = useTranslation();
  const getTime = (date) => {
    date = date.split(" ");
    const time = date[3].split(":");
    return time[0] + ":" + time[1];
  };
  const getCalendar = (date) => {
    date = date.split(" ");
    if (date[1] === "فروردین") {
      date[1] = 1;
    } else if (date[1] === "اردیبهشت") {
      date[1] = 2;
    } else if (date[1] === "خرداد") {
      date[1] = 3;
    } else if (date[1] === "تیر") {
      date[1] = 4;
    } else if (date[1] === "مرداد") {
      date[1] = 5;
    } else if (date[1] === "شهریور") {
      date[1] = 6;
    } else if (date[1] === "مهر") {
      date[1] = 7;
    } else if (date[1] === "آبان") {
      date[1] = 8;
    } else if (date[1] === "آذر") {
      date[1] = 9;
    } else if (date[1] === "دی") {
      date[1] = 10;
    } else if (date[1] === "بهمن") {
      date[1] = 11;
    } else if (date[1] === "اسفد") {
      date[1] = 12;
    }
    return date[2] + "/" + date[1] + "/" + date[0];
  };

  useEffect(() => {
    setItems(data);
  }, [data]);
  return (
    <>
      {items?.length === 0 ? (
        <TextNoItems>
          <p>شما سفارشی ثبت نکرده اید</p>
        </TextNoItems>
      ) : (
        <Container>
          <DivItems>
            {items?.map((item, index) => (
              <Item key={index} to="invoice" state={item}>
                <Card src={card} />
                <Btn>{item?.jalali_payment_date}</Btn>
                <DivDetails>
                  <Title>فاکتور {item?.number}</Title>
                  <Description>
                    {item?.shopping_cart_items.map(
                      (subItem, index) =>
                        subItem?.product?.title +
                        " (" +
                        subItem?.quantity +
                        " " +
                        subItem?.product?.measure_unit +
                        ")" +
                        (item?.shopping_cart_items?.length - index !== 1
                          ? " - "
                          : "")
                    )}
                  </Description>
                </DivDetails>
              </Item>
            ))}
          </DivItems>
        </Container>
      )}
    </>
  );
};

export default Body;
