import React, { useEffect, useState } from "react";
import Header from "../../../Components/UpdateUserInformation/Header";
import Body from "../../../Components/UpdateUserInformation/Body";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { useLocation, useOutletContext } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { MainContainer } from "../../../Components/SoftwareMain/MainElements";
const customTheme = "red";

const Index = () => {
  const [user, setUser] = useState(null);

  let location = useLocation();
  useEffect(() => {
    setUser(location?.state);
  }, [location]);

  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  const showWarning = (text) => {
    enqueueSnackbar(text, {
      autoHideDuration: 2000,
      style: { backgroundColor: "#DC981B" },
    });
  };

  return (
    <MainContainer subPage className={customTheme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />
      <Header />
      <Body showError={showError} showWarning={showWarning} data={user} />
    </MainContainer>
  );
};

export default Index;
