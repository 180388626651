import { Link } from "react-router-dom";
import styled from "styled-components";
export const Container = styled.div`
  height: calc(100vh);
  @supports (height: calc(100svh)) {
    height: calc(100svh);
  }
  width: 100%;
  display: flex;
  align-content: flex-start;
  gap: 23px;
  flex-wrap: wrap;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const DivSearch = styled.div`
  width: 85vw;
  height: 51px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  white-space: nowrap;
`;
export const DivInput = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;
export const Input = styled.input`
  padding-inline-start: 50px;
  padding-inline-end: 14px;
  font-size: 12px;
  height: 100%;
  width: 100%;
  background-color: var(--searchFillColor);
  border-radius: 1.6vh;
  border: 1px solid var(--searchStrokeColor);
  margin: 0;
  outline: none;
  &::placeholder {
    color: var(--placeholder);
    opacity: 1; /* Firefox */
  }
  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--placeholder);
  }
`;
export const IconInput = styled.img`
  position: absolute;
  height: 51px;
  width: 51px;
  & path {
    stroke: var(--firstColor);
  }
  & circle {
    stroke: var(--firstColor);
    fill: var(--firstColor);
  }
`;
export const ButtonFilter = styled.button`
  height: 51px;
  min-width: 51px;
  outline: none;
  border: none;
  background: var(--firstColor);
  border-radius: 17px;
`;
export const ImgFilter = styled.img`
  height: 51px;
  max-width: 51px;
`;
export const DivItems = styled.div`
  padding-top: 41vw;
  padding-bottom: 28vw;
  width: 85vw;
  margin: auto;
  display: flex;
  align-content: flex-start;
  row-gap: 14px;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const Item = styled.div`
  font-size: 13px;
  width: 100%;
  position: relative;
  padding-inline-start: 14%;
  color: black;
`;
export const Card = styled.img`
  height: 100%;
  width: 100%;
`;
export const DivImg = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 73%;
  width: 24%;
  display: flex;
  background: white;
  margin-top: 1%;
  border-radius: 16%;
`;
export const Img = styled.img`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  border-radius: 16%;
  position: relative;
`;
export const DivDiscount = styled.div`
  border-radius: 11px;
  padding: 4px 6px;
  position: absolute;
  bottom: -17%;
  right: -12%;
  background: var(--secondColor);
  color: white;
  font-size: 10px;
  font-weight: 900;
  border: solid 4px var(--background);
`;
export const Btn = styled.div`
  position: absolute;
  left: 0;
  left: ${({ type }) =>
    (type === "add" && "18%") ||
    (type === "minus" && "0") ||
    (type === "delete" && "0")};
  bottom: 0;
  height: 30%;
  width: 10%;
  display: flex;
  background: var(--firstColor);
  margin-bottom: 1%;
  border-radius: 42%;
`;
export const DivQuantity = styled.div`
  position: absolute;
  left: 9.5%;
  bottom: 0;
  height: 30%;
  width: 9%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1%;
  justify-content: center;
`;
export const Quantity = styled.p`
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
`;
export const MeasureUnit = styled.p`
  font-size: 7px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: var(--measureUnitColor);
`;
export const DivDetails = styled.div`
  position: absolute;
  right: 32%;
  top: 21%;
  width: 62%;
  display: flex;
  flex-direction: column;
`;
export const Title = styled.p`
  font-size: 13px;
  font-weight: 800;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Description = styled.p`
  font-size: 10px;
  font-weight: 600;
  width: 100%;
  color: var(--gray);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4%;
`;

export const Value = styled.div`
  height: 100%;
  background: var(--secondColor);
  padding-inline: 4px;
  border-radius: 7px;
  color: white;
  font-size: 10px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DetailItem = styled.div`
  position: absolute;
  inset-inline: 0.3vw;
  top: 58%;
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  align-items: center;
`;

export const DivPrice = styled.div`
  position: relative;
`;
export const Price = styled.p`
  position: absolute;
  top: 3px;
  inset-inline-start: 0;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: var(--placeholder);
  text-decoration-line: line-through;
`;
export const DivDiscountPrice = styled.p`
  font-size: 11px;
  font-weight: 700;
  margin-top: 7%;
`;
export const DiscountPrice = styled.span`
  font-size: 14px;
  font-weight: 900;
  color: var(--firstColor);
  margin-inline-end: 4px;
`;
export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;
export const ContainerShowShoppingCart = styled.div`
  position: fixed;
  height: 10vh;
  @supports (height: 10svh) {
    height: 10svh;
  }
  width: 100vw;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(31.5px);
  -webkit-backdrop-filter: blur(31.5px);
  z-index: 0;
  transform: ${({ show }) =>
    show ? "translate(0%, 0%) scale(1)" : "translate(0%, 100%) scale(1)"};
  transition: transform 0.3s ease-in-out;
`;
export const DivShowShoppingCart = styled.div`
  height: 10vh;
  @supports (height: 10svh) {
    height: 10svh;
  }
  width: 87vw;
  margin-inline: auto;
  display: flex;
  align-items: center;
`;
export const ButtonShowShoppingCart = styled(Link)`
  height: 6vh;
  @supports (height: 6svh) {
    height: 6svh;
  }
  min-width: 62%;
  background: var(--secondColor);
  border-radius: 13px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  text-decoration: none;
`;
export const SumPrice = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  width: 100%;
  text-align: center;
`;
export const UnitSumPrice = styled.span`
  color: var(--secondColor);
  margin-inline-start: 5px;
`;
