import styled from "styled-components";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100vw;
  height: 100vh; /* استفاده از vh برای سازگاری اولیه */
  max-height: 100vh;

  /* استایل اصلی برای نسخه‌های جدید */
  @supports (height: 100svh) {
    height: 100svh;
    max-height: 100svh;
  }
  background: var(--loginBackground);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const BoxLogin = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -24vh;
  gap: 11vh;
  @supports (margin-top: -24svh) {
    margin-top: -24svh;
  }
  @supports (gap: 11svh) {
    gap: 11svh;
  }
`;
export const BoxOrganization = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LogoOrganization = styled.img`
  width: 60vw;
`;
export const BoxInputLogin = styled.div`
  padding-inline: 1.6rem;
  padding-block: 2rem;
  display: flex;
  background: rgba(255, 255, 255, 0.5);
  border: 0.1vw solid white;
  border-radius: 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3vh;
`;
export const IconInput = styled(SVG)`
  position: absolute;
  inset-inline-start: 0;
  width: 50px;
  & path {
    stroke: var(--firstColor);
  }
  & circle {
    stroke: var(--firstColor);
    fill: var(--firstColor);
  }
`;
export const DivInput = styled.div`
  width: 74vw;
  height: 50px;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const DivInputUsername = styled.div`
  position: relative;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
`;
export const Input = styled.input`
  padding-inline-start: 50px;
  padding-inline-end: 50px;
  font-size: 13px;
  font-weight: 600;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 17px;
  outline: none;
  border: none;
  text-align: center;
  pointer-events: auto;

  &::placeholder {
    color: var(--placeholder);
    opacity: 1; /* Firefox */
  }
  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--placeholder);
  }
`;
export const DivInputPassword = styled.div`
  position: relative;
  height: 6vh;
  width: 100%;
  display: flex;
  align-items: center;
`;
export const InputPassword = styled.input`
  padding-inline-start: 50px;
  padding-inline-end: 107px;
  font-size: 13px;
  font-weight: 600;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 17px;
  outline: none;
  border: none;
  margin: 0;
  text-align: center;
  &::placeholder {
    color: var(--placeholder);
    opacity: 1; /* Firefox */
  }
  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--placeholder);
  }
`;
export const BtnSendAgain = styled.button`
  height: 42px;
  width: 103px;
  border-radius: 14px;
  background: var(--backgroundBtnSendAgain);
  color: black;
  margin-block: 4px;
  font-size: 13px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
  position: absolute;
  inset-inline-end: 4px;
  ${({ disabledBtn }) => disabledBtn && "opacity:0.6;"}
`;
export const ImgShowPassword = styled(SVG)`
  height: 50%;
  & path {
    fill: ${({ iconTitle }) =>
      iconTitle === "Eye_slash" ? "var(--placeholder)" : "var(--firstColor)"};
  }
`;
export const Btn = styled.button`
  width: 100%;
  height: 50px;
  font-size: 14px;
  border-radius: 1.6vh;
  align-items: center;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  background: ${({ correctionNumber }) =>
    correctionNumber ? "var(--secondColor)" : "black"};
  font-weight: 900;
  text-decoration: none;
  padding: 1.5vh 1vw;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  -webkit-tap-highlight-color: transparent; // حذف هایلایت آبی در دستگاه‌های لمسی
  ${({ disabledBtn }) => disabledBtn && "opacity:0.6;"}
  transition: all 0.2s ease-in-out;
  &:active {
    filter: brightness(0.75);
  }
`;
export const PHelper = styled.p`
  color: black;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`;
export const Mobile = styled.span`
  color: var(--secondColor);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`;

export const Footer = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
`;
export const DivFooterTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 1vh;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  height: 1.2vh;
  padding-bottom: 0.2vh;
  padding-inline: 2rem;
  border-radius: 10vw;
`;
export const FooterTitle = styled.p`
  font-family: "Time New Roman" !important;
  font-weight: 600;
  font-size: 1.4rem;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
export const FooterRaw = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: black;
`;
export const FooterRawLink = styled(Link)`
  font-weight: 500;
  font-size: 14px !important;
  color: white;
  background: none !important;
  padding: 0;
  text-decoration: underline;
`;
