import React, { useEffect, useState } from "react";
import Header from "../../../Components/Product/Header";
import Body from "../../../Components/Product/CommentsBody";
import { useLocation } from "react-router-dom";
import { MainContainer } from "../../../Components/SoftwareMain/MainElements";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
const customTheme = "red";

const Index = () => {
  const [items, setItems] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [title, setTitle] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyId, setcompanyId] = useState(null);
  const [productId, setproductId] = useState(null);
  const [loading, setLoading] = useState(false);

  let location = useLocation();
  useEffect(() => {
    setTitle(location?.state?.title);
    setCompanyName(location?.state?.companyName);
    setCompanyLogo(location?.state?.companyLogo);
    setcompanyId(location?.state?.company_id);
    setproductId(location?.state?.product_id);

    setLoading(true);
    async function fetchData() {
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        const { data } = await axiosConfig.get(
          "/application/comment/list/" +
            location?.state?.company_id +
            "/" +
            location?.state?.product_id,
          config
        );
        setItems(data.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching products:", error);
        showError();
        setLoading(false);
      }
    }
    fetchData();
  }, [location]);
  const updateData = () => {
    async function fetchData() {
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        const { data } = await axiosConfig.get(
          "/application/comment/list/" + companyId + "/" + productId,
          config
        );
        setItems(data.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching products:", error);
        showError();
      }
    }
    fetchData();
  };

  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  const showWarning = (text) => {
    enqueueSnackbar(text, {
      autoHideDuration: 2000,
      style: { backgroundColor: "#DC981B" },
    });
  };
  return (
    <MainContainer subPage className={customTheme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />
      <Header
        title={title}
        description={"نظرات " + companyName}
        logo={companyLogo}
        page="images"
      />
      {loading ? (
        <div className="loadingList">
          <div className="spinner"></div>
        </div>
      ) : (
        <Body
          data={items}
          company_id={companyId}
          product_id={productId}
          updateData={updateData}
          showError={showError}
          showWarning={showWarning}
        />
      )}
    </MainContainer>
  );
};

export default Index;
