import React, { useEffect, useState } from "react";
import Header from "../../../Components/Products/Header";
import Body from "../../../Components/Products/Body";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { useLocation, useOutletContext } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { MainContainer } from "../../../Components/SoftwareMain/MainElements";
const customTheme = "red";

const Index = () => {
  const [items, setItems] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  // const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState([]);
  // const [activeCategoryId, setActiveCategoryId] = useState(null);
  // const [activeSubCategoryId, setActiveSubCategoryId] = useState(null);
  // let { updateCountCart } = useOutletContext();
  let location = useLocation();

  useEffect(() => {
    setLoading(true);
    setSubCategory(location?.state);
    let haveError = false;
    async function fetchData() {
      let cartItems = [];
      // Fetch cart
      async function fetchCart() {
        const token = localStorage.getItem("tokenManiarApp");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        try {
          const { data } = await axiosConfig.get(
            "/shopping_cart/item/list",
            config
          );
          cartItems = data.data; // مطمئن شوید که داده‌ها به درستی استخراج شده‌اند
          setCart(cartItems);
          // updateCountCart(data.data?.items.length);
          localStorage.setItem("countCartItems", data.data?.items.length);
          setLoading(false);
        } catch (error) {
          console.log("Error fetching products:", error);
          haveError = true;
        }
      }
      // Fetch products
      async function fetchProducts() {
        const token = localStorage.getItem("tokenManiarApp");
        const params = new URLSearchParams([
          ["category_id", location?.state?.id],
          ["type", "single"],
        ]);

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
          params,
        };
        try {
          const { data } = await axiosConfig.get(
            "/application/product/list",
            config
          );
          const itemsData = data.data;
          // itemsData.forEach((item1) => {
          //   const match = cartItems?.items.find(
          //     (item2) =>
          //       item2?.company_id === item1?.minimum_detail?.company_id &&
          //       item2?.product_id === item1?.minimum_detail?.product_id
          //   );
          //   if (match) {
          //     item1.quantity = match?.quantity;
          //     item1.itemId = match?.id;
          //   } else {
          //     item1.quantity = 0;
          //     item1.itemId = null;
          //   }
          // });
          setItems(itemsData);
          setFilterItems(itemsData);
          setLoading(false);
        } catch (error) {
          console.log("Error fetching products:", error);
          haveError = true;
        }
      }

      // Execute fetchCategories first, then fetchProducts
      await fetchCart();
      await fetchProducts();
      if (haveError) {
        showError();
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  // const selectCategory = (categoryId) => {
  //   setLoading(true);
  //   async function fetchProducts() {
  //     const token = localStorage.getItem("tokenManiarApp");
  //     const params = new URLSearchParams([
  //       ["category_id", categoryId],
  //       ["type", "all"],
  //     ]);
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //       params,
  //     };
  //     try {
  //       const { data } = await axiosConfig.get(
  //         "/application/product/list",
  //         config
  //       );
  //       const itemsData = data.data;
  //       itemsData.forEach((item1) => {
  //         const match = cart?.items.find(
  //           (item2) =>
  //             item2?.company_id === item1.minimum_detail?.company_id &&
  //             item2?.product_id === item1.minimum_detail?.product_id
  //         );

  //         if (match) {
  //           item1.quantity = match.quantity;
  //           item1.itemId = match.id;
  //         } else {
  //           item1.quantity = 0;
  //           item1.itemId = null;
  //         }
  //       });
  //       setActiveCategoryId(categoryId);
  //       setActiveSubCategoryId(null);
  //       setItems(itemsData);
  //       setFilterItems(itemsData);
  //       setLoading(false);
  //     } catch (error) {
  //       console.log("Error fetching users:", error);
  //       showError();
  //     }
  //   }
  //   fetchProducts();
  // };
  // const selectSubCategory = (categoryId, subCategoryId) => {
  //   setLoading(true);
  //   async function fetchProducts() {
  //     const token = localStorage.getItem("tokenManiarApp");
  //     let params;
  //     if (categoryId) {
  //       params = new URLSearchParams([
  //         ["category_id", categoryId],
  //         ["type", "all"],
  //       ]);
  //       setActiveCategoryId(categoryId);
  //       setActiveSubCategoryId(null);
  //     } else {
  //       params = new URLSearchParams([
  //         ["category_id", subCategoryId],
  //         ["type", "single"],
  //       ]);
  //       setActiveSubCategoryId(subCategoryId);
  //     }
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //       params,
  //     };
  //     try {
  //       const { data } = await axiosConfig.get(
  //         "/application/product/list",
  //         config
  //       );
  //       const itemsData = data.data;
  //       itemsData.forEach((item1) => {
  //         const match = cart?.items.find(
  //           (item2) =>
  //             item2.company_id === item1.minimum_detail.company_id &&
  //             item2.product_id === item1.minimum_detail.product_id
  //         );

  //         if (match) {
  //           item1.quantity = match.quantity;
  //           item1.itemId = match.id;
  //         } else {
  //           item1.quantity = 0;
  //           item1.itemId = null;
  //         }
  //       });
  //       setItems(itemsData);
  //       setFilterItems(itemsData);
  //       setLoading(false);
  //     } catch (error) {
  //       console.log("Error fetching users:", error);
  //       showError();
  //     }
  //   }
  //   fetchProducts();
  // };
  const updateData = () => {
    let cartItems;
    async function fetchCart() {
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        const { data } = await axiosConfig.get(
          "/shopping_cart/item/list",
          config
        );
        cartItems = data.data; // مطمئن شوید که داده‌ها به درستی استخراج شده‌اند
        items.forEach((item1) => {
          const match = cartItems?.items.find(
            (item2) =>
              item2.company_id === item1.minimum_detail?.company_id &&
              item2.product_id === item1.minimum_detail?.product_id
          );

          if (match) {
            item1.quantity = match?.quantity;
            item1.itemId = match?.id;
          } else {
            item1.quantity = 0;
            item1.itemId = null;
          }
        });
        setCart(cartItems);
        localStorage.setItem("countCartItems", cartItems?.items.length);
        setItems(items);
        setFilterItems(items);
      } catch (error) {
        console.log("Error fetching products:", error);
        showError();
      }
    }
    fetchCart();
  };
  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  const showErrorQuantity = (text) => {
    enqueueSnackbar(text, {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  const replaceArabicYeWithPersianYe = (text) => {
    if (!text) return "";
    return text.replace(/ي/g, "ی").replace(/ك/g, "ک"); // تبدیل "ي" عربی به "ی" فارسی
  };

  const filter = (e) => {
    const keyword = replaceArabicYeWithPersianYe(e.target.value);
    if (keyword !== "") {
      let results = filterItems.filter((item) => {
        return item?.title.toLowerCase().includes(keyword.toLowerCase());
      });
      setItems(results);
    } else {
      setItems(filterItems);
    }
  };

  return (
    <MainContainer subPage className={customTheme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />
      <Header title={subCategory?.title} />
      {loading ? (
        <div className="loadingList">
          <div className="spinner"></div>
        </div>
      ) : (
        <Body
          cart={cart}
          data={items}
          updateData={updateData}
          handleFilter={filter}
          showErrorQuantity={showErrorQuantity}
        />
      )}
    </MainContainer>
  );
};

export default Index;
