import React, { useEffect, useState } from "react";
import Header from "../../Components/Profile/InvoicesHeader";
import Body from "../../Components/Profile/InvoicesBody";
import axiosConfig from "../../ApiCall/axiosConfig";
import { useLocation } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { MainContainer } from "../../Components/SoftwareMain/MainElements";
const customTheme = "red";

const Index = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  let location = useLocation();
  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        const { data } = await axiosConfig.get(
          "/application/invoice/list",
          config
        );
        setItems(data.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching products:", error);
        showError();
      }
    }
    fetchData();
  }, [location]);

  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  const showWarning = (text) => {
    enqueueSnackbar(text, {
      autoHideDuration: 2000,
      style: { backgroundColor: "#DC981B" },
    });
  };

  return (
    <MainContainer subPage className={customTheme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />
      <Header />
      {loading ? (
        <div className="loadingList">
          <div className="spinner"></div>
        </div>
      ) : (
        <Body data={items} showError={showError} showWarning={showWarning} />
      )}
    </MainContainer>
  );
};

export default Index;
