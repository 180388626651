import styled from "styled-components";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";

export const Container = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25.8vw;
  padding-top: 1vw;
  width: 100%;
  flex-direction: row;
  grid-area: col2;
  position: fixed;
  bottom: 0;
  right: 0;
  div {
    position: relative;
    height: 100%;
    width: 100%;
  }
`;
export const BackgroundBottomNavigation = styled.img`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100vw;
`;
export const Buttons = styled.ul`
  position: absolute;
  overflow: auto;
  display: flex;
  text-align: center;
  /* background: white; */
  align-items: center;
  list-style: none;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding-top: 6%;
  border-radius: 16px 16px 0 0;
  gap: 4%;
  justify-content: center;
  z-index: 10;
`;
export const Button = styled.li`
  position: relative;
  display: flex;
  text-align: center;
  align-content: center;
`;
export const ButtonLink = styled(Link)`
  height: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.6vh 1vw;
  transition: 0.3s ease-in-out;
  border-radius: 50%;
  ${({ active, search }) =>
    (search &&
      !active &&
      "background:black;margin-top:-40%;border:2.4vw solid white;") ||
    (search &&
      active &&
      "background:var(--firstColor);margin-top:-40%;border:2.4vw solid white;")}
  ${({ active }) => (active ? "cursor: default;" : "cursor: pointer;")};
  ${({ withMargin }) => withMargin && "margin-top: 8%;"};

  -webkit-tap-highlight-color: transparent; // حذف هایلایت آبی در دستگاه‌های لمسی
  &:active {
    opacity: 0.2;
  }
`;
export const ButtonImg = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  ${({ active, search }) =>
    (!active && search && "box-shadow: 0px 3vw 10px rgba(0, 0, 0, 0.3);") ||
    (active && search && "box-shadow: 0px 3vw 10px rgba(202, 18, 17, 0.4);")}
  ${({ active, search }) => active && !search && "filter: brightness(0%);"}
`;
export const Dot = styled.div`
  min-width: 4.27px;
  max-width: 4.27px;
  max-height: 4.27px;
  min-height: 4.27px;
  display: ${({ search }) => (search ? "none" : "block")};
  background: ${({ active }) => (active ? "black" : "white")};
  border-radius: 50%;
`;
export const Count = styled.p`
  position: absolute;
  top: 36.5%;
  inset-inline-start: 24%;
  width: 22%;
  color: white;
  font-size: 8px;
  font-weight: 600;
  text-align: center;
  z-index: 1;
`;
