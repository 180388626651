import styled from "styled-components";
import SVG from "react-inlinesvg";

export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
`;
export const TopHeader = styled.div`
  height: 76px;
  width: 85vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const TitleTopHeader = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
`;
export const DivHeaderBtn = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  opacity: ${({ hide }) => (hide ? "0" : "1")};
  background: ${({ direction }) =>
    (direction === "left" && "var(--firstColor)") ||
    (direction === "right" && "var(--secondColor)")};
`;
export const ImgHeaderBtn = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  &:active {
    filter: brightness(0.75);
  }
`;
export const BottomHeader = styled.div`
  height: 90px;
  width: 85vw;
  background: var(--backgroundBoxCarts);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
  padding: 3vw;
`;

export const Cart = styled.div`
  position: relative;
  height: 100%;
  width: 37.4vw;
  background: ${({ active }) => (active ? "var(--firstColor)" : "white")};
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding-inline: 4%;
  p {
    font-size: 11px;
    font-style: normal;
    font-weight: 800;
    text-align: center;
    width: 100%;
    color: ${({ active }) => (active ? "black" : "#BBBBBB")};
  }
  div {
    position: absolute;
    top: -2px;
    left: -2px;
    background: ${({ active }) => (active ? "black" : "#BBBBBB")};
    color: white;
    height: 26px;
    width: 26px;
    border-radius: 13px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 800;
  }
`;
export const ImgCart = styled(SVG)`
  height: 60px;
  width: 60px;
  & path {
    &:nth-child(1) {
      fill: ${({ active }) => (active ? "white" : "#D9D9D9")};
    }
    &:nth-child(2) {
      fill: ${({ active }) => (active ? "black" : "#BBBBBB")};
    }
  }
`;

export const CoverHeader = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 0 25px 25px;
  filter: opacity(0.3);
  -webkit-filter: opacity(0.3);
  -moz-filter: opacity(0.3);
`;
export const BackgroundHeader = styled.div`
  position: absolute;

  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 0 25px 25px;
  background: linear-gradient(
    180deg,
    var(--statusBackgroundColor) 0%,
    rgba(248, 67, 27, 0) 100%
  );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 31px;
`;
export const BackgroundHeaderTitle = styled.div`
  border-radius: 0px 20px 20px 0px;
  background: linear-gradient(
    92deg,
    rgba(202, 18, 17, 0) 4.9%,
    var(--statusBackgroundColor) 92.46%
  );
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
  padding: 6px 21px;
  font-size: 16px;
  font-weight: 900;
`;
export const ContainerCategory = styled.div`
  border-radius: 24px;
  background: rgba(244, 223, 223, 0.6);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  width: 85vw;
  height: 124px;
  margin-bottom: -62px;
  padding: 12px;
`;
export const WrapperCategory = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 10px;
  gap: 12px;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Category = styled.div`
  border-radius: 15px;
  background: ${({ active }) => (active ? "var(--firstColor)" : "white")};
  min-width: 77px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-inline: 17px;
  padding-top: 12px;
  font-size: 11px;
  font-style: normal;
  font-weight: 800;
  line-height: 157.5%;
`;
export const PCategory = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
  }
`;
export const ImgCategory = styled.img`
  min-height: 43px;
  width: 43px;
`;
export const ContainerSubCategory = styled.div`
  height: 40px;
  width: 85vw;
  border-radius: 11px;
  margin-top: 50px;
  margin-bottom: -116px;
`;

export const WrapperSubCategory = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 11px;
  gap: 12px;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const SubCategory = styled.div`
  border-radius: 11px;
  background: ${({ active }) => (active ? "var(--secondColor)" : "white")};
  color: ${({ active }) => (active ? "white" : "var(--secondColor)")};
  height: 100%;
  display: flex;
  padding-inline: 24px;
  font-size: 11px;
  font-weight: 800;
  align-items: center;
  white-space: nowrap;
`;
