import {
  BackgroundHeader,
  BackgroundHeaderTitle,
  Container,
  CoverHeader,
} from "./HeaderElements";
import cover from "../../Images/productsPage/cover.jpg";
import back from "../../Images/headerIcons/back.svg";
import { DivHeaderBtn, ImgHeaderBtn } from "../Cart/HeaderElements";

const Header = ({ selectCategory, showBack }) => {
  return (
    <>
      <Container>
        <BackgroundHeader>
          <CoverHeader src={cover} />
          <BackgroundHeader>
            <DivHeaderBtn
              hide={!showBack}
              onClick={() => selectCategory("SelectBack")}
            >
              <ImgHeaderBtn src={back} />
            </DivHeaderBtn>
            <BackgroundHeaderTitle>
              {!showBack ? "دسته بندی ها" : "زیر دسته ها"}
            </BackgroundHeaderTitle>
            <DivHeaderBtn hide></DivHeaderBtn>
          </BackgroundHeader>
        </BackgroundHeader>
      </Container>
    </>
  );
};

export default Header;
