import styled from "styled-components";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

export const Container = styled.div`
  /* margin-top: 138px; */
  height: calc(100vh - 76px);
  @supports (height: calc(100svh - 76px)) {
    height: calc(100svh - 76px);
  }

  background: var(--background);
  width: 100%;
  display: flex;
  align-content: flex-start;
  gap: 23px;
  flex-wrap: wrap;
  overflow-x: hidden;
  padding-top: 29px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const TextNoItems = styled.div`
  /* margin-top: 138px; */
  height: calc(90vh - 205px);
  @supports (height: calc(90svh - 205px)) {
    height: calc(90svh - 205px);
  }
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 11px;
    font-weight: 600;
  }
`;
export const Company = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  width: 85vw;
  margin-inline: auto;
`;
export const DivImg = styled.div`
  max-width: 85vw;
  min-width: 85vw;
  height: 40vh;
  margin-top: 2vh;
  margin-inline: auto;
  display: flex;
  border-radius: 8px;
`;
export const Img = styled.img`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
`;
export const DefualtImg = styled(SVG)`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  border-radius: 50%;
  & path {
    fill: var(--firstColor);
  }
`;
export const MainDiv = styled.div`
  position: relative;
  margin-inline: auto;
`;
export const DivItems = styled.div`
  width: 85vw;
  height: 76px;
  display: flex;
  flex-direction: row;
  gap: 18px;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  white-space: nowrap; /* جلوگیری از شکستن خط */
  &::-webkit-scrollbar {
    display: none; /* مخفی کردن اسکرول بار در Webkit-based browsers */
  }
`;
export const DivItem = styled.div`
  min-width: 76px;
  max-width: 76px;
  height: 100%;
  background: white;
  border-radius: 8px;
  ${({ active }) => !active && "opacity: 0.5;"}
`;
export const Item = styled.img`
  min-height: 76px;
  max-height: 76px;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  ${({ active }) => active && "border:4px solid var(--secondColor);"}
`;
export const FadeDiv = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 27px;
  background: linear-gradient(90deg, #f7efef 0%, rgba(247, 239, 239, 0) 100%);
`;
