import React, { useEffect, useState } from "react";
import Header from "../../../Components/Product/Header";
import Body from "../../../Components/Product/ImagesBody";
import { useLocation } from "react-router-dom";
import { MainContainer } from "../../../Components/SoftwareMain/MainElements";
const customTheme = "red";

const Index = () => {
  const [items, setItems] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  let location = useLocation();
  useEffect(() => {
    setTitle(location?.state?.title);
    setDescription(location?.state?.description);
    setCompanyName(location?.state?.companyName);
    setCompanyLogo(location?.state?.companyLogo);
    setItems(location?.state?.images);
  }, [location]);

  return (
    <MainContainer subPage className={customTheme}>
      <Header
        title={title}
        description={description}
        logo={companyLogo}
        page="images"
      />
      <Body data={items} companyName={companyName} />
    </MainContainer>
  );
};

export default Index;
