import styled from "styled-components";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { Sheet } from "react-modal-sheet";

export const Container = styled.div`
  /* margin-top: 138px; */
  height: calc(90vh - 76px);
  @supports (height: calc(90svh - 76px)) {
    height: calc(90svh - 76px);
  }
  background: var(--background);
  width: 100%;
  display: flex;
  align-content: flex-start;
  gap: 23px;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: hidden;
  padding-bottom: 40px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const BoxCalendar = styled.div`
  /* margin-top: 138px; */
  width: 100%;
  display: flex;
  align-content: flex-start;
  padding-top: 6px;
`;
export const BoxItems = styled.div`
  /* margin-top: 138px; */
  height: calc(61vh - 48px);
  @supports (height: calc(61svh - 48px)) {
    height: calc(61svh - 48px);
  }

  background: var(--background);
  width: 100%;
  display: flex;
  align-content: flex-start;
  gap: 23px;
  flex-wrap: wrap;
  overflow-x: hidden;
  padding-top: 6px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const DivDetailes = styled.div`
  background: var(--backgroundPrices);
  width: 85vw;
  margin-top: 26px;
  margin-inline: auto;
  display: flex;
  align-content: flex-start;
  gap: 12px;
  flex-direction: column;
  border-radius: 22px;
  justify-content: center;
  padding-inline: 20px;
  padding-block-start: 10px;
  padding-block-end: 15px;
  tr {
    border-bottom: 1px dashed;
  }
`;
export const Detail = styled.div`
  width: 100%;
  margin-inline: auto;
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  label {
    font-weight: 900;
  }
`;
export const Helper = styled.p`
  width: 85vw;
  margin-inline: auto;
  color: var(--secondColor);
  text-align: right;
  font-size: 14.2px;
  font-style: normal;
  font-weight: 700;
  padding-top: 13px;
`;

export const TextNoItems = styled.div`
  /* margin-top: 138px; */
  height: calc(90vh - 205px);
  @supports (height: calc(90svh - 205px)) {
    height: calc(90svh - 205px);
  }
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 11px;
    font-weight: 600;
  }
`;

export const DivSearch = styled.div`
  width: 85vw;
  height: 51px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  white-space: nowrap;
`;
export const DivInput = styled.div`
  position: relative;
  height: 51px;
  width: 100%;
  display: flex;
  align-items: center;
`;
export const Input = styled.input`
  padding-inline-start: 50px;
  padding-inline-end: 14px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 17px;
  border: 1px solid var(--searchStrokeColor);
  margin: 0;
  outline: none;
  &::placeholder {
    color: var(--placeholder);
    opacity: 1; /* Firefox */
  }
  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--placeholder);
  }
`;
export const IconInput = styled(SVG)`
  position: absolute;
  height: 51px;
  width: 51px;
  & path {
    stroke: var(--firstColor);
  }
  & circle {
    stroke: var(--firstColor);
    fill: var(--firstColor);
  }
`;
export const ButtonFilter = styled.button`
  height: 51px;
  min-width: 51px;
  outline: none;
  border: none;
  background: var(--firstColor);
  border-radius: 17px;
`;
export const ImgFilter = styled.img`
  height: 51px;
  max-width: 51px;
`;
export const DivRaedyCarts = styled.div`
  width: 88vw;
  border: 1px solid var(--firstColor);
  border-radius: 17px 17px 0 0;
  background: white;
`;
export const RaedyCart = styled.div`
  position: relative;
  margin-inline: 30px;
  width: calc(100% - 60px);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #6d4c10;
  border-bottom: 1px solid var(--separatorReadyCarts);

  &:last-child {
    border-bottom: none;
  }
  p {
    width: 100%;
    text-align: center;
    padding-inline: 42px;
  }
`;
export const ButtonAddRaedyCart = styled.div`
  width: 88vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: white;
  background: var(--firstColor);
  border-radius: ${({ emptyReadyCart }) =>
    emptyReadyCart ? "17px" : "0 0 17px 17px"};
  p {
    text-align: center;
    margin-left: 35px;
  }
`;
export const ImgReadyCart = styled(SVG)`
  position: absolute;
  right: 0;
  & path {
    &:nth-child(1) {
      stroke: var(--firstColor);
    }
    &:nth-child(3) {
      fill: var(--firstColor);
    }
    &:nth-child(4) {
      fill: var(--firstColor);
    }
  }
`;
export const DivItems = styled.div`
  width: 85vw;
  margin: auto;
  display: flex;
  align-content: flex-start;
  row-gap: 14px;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const Item = styled.div`
  font-size: 13px;
  width: 100%;
  height: 53px;
  background: ${({ active }) => (active ? "var(--firstColor)" : "white")};
  color: ${({ active }) => (active ? "white" : "var(--darkFirstColor)")};
  border-radius: 22px;
  padding-inline-start: 21px;
  position: relative;
`;
export const DivImg = styled.div`
  position: absolute;
  top: 0;
  right: 21px;
  width: 53px;
  height: 53px;
  ${({ active }) => active && "filter: brightness(0) invert(1);"}
`;
export const Img = styled.img`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  border-radius: 50%;
`;
export const DivDetails = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  background: var(--secondColor);
  padding-left: 24px;
  border-radius: 5px 15px 5px 22px;
`;

export const Title = styled.p`
  font-size: 13px;
  font-weight: 800;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
`;
export const Address = styled.p`
  padding-inline: 23px;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  margin-top: 16px;
  padding-bottom: 16px;
`;

export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;
export const ContainerTextBoxComment = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 20px 6vw;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(31.5px);
  -webkit-backdrop-filter: blur(31.5px);
  z-index: 0;
  display: flex;
  gap: 5%;
`;
export const DivInputAndBtn = styled.div`
  width: 84%;
`;
export const DivRate = styled.div`
  width: 11%;
  direction: ltr;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  background: white;
  border-radius: 17px;
  border: 1px solid var(--searchStrokeColor);
`;
export const ContainerShowShoppingCart = styled.div`
  position: fixed;
  height: 10vh;
  @supports (height: 10svh) {
    height: 10svh;
  }
  width: 100vw;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(31.5px);
  -webkit-backdrop-filter: blur(31.5px);
  z-index: 0;
  transform: ${({ show }) =>
    show ? "translate(0%, 0%) scale(1)" : "translate(0%, 100%) scale(1)"};
  transition: transform 0.3s ease-in-out;
`;
export const DivShowShoppingCart = styled.div`
  height: 10vh;
  @supports (height: 10svh) {
    height: 10svh;
  }
  width: 87vw;
  margin-inline: auto;
  display: flex;
  align-items: center;
`;
export const ButtonNext = styled.div`
  height: 6vh;
  @supports (height: 6svh) {
    height: 6svh;
  }
  min-width: 100%;
  background: var(--firstColor);
  border-radius: 13px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; // حذف هایلایت آبی در دستگاه‌های لمسی
  transition: all 0.2s ease-in-out;
  &:active {
    filter: brightness(0.75);
  }
`;
export const ButtonInsertComment = styled.div`
  height: 6vh;
  @supports (height: 6svh) {
    height: 6svh;
  }
  min-width: 62%;
  background: var(--firstColor);
  border-radius: 13px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  text-decoration: none;
`;
export const SumPrice = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  width: 100%;
  text-align: center;
`;
export const UnitSumPrice = styled.span`
  color: var(--secondColor);
  margin-inline-start: 5px;
`;
export const DivBtnBottomSheet = styled.div`
  height: 50px;
  width: 88vw;
  margin-inline: auto;
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: flex-end;
  div {
    height: 100%;
    width: 50%;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }
`;
export const ButtonCalendar = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  padding-inline: 6px;
  border: none;
  background: none;
`;

export const CustomSheet = styled(Sheet)`
  .react-modal-sheet-backdrop {
    background-color: rgba(0, 0, 0, 0.4) !important;
  }
  .react-modal-sheet-container {
    border-radius: 0 !important;
    background: var(--background) !important;
    box-shadow: none !important;
  }
  .react-modal-sheet-scroller {
    flex-wrap: wrap;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .react-modal-sheet-content {
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 14px;
    align-items: center;
  }
`;
