import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";

import {
  Container,
  Card,
  Title,
  DivItems,
  DiscountPrice,
  DivDiscountPrice,
  Icon,
  Description,
  DivQuantity,
  Quantity,
  MeasureUnit,
  TextNoItems,
  CustomSheet,
  ReadyCartItem,
  DivAvailable,
  Available,
  DivReadyCartDetails,
  BtnReadyCart,
  Separator,
  UnAvailable,
  DivReadyCartPrice,
  DivBtnBottomSheet,
  BtnBottomSheet,
  SpanContent,
  TitleReadyCart,
} from "./Element";
import card from "../../Images/cards/readyCart.svg";
import deleteIcon from "../../Images/delete.svg";
import addReadyCartIcon from "../../Images/cartPage/productAddToReadyCart.svg";
import { Sheet } from "react-modal-sheet";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const customTheme = "red";
const Body = ({ cart, data, updateData, showError }) => {
  const [title, setTitle] = useState("");
  const [items, setItems] = useState([]);
  const [detailItem, setDetailItem] = useState([]);

  const [isOpen, setOpen] = useState(false);
  const [deleteSheete, setDeleteSheete] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setItems(data);
  }, [data]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleClick = (item) => {
    navigate("readyCart", { state: item });
  };

  const handleDelete = async () => {
    setDeleteSheete(false);
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.delete("/ready_cart/delete/" + detailItem?.id, config);
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
    }
    if (isOkResponse) {
      updateData("deleteReadyCart");
    } else {
      showError();
    }
  };

  const handleAddReadyCartToShoppingCart = async () => {
    let itemsArray = [];
    setOpen(false);
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const readyCart = await axiosConfig.get(
        "/ready_cart/item/list/" + detailItem?.id,
        config
      );
      try {
        readyCart?.data?.data?.items.forEach((item) => {
          itemsArray.push({
            company_id: item?.company_id,
            product_id: item?.product_id,
            quantity: item?.quantity,
          });
        });
        await axiosConfig.post("/shopping_cart/item/store", itemsArray, config);
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
        console.log("Error response from server:", error);
      }
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error);
    }
    if (isOkResponse) {
      updateData("addShoppingCartToReadyCart");
    } else {
      showError();
    }
  };

  return (
    <>
      {items?.length === 0 ? (
        <TextNoItems>
          <p>محصولی در سبد خرید شما وجود ندارد</p>
        </TextNoItems>
      ) : (
        <Container>
          <CustomSheet
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            detent="content-height"
            className={customTheme}
          >
            <Sheet.Container>
              <Sheet.Content>
                <p>
                  آیا <SpanContent type="add">{detailItem?.title}</SpanContent>{" "}
                  به سبد خریدتان اضافه شود؟
                </p>
                <Sheet.Scroller>
                  <DivBtnBottomSheet>
                    <BtnBottomSheet
                      type="cancel"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      خیر
                    </BtnBottomSheet>
                    <BtnBottomSheet
                      type="ok"
                      onClick={() => {
                        handleAddReadyCartToShoppingCart();
                      }}
                    >
                      بله
                    </BtnBottomSheet>
                  </DivBtnBottomSheet>
                </Sheet.Scroller>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={() => setOpen(false)} />
          </CustomSheet>
          <CustomSheet
            isOpen={deleteSheete}
            onClose={() => setDeleteSheete(false)}
            detent="content-height"
            className={customTheme}
          >
            <Sheet.Container>
              <Sheet.Content>
                <p>
                  آیا مایل هستید که
                  <SpanContent type="delete">
                    {" "}
                    {detailItem?.title}
                  </SpanContent>{" "}
                  حذف شود؟
                </p>

                <Sheet.Scroller>
                  <DivBtnBottomSheet>
                    <BtnBottomSheet
                      type="cancel"
                      onClick={() => {
                        setDeleteSheete(false);
                      }}
                    >
                      خیر
                    </BtnBottomSheet>
                    <BtnBottomSheet
                      type="ok"
                      onClick={() => {
                        handleDelete();
                      }}
                    >
                      بله
                    </BtnBottomSheet>
                  </DivBtnBottomSheet>
                </Sheet.Scroller>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={() => setDeleteSheete(false)} />
          </CustomSheet>
          <DivItems showShoppingCart={false}>
            {items?.map((item, index) => (
              <ReadyCartItem key={index}>
                <Card src={card} onClick={() => handleClick(item)} />
                <BtnReadyCart
                  type="delete"
                  onClick={() => {
                    setDeleteSheete(true);
                    setDetailItem(item);
                  }}
                >
                  <Icon src={deleteIcon} />
                </BtnReadyCart>

                <DivReadyCartDetails onClick={() => handleClick(item)}>
                  <TitleReadyCart>{item?.title}</TitleReadyCart>
                  <DivAvailable>
                    <Available>
                      کالا های موجود : {item?.available} کالا
                    </Available>
                    {item?.unavailable !== 0 && (
                      <>
                        <Separator />
                        <UnAvailable>
                          کالا های ناموجود : {item?.unavailable} کالا
                        </UnAvailable>
                      </>
                    )}
                  </DivAvailable>
                </DivReadyCartDetails>
                <DivReadyCartPrice onClick={() => handleClick(item)}>
                  <DivDiscountPrice>
                    <DiscountPrice>
                      {numberWithCommas(item?.sum_price)}
                    </DiscountPrice>
                    تومان
                  </DivDiscountPrice>
                </DivReadyCartPrice>
                <BtnReadyCart
                  type="addToShoppingCart"
                  onClick={() => {
                    setOpen(true);
                    setDetailItem(item);
                  }}
                >
                  <Icon src={addReadyCartIcon} />
                </BtnReadyCart>
              </ReadyCartItem>
            ))}
          </DivItems>
        </Container>
      )}
    </>
  );
};

export default Body;
