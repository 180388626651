import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";

import {
  Container,
  Item,
  Card,
  Title,
  DivItems,
  Img,
  DiscountPrice,
  DivDiscountPrice,
  Price,
  DivImg,
  Btn,
  Icon,
  DivDetails,
  Description,
  DivDiscount,
  DivQuantity,
  Quantity,
  MeasureUnit,
  DivPrice,
  DivShowShoppingCart,
  ContainerShowShoppingCart,
  ButtonShowShoppingCart,
  SumPrice,
  UnitSumPrice,
  Company,
  CompanyDefualtIcon,
  TextNoItems,
  RaedyCart,
  ImgReadyCart,
  DivRaedyCarts,
  ButtonAddRaedyCart,
  ImgButton,
  DivInput,
  Input,
  CustomSheet,
  DivBtnBottomSheet,
  BtnBottomSheet,
} from "./Element";
import card from "../../Images/cards/shoppingCart.svg";
import addIcon from "../../Images/add.svg";
import minusIcon from "../../Images/minus.svg";
import deleteIcon from "../../Images/delete.svg";
import addReadyCartIcon from "../../Images/cartPage/productAddToReadyCart.svg";
import bottomSheateReadyCartIcon from "../../Images/cartPage/bottomSheateReadyCart.svg";
import defualtCompanyIcon from "../../Images/defaultImage/deliveryCompany.svg";
import { Sheet } from "react-modal-sheet";
import { useEffect, useState } from "react";
const customTheme = "red";
const Body = ({
  data,
  readyCartData,
  updateData,
  showError,
  updateShoppingCartData,
  showErrorQuantity,
}) => {
  const [title, setTitle] = useState("");
  const [items, setItems] = useState([]);
  const [detailItem, setDetailItem] = useState([]);

  const [readyCartItems, setReadyCartItems] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [showAddReadyCartSheet, setShowAddReadyCartSheet] = useState(false);
  const [showDeleteProductSheet, setShowDeleteProductSheet] = useState(false);

  const { t } = useTranslation();
  const handleChange = (e) => {
    setTitle(e.currentTarget.value);
  };

  useEffect(() => {
    setItems(data);
    setReadyCartItems(readyCartData);
  }, [data, readyCartData]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleAddProductToNewReadyCart = async () => {
    setShowAddReadyCartSheet(false);
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const { data } = await axiosConfig.post(
        "/ready_cart/store",
        { title: title },
        config
      );
      try {
        await axiosConfig.post(
          "/ready_cart/item/store",
          [
            {
              ready_cart_id: data?.data?.id,
              company_id: detailItem?.company_id,
              product_id: detailItem?.product_id,
              quantity: detailItem?.quantity,
            },
          ],
          config
        );
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
      }
    } catch (error) {
      isOkResponse = false;
    }
    if (isOkResponse) {
      updateData("addProductToNewReadyCart");
      setTitle("");
    } else {
      showError();
      setTitle("");
    }
  };
  const handleAddProductToReadyCart = async (readyCart) => {
    setOpen(false);
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.post(
        "/ready_cart/item/store",
        [
          {
            ready_cart_id: readyCart?.id,
            company_id: detailItem?.company_id,
            product_id: detailItem?.product_id,
            quantity: detailItem?.quantity,
          },
        ],
        config
      );
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
    }
    if (isOkResponse) {
      updateData("addProductToReadyCart");
    } else {
      showError();
    }
  };

  const handleOperation = async (item, operation, type) => {
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    if (type === "update") {
      let quantity;
      if (operation === "add") {
        quantity = item?.quantity + 1;
      } else {
        quantity = item?.quantity - 1;
      }
      if (quantity > item?.max_quantity) {
        showErrorQuantity("موجودی بیشتری از کالا وجود ندارد");
      } else {
        try {
          await axiosConfig.put(
            "/shopping_cart/item/update/" + item?.id,
            {
              quantity: quantity,
            },
            config
          );
          isOkResponse = true;
        } catch (error) {
          isOkResponse = false;
          console.log("Error response from server:", error);
        }
      }
    } else if (type === "delete") {
      try {
        await axiosConfig.delete(
          "/shopping_cart/item/delete/" + item?.id,
          config
        );
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
        console.log("Error response from server:", error);
      }
    }

    if (isOkResponse) {
      updateShoppingCartData();
    }
  };
  return (
    <>
      {!items.items || items.items?.length === 0 ? (
        <TextNoItems>
          <p>محصولی در سبد خرید شما وجود ندارد</p>
        </TextNoItems>
      ) : (
        <Container>
          <CustomSheet
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            detent="content-height"
            className={customTheme}
          >
            <Sheet.Container>
              <Sheet.Content>
                به کدام سبد آماده اضافه شود؟
                <Sheet.Scroller>
                  {readyCartItems.length !== 0 && (
                    <DivRaedyCarts>
                      {readyCartItems.map((item, index) => (
                        <RaedyCart
                          key={index}
                          onClick={() => handleAddProductToReadyCart(item)}
                        >
                          <ImgReadyCart src={bottomSheateReadyCartIcon} />
                          <p>{item?.title}</p>
                        </RaedyCart>
                      ))}
                    </DivRaedyCarts>
                  )}
                  <ButtonAddRaedyCart
                    emptyReadyCart={readyCartItems.length === 0}
                    onClick={() => {
                      setOpen(false);
                      setShowAddReadyCartSheet(true);
                    }}
                  >
                    <ImgButton src={addIcon} />
                    <p>ایجاد سبد آماده جدید</p>
                  </ButtonAddRaedyCart>
                </Sheet.Scroller>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={() => setOpen(false)} />
          </CustomSheet>
          <CustomSheet
            isOpen={showAddReadyCartSheet}
            onClose={() => setShowAddReadyCartSheet(false)}
            detent="content-height"
            className={customTheme}
          >
            <Sheet.Container>
              <Sheet.Content>
                <Sheet.Scroller>
                  <DivInput>
                    <ImgReadyCart src={bottomSheateReadyCartIcon} />
                    <Input
                      id="title"
                      placeholder="نام سبد خرید"
                      onChange={handleChange}
                      value={title}
                    />
                  </DivInput>
                  <ButtonAddRaedyCart
                    emptyReadyCart
                    onClick={() => {
                      title !== "" && handleAddProductToNewReadyCart();
                    }}
                  >
                    ایجاد و افزودن کالا ها به این سبد
                  </ButtonAddRaedyCart>
                </Sheet.Scroller>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={() => setShowAddReadyCartSheet(false)} />
          </CustomSheet>
          <CustomSheet
            isOpen={showDeleteProductSheet}
            onClose={() => setShowDeleteProductSheet(false)}
            detent="content-height"
            className={customTheme}
          >
            <Sheet.Container>
              <Sheet.Content>
                <p>آیا مایل هستید که محصول از سبد خرید حذف شود؟</p>

                <Sheet.Scroller>
                  <DivBtnBottomSheet>
                    <BtnBottomSheet
                      type="cancel"
                      onClick={() => {
                        setShowDeleteProductSheet(false);
                      }}
                    >
                      خیر
                    </BtnBottomSheet>
                    <BtnBottomSheet
                      type="ok"
                      onClick={() => {
                        handleOperation(detailItem, "delete", "delete");
                        setShowDeleteProductSheet(false);
                      }}
                    >
                      بله
                    </BtnBottomSheet>
                  </DivBtnBottomSheet>
                </Sheet.Scroller>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={() => setShowDeleteProductSheet(false)} />
          </CustomSheet>

          <DivItems showShoppingCart={items?.items?.length !== 0}>
            {items?.items?.map((item, index) => (
              <Item key={index}>
                <Card src={card} />
                <Btn
                  type="addReadyCart"
                  onClick={() => {
                    setOpen(true);
                    setDetailItem(item);
                  }}
                >
                  <Icon src={addReadyCartIcon} />
                </Btn>

                <DivImg>
                  <Img src={item?.product?.image} />
                  {item?.detail?.confirmed_price !==
                    item?.detail?.discount_price &&
                    item?.detail?.discount_type === "percentage" && (
                      <DivDiscount>
                        {item?.detail?.discount_value + "%"}
                      </DivDiscount>
                    )}
                  {item?.detail?.confirmed_price !==
                    item?.detail?.discount_price &&
                    item?.detail?.discount_type === "fixed" && (
                      <DivDiscount>
                        {numberWithCommas(item?.detail?.discount_value) + "-"}
                      </DivDiscount>
                    )}
                </DivImg>
                <DivDetails>
                  <Title>{item?.product?.title}</Title>
                  <Company>
                    {item?.company?.logo ? (
                      <img src={item?.company?.logo} alt="companyLogo" />
                    ) : (
                      <CompanyDefualtIcon src={defualtCompanyIcon} />
                    )}
                    {item?.company?.title}
                  </Company>
                  <Description>{item?.detail_description}</Description>
                </DivDetails>
                {item?.detail ? (
                  <DivPrice>
                    <DivDiscountPrice>
                      قیمت واحد :
                      <DiscountPrice type="unit">
                        {item?.detail?.confirmed_price !==
                          item?.detail?.discount_price &&
                          (item?.detail?.discount_type === "percentage" ||
                            item?.detail?.discount_type === "fixed") && (
                            <Price>
                              {numberWithCommas(item?.detail?.confirmed_price)}
                            </Price>
                          )}
                        {numberWithCommas(item?.detail?.discount_price)}
                      </DiscountPrice>
                      تومان
                    </DivDiscountPrice>
                    <DivDiscountPrice>
                      قیمت کل :
                      <DiscountPrice>
                        {numberWithCommas(item?.sum_price)}
                      </DiscountPrice>
                      تومان
                    </DivDiscountPrice>
                  </DivPrice>
                ) : (
                  <DiscountPrice>ناموجود</DiscountPrice>
                )}
                <Btn
                  type="deleteProduct"
                  onClick={() => {
                    setDetailItem(item);
                    setShowDeleteProductSheet(true);
                  }}
                >
                  <Icon type="delete" src={deleteIcon} />
                </Btn>

                <Btn
                  type="add"
                  onClick={() => handleOperation(item, "add", "update")}
                >
                  <Icon src={addIcon} />
                </Btn>
                <DivQuantity>
                  <Quantity>{item?.quantity}</Quantity>
                  <MeasureUnit>{item?.product?.measure_unit}</MeasureUnit>
                </DivQuantity>
                {item?.quantity !== 1 ? (
                  <Btn onClick={() => handleOperation(item, "minus", "update")}>
                    <Icon type="mines" src={minusIcon} />
                  </Btn>
                ) : (
                  <Btn
                    onClick={() => handleOperation(item, "delete", "delete")}
                  >
                    <Icon type="delete" src={deleteIcon} />
                  </Btn>
                )}
              </Item>
            ))}
          </DivItems>
          <ContainerShowShoppingCart show={items?.items?.length !== 0}>
            <DivShowShoppingCart>
              <ButtonShowShoppingCart to="/app/address" state={items}>
                ادامه فرآیند خرید
              </ButtonShowShoppingCart>
              <SumPrice>
                {numberWithCommas(items?.sum_price)}
                <UnitSumPrice>تومان</UnitSumPrice>
              </SumPrice>
            </DivShowShoppingCart>
          </ContainerShowShoppingCart>
        </Container>
      )}
    </>
  );
};

export default Body;
