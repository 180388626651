import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {
  Container,
  Item,
  Card,
  DetailItem,
  Title,
  DivSlider,
  Slider,
  DivTitle,
  DashedLine,
  DivItems,
  Img,
  DiscountPrice,
  DivDiscountPrice,
  Price,
  DivImg,
  DiscountValue,
  Value,
} from "./Element";
import card from "../../Images/cards/discount.svg";
import { useEffect, useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const Body = ({ sliders, data }) => {
  const [items, setItems] = useState([]);
  const [slidersImage, setSlidersImage] = useState([]);
  const [imageLoadingStates, setImageLoadingStates] = useState([]); // مدیریت وضعیت لودینگ هر تصویر

  const { t } = useTranslation();
  useEffect(() => {
    if (sliders) {
      setSlidersImage(sliders);
      setItems(data);
      setImageLoadingStates(sliders.map(() => true)); // وضعیت لودینگ برای هر تصویر
    }
  }, [sliders, data]);
  // const handleImageLoad = (index) => {
  //   setImageLoadingStates((prevStates) => {
  //     const newStates = [...prevStates];
  //     newStates[index] = false; // لودینگ تصویر را غیر فعال می‌کنیم
  //     return newStates;
  //   });
  // };
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <Container>
        <DivSlider>
          <Swiper
            dir="ltr"
            loop={true}
            lazy={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {sliders.map((slide, index) => (
              <SwiperSlide key={index}>
                {/* {imageLoadingStates[index] ? (
                  <Skeleton height="161px" width="100%" /> // نمایش Skeleton در حال لود تصویر
                ) : ( */}
                <Slider
                  style={{
                    backgroundImage: `url(${slide?.image})`,
                  }}
                ></Slider>
                {/*)}
                 <img
                  src={slide.image}
                  alt={`slide ${index}`}
                  style={{ display: "none" }} // مخفی کردن تصویر در حین لود
                  onLoad={() => handleImageLoad(index)} // فراخوانی تابع بعد از لود شدن تصویر
                /> */}
              </SwiperSlide>
            ))}
          </Swiper>
        </DivSlider>
        <DivTitle>
          <DashedLine />
          محصولات تخفیف دار
          <DashedLine />
        </DivTitle>
        <DivItems>
          {items.map((item, index) => (
            <Item
              key={item.id}
              to="/app/categories/products/product"
              state={item}
            >
              <Card src={card} />
              <DivImg>
                <Img src={item.image} />

                <DiscountValue>
                  <Value>
                    {item?.minimum_detail?.discount_type === "percentage"
                      ? item?.minimum_detail?.discount_value + "%"
                      : numberWithCommas(item?.minimum_detail?.discount_value) +
                        "-"}
                  </Value>
                </DiscountValue>
              </DivImg>
              <DetailItem>
                <Title>{item?.title}</Title>
                <Price>
                  {numberWithCommas(item?.minimum_detail?.confirmed_price)}
                </Price>
                <DivDiscountPrice>
                  <DiscountPrice>
                    {numberWithCommas(item?.minimum_detail?.discount_price)}
                  </DiscountPrice>
                  تومان
                </DivDiscountPrice>
              </DetailItem>
            </Item>
          ))}
        </DivItems>
      </Container>
    </>
  );
};

export default Body;
