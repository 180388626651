import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";
import {
  Container,
  Item,
  Title,
  DivItems,
  Img,
  DivImg,
  DivDetails,
  DivShowShoppingCart,
  ContainerShowShoppingCart,
  SumPrice,
  UnitSumPrice,
  Helper,
  BoxItems,
  ButtonNext,
  BoxCalendar,
  Input,
  DivInput,
  IconInput,
  DivDetailes,
  Detail,
} from "./Element";

import user from "../../Images/peymentPage/user.svg";
import mobile from "../../Images/peymentPage/phone.svg";
import online from "../../Images/peymentPage/online.svg";
import ondoor from "../../Images/peymentPage/ondoor.svg";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const customTheme = "red";
const Body = ({
  sumPrice,
  invoice_id,
  invoice_details,
  showWarning,
  showError,
}) => {
  const [sum, setSum] = useState("");
  const [invoiceId, setInvoiceId] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setSum(sumPrice);
    setInvoiceId(invoice_id);
    setInvoiceDetails(invoice_details);
  }, [sumPrice, invoice_id, invoice_details]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleChangeName = (e) => {
    setName(e.currentTarget.value);
  };
  const handleChangePhone = (e) => {
    setPhone(e.currentTarget.value);
  };

  const handleNext = async () => {
    if (name === "") {
      showWarning("لطفا نام تحویل گیرنده را وارد کنید.");
    } else if (phone === "") {
      showWarning("لطفا شماره موبایل تحویل گیرنده را وارد کنید.");
    } else if (paymentMethod === null) {
      showWarning("لطفا نحوه پرداخت را انتخاب نمایید.");
    } else {
      let response;
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        await axiosConfig.put(
          "/invoice/recipient_payment/update/" + invoiceDetails?.id,
          {
            recipient_full_name: name,
            recipient_phone: phone,
            payment_method: paymentMethod,
          },
          config
        );
        if (paymentMethod === "ondoor") {
          try {
            await axiosConfig.put(
              "/invoice/complete/" + invoiceDetails?.id,
              { description: null },
              config
            );
            localStorage.setItem("countCartItems", 0);
            navigate("/app/profile/invoices");
          } catch (error) {
            showError();
          }
        } else {
          try {
            response = await axiosConfig.get(
              "/web_pay/" + invoiceDetails?.code,
              config
            );
            window.location.href = response?.data?.data; // هدایت به لینک خارجی
          } catch (error) {
            showError();
          }
        }
      } catch (error) {
        showError();
      }
    }
  };
  const handleSelectType = (type) => {
    setPaymentMethod(type);
  };
  return (
    <>
      <Container className={customTheme}>
        <Helper>لطفا اطلاعات تحویل گیرنده را وارد کنید.</Helper>
        <BoxCalendar>
          <DivItems>
            <DivInput>
              <IconInput src={user} />
              <Input
                placeholder="نام تحویل گیرنده"
                id="name"
                value={name}
                onChange={handleChangeName}
              />
            </DivInput>
            <DivInput>
              <IconInput src={mobile} />
              <Input
                placeholder="شماره موبایل تحویل گیرنده"
                id="phone"
                value={phone}
                maxLength={11}
                inputMode="numeric"
                onChange={handleChangePhone}
              />
            </DivInput>
          </DivItems>
        </BoxCalendar>

        <Helper>لطفا نحوه پرداخت را انتخاب کنید.</Helper>
        <BoxCalendar>
          <DivItems>
            <Item
              active={paymentMethod === "online"}
              onClick={() => handleSelectType("online")}
            >
              <DivDetails>
                <DivImg active={paymentMethod === "online"}>
                  <Img src={online} />
                </DivImg>
                <Title>پرداخت آنلاین</Title>
              </DivDetails>
            </Item>
            <Item
              active={paymentMethod === "ondoor"}
              onClick={() => handleSelectType("ondoor")}
            >
              <DivDetails>
                <DivImg active={paymentMethod === "ondoor"}>
                  <Img src={ondoor} />
                </DivImg>
                <Title>پرداخت درب محل</Title>
              </DivDetails>
            </Item>
          </DivItems>
        </BoxCalendar>
        <DivDetailes>
          <Detail>
            <label>آدرس : </label>
            <p>{invoiceDetails?.branch?.address}</p>
          </Detail>
          <tr />
          <Detail final>
            <label>زمان تحویل :</label>
            <p>
              {invoiceDetails?.jalali_delivery_date +
                " " +
                invoiceDetails?.delivery_time?.title}
            </p>
          </Detail>
        </DivDetailes>
      </Container>

      <ContainerShowShoppingCart show>
        <DivShowShoppingCart>
          <ButtonNext onClick={handleNext}>ثبت سفارش</ButtonNext>
          <SumPrice>
            {numberWithCommas(sum)}
            <UnitSumPrice>تومان</UnitSumPrice>
          </SumPrice>
        </DivShowShoppingCart>
      </ContainerShowShoppingCart>
    </>
  );
};

export default Body;
