import React, { useEffect, useState } from "react";
import axiosConfig from "../../ApiCall/axiosConfig";
import "./style.css";
import Mapir from "mapir-react-component";
import {
  BoxSearch,
  ButtonInsertAddress,
  Container,
  ContainerTextBoxAddress,
  DivInput,
  DivMyLocation,
  DivSearch,
  DivTextarea,
  IconInput,
  Img,
  Input,
  Marker,
  Search,
  SearchItem,
  Textarea,
} from "./AddressElement";
import search from "../../Images/addressPage/search.svg";
import marker from "../../Images/addressPage/marker.svg";
import addressIcon from "../../Images/addressPage/address.svg";
import titleAddress from "../../Images/addressPage/titleAddress.svg";
import myLocation from "../../Images/addressPage/myLocation.svg";
import { useNavigate } from "react-router-dom";
// لوکیشن دیفالت در صورت عدم دسترسی
const apiKey =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjUzZDdiZDg4OTMyOTE2OGY1NjZlNTA5MzY0YzkwMzg2ZjJmNmUzMzg0YjAyZGUzZDI1OTJkNTNlOTIwMzczODQxYTA3ZTUxMmU2ZTk1ZWJhIn0.eyJhdWQiOiIyNTQ4MSIsImp0aSI6IjUzZDdiZDg4OTMyOTE2OGY1NjZlNTA5MzY0YzkwMzg2ZjJmNmUzMzg0YjAyZGUzZDI1OTJkNTNlOTIwMzczODQxYTA3ZTUxMmU2ZTk1ZWJhIiwiaWF0IjoxNzAzNDE3MDY2LCJuYmYiOjE3MDM0MTcwNjYsImV4cCI6MTcwNTkyMjY2Niwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.Zb_GeDElPake2DltEomo91uAlCYQWAIKHor3Z349YxxORJMVbBTx-qduSqVB5Z9iwMfgNjDUxPSMW4YQTR_jmizkx1HOsdiJvhN8uEhaLB9atBZhYtkEeUBsgvmPgRGOxKeTsJPDgx6pLKYe-8PwxUKigtZ65LwCr_ZSee-szEfofJRBTm3O47ELYk9Z2A28X4sG-b0Qm4Zj5VYZ-Ymwa8WOaXSj94s0OI9_MaZmP3ltjMnEO3ZObXRTLoNWnIEjnwhazPrtS-eEheQ12bhkq-sNXCwHbMxg6mdB9bGgjY6tpcR4r7oodursdvWViQHf-rEhfHNb4rmvqxCW3IGY6w";
const Map = Mapir.setToken({
  transformRequest: (url) => {
    return {
      url: url,
      headers: {
        "x-api-key": apiKey,
        "Mapir-SDK": "reactjs",
      },
    };
  },
});
const defaultLocation = { lat: 35.701087, lon: 51.397326 };

const App = ({ showError, showWarning }) => {
  const [location, setLocation] = useState({ lat: 35.701087, lon: 51.397326 });
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [user, setUser] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userManiarApp")));
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          setLocation(userLocation);
          fetchAddress(userLocation.lat, userLocation.lon);
        },
        (error) => {
          setError(error.message);
          setLocation(defaultLocation);
          fetchAddress(defaultLocation.lat, defaultLocation.lon);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      setLocation(defaultLocation);
      fetchAddress(defaultLocation.lat, defaultLocation.lon);
    }
  }, []);

  const fetchAddress = async (lat, lon) => {
    const config = {
      headers: {
        "x-api-key": apiKey,
      },
    };
    try {
      const { data } = await axiosConfig.get(
        `https://map.ir/reverse/fast-reverse?lat=${lat}&lon=${lon}`,
        config
      );
      setAddress(data?.address.replace("ایران، ", ""));
    } catch (error) {
      console.log("Error fetching address:", error);
    }
  };
  const handleSetMyLocation = async () => {
    const defaultLocation = { lat: 35.701087, lon: 51.397326 }; // موقعیت مکانی دیفالت (مثلاً تهران)

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          setLocation(userLocation);
          fetchAddress(userLocation.lat, userLocation.lon);
        },
        (error) => {
          setError(error.message);
          console.log(error.message);
          setLocation(defaultLocation);
          fetchAddress(defaultLocation.lat, defaultLocation.lon);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      setLocation(defaultLocation);
      fetchAddress(defaultLocation.lat, defaultLocation.lon);
    }
  };

  const handleAddAddress = async () => {
    if (title === "") {
      showWarning("لطفا عنوان آدرس خود را وارد نمایید.");
    } else if (address === "") {
      showWarning("لطفا آدرس خود را وارد نمایید.");
    } else {
      let isOkResponse;
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        await axiosConfig.post(
          "/application/branch/store",
          {
            title: title,
            slogan: "",
            trade_unit_id: user?.trade_units?.[0]?.id,
            theme: "orange",
            user_id: user?.id,
            latitude: location?.lat,
            longitude: location?.lon,
            address: address,
            status: 1,
          },
          config
        );
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
      }
      if (isOkResponse) {
        navigate(-1);
      } else {
        showError();
      }
    }
  };
  const handleOnDrog = (e) => {
    setLocation({
      lat: e.getCenter().lat,
      lon: e.getCenter().lng,
    });
    let latValue = e.getCenter().lat;
    let lonValue = e.getCenter().lng;

    async function fetchData() {
      const config = {
        headers: {
          "x-api-key": apiKey,
        },
      };
      try {
        const { data } = await axiosConfig.get(
          "https://map.ir/reverse/fast-reverse?lat=" +
            latValue +
            "&lon=" +
            lonValue,
          config
        );
        setAddress(data?.address.replace("ایران، ", ""));
      } catch (error) {
        console.log("Error fetching products:", error);
        // showError();
      }
    }
    fetchData();
  };
  const replaceArabicYeWithPersianYe = (text) => {
    if (!text) return "";
    return text.replace(/ي/g, "ی").replace(/ك/g, "ک"); // تبدیل "ي" عربی به "ی" فارسی
  };
  const handleChange = (e) => {
    if (e.currentTarget.value === "") {
      setShowSearchBox(false);
    } else {
      setShowSearchBox(true);
      async function fetchData() {
        const config = {
          headers: {
            "x-api-key": apiKey,
          },
        };
        try {
          const { data } = await axiosConfig.post(
            "https://map.ir/search/v2/autocomplete",
            {
              text: replaceArabicYeWithPersianYe(e.currentTarget.value),
              $select: "roads,poi",
              $filter: "province eq البرز",
            },
            config
          );
          setSearchItems(data?.value);
        } catch (error) {
          console.log("Error fetching products:", error);
          // showError();
        }
      }
      fetchData();
    }
  };

  const handleChangeTitle = (e) => {
    setTitle(e.currentTarget.value);
  };
  const handleChangeAddress = (e) => {
    setAddress(e.currentTarget.value);
  };
  const handleSelectLocation = (location) => {
    setShowSearchBox(false);
    const newLocation = {
      lat: location?.geom?.coordinates?.[1],
      lon: location?.geom?.coordinates?.[0],
    };
    fetchAddress(newLocation.lat, newLocation.lon);
    setLocation(newLocation);
  };

  return (
    <>
      <Container className="App">
        <DivSearch>
          <DivInput>
            <IconInput src={search} />
            <Search
              placeholder="جستجو کنید ..."
              id="search"
              onChange={handleChange}
            />
          </DivInput>
        </DivSearch>
        {showSearchBox && (
          <BoxSearch>
            {searchItems?.map((item, index) => (
              <SearchItem
                key={index}
                onClick={() => {
                  handleSelectLocation(item);
                }}
              >
                {item?.address}
              </SearchItem>
            ))}
          </BoxSearch>
        )}
        <Mapir
          zoom={[14]}
          onDrag={(e) => {
            handleOnDrog(e);
          }}
          center={[location?.lon, location?.lat]}
          Map={Map}
        ></Mapir>
        <Marker src={marker} />
        <DivMyLocation onClick={handleSetMyLocation}>
          <Img src={myLocation} />
        </DivMyLocation>
        <ContainerTextBoxAddress show>
          <DivInput>
            <IconInput src={titleAddress} />
            <Input
              placeholder="عنوان آدرس"
              id="title"
              value={title}
              onChange={handleChangeTitle}
            />
          </DivInput>

          <DivTextarea>
            <IconInput src={addressIcon} />
            <Textarea
              placeholder="آدرس"
              id="address"
              value={address}
              onChange={handleChangeAddress}
            />
          </DivTextarea>
          <ButtonInsertAddress onClick={handleAddAddress}>
            ثبت آدرس جدید
          </ButtonInsertAddress>
        </ContainerTextBoxAddress>
      </Container>
    </>
  );
};

export default App;
