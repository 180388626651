import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";

import {
  Container,
  Item,
  Card,
  Title,
  DivItems,
  Img,
  DiscountPrice,
  DivDiscountPrice,
  Price,
  DivImg,
  Btn,
  DivDetails,
  Description,
  DivDiscount,
  DivPrice,
  Company,
  CompanyDefualtIcon,
  CustomSheet,
  DivRaedyCarts,
  RaedyCart,
  ImgReadyCart,
  ButtonAddRaedyCart,
  ImgButton,
  DivInput,
  Input,
} from "./InvoiceElement";
import card from "../../Images/cards/invoiceProduct.svg";
import defualtCompanyIcon from "../../Images/defaultImage/deliveryCompany.svg";
import { useEffect, useState } from "react";
const Body = ({
  data,
  readyCartData,
  updateData,
  showError,
  updateShoppingCartData,
}) => {
  const [items, setItems] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setItems(data);
  }, [data, readyCartData]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleOperation = async (item, operation, type) => {
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    if (type === "update") {
      let quantity;
      if (operation === "add") {
        quantity = item?.quantity + 1;
      } else {
        quantity = item?.quantity - 1;
      }
      try {
        await axiosConfig.put(
          "/shopping_cart/item/update/" + item?.id,
          {
            quantity: quantity,
          },
          config
        );
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
        console.log("Error response from server:", error);
      }
    } else if (type === "delete") {
      try {
        await axiosConfig.delete(
          "/shopping_cart/item/delete/" + item?.id,
          config
        );
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
        console.log("Error response from server:", error);
      }
    }

    if (isOkResponse) {
      updateShoppingCartData();
    }
  };
  return (
    <>
      <Container>
        <DivItems>
          {items?.shopping_cart_items?.map((item, index) => (
            <Item key={index}>
              <Card src={card} />
              <DivImg>
                <Img src={item?.product?.image} />
                {item?.detail?.confirmed_price !==
                  item?.detail?.discount_price &&
                  item?.detail?.discount_type === "percentage" && (
                    <DivDiscount>
                      {item?.detail?.discount_value + "%"}
                    </DivDiscount>
                  )}
                {item?.detail?.confirmed_price !==
                  item?.detail?.discount_price &&
                  item?.detail?.discount_type === "fixed" && (
                    <DivDiscount>
                      {numberWithCommas(item?.detail?.discount_value) + "-"}
                    </DivDiscount>
                  )}
              </DivImg>
              <DivDetails>
                <Title>{item?.product?.title}</Title>
                <Company>
                  {item?.company?.logo ? (
                    <img src={item?.company?.logo} alt="companyLogo" />
                  ) : (
                    <CompanyDefualtIcon src={defualtCompanyIcon} />
                  )}
                  {item?.company?.title}
                </Company>
                <Description>{item?.detail?.description}</Description>
              </DivDetails>
              {item?.detail ? (
                <DivPrice>
                  <DivDiscountPrice>
                    قیمت واحد :
                    <DiscountPrice type="unit">
                      {item?.detail?.confirmed_price !==
                        item?.detail?.discount_price &&
                        (item?.detail?.discount_type === "percentage" ||
                          item?.detail?.discount_type === "fixed") && (
                          <Price>
                            {numberWithCommas(item?.detail?.confirmed_price)}
                          </Price>
                        )}
                      {numberWithCommas(item?.detail?.discount_price)}
                    </DiscountPrice>
                    تومان
                  </DivDiscountPrice>
                  <DivDiscountPrice>
                    قیمت کل :
                    <DiscountPrice>
                      {numberWithCommas(
                        item?.detail?.discount_price * item?.quantity
                      )}
                    </DiscountPrice>
                    تومان
                  </DivDiscountPrice>
                </DivPrice>
              ) : (
                <DiscountPrice>ناموجود</DiscountPrice>
              )}

              <Btn
                type="delete"
                onClick={() => handleOperation(item, "minus", "update")}
              >
                {item?.quantity + " " + item?.product?.measure_unit}
              </Btn>
            </Item>
          ))}
        </DivItems>
      </Container>
    </>
  );
};

export default Body;
