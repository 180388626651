import axios from "axios";

const BaseUrl = axios.create({
  // my server :
  // baseURL: "https://api.roysa-app.ir/api",
  // baseURL: "https://api.roysa-app.com/api",
  baseURL: "https://api.maniar-app.ir/api",

  // baseURL: "http://localhost:8080/api/v1",
});

export default BaseUrl;
