import {
  Container,
  Item,
  DivItems,
  Img,
  DivImg,
  TextNoItems,
  Company,
  DivItem,
  FadeDiv,
  MainDiv,
} from "./ImagesElement";
import { useEffect, useState } from "react";
const customTheme = "red";
const Body = ({ data, companyName }) => {
  const [items, setItems] = useState([]);
  const [company, setCompany] = useState([]);
  const [showImg, setShowImg] = useState([]);

  useEffect(() => {
    setItems(data);
    setShowImg(data?.[0]);
    setCompany(companyName);
  }, [data, companyName]);
  const handleChangeImg = (item) => {
    setShowImg(item);
  };
  return (
    <>
      {items?.length === 0 ? (
        <TextNoItems>
          <p>هیچ تصویری برای نمایش وجود ندارد ندارد</p>
        </TextNoItems>
      ) : (
        <Container className={customTheme}>
          <Company>تصاویر روز {company}</Company>
          <DivImg>
            <Img src={showImg?.image} />
          </DivImg>
          <MainDiv>
            <DivItems showShoppingCart={items?.length !== 0}>
              {items?.map((item, index) => (
                <DivItem
                  key={index}
                  onClick={() => handleChangeImg(item)}
                  active={item?.id === showImg?.id}
                >
                  <Item src={item?.image} active={item?.id === showImg?.id} />
                </DivItem>
              ))}
            </DivItems>
            <FadeDiv />
          </MainDiv>
        </Container>
      )}
    </>
  );
};

export default Body;
