import {
  Container,
  DescriptionTopHeader,
  DivHeaderBtn,
  DivTitleTopHeader,
  ImgHeaderBtn,
  SvgHeaderBtn,
  TitleTopHeader,
  TopHeader,
} from "./HeaderElements";
import back from "../../Images/headerIcons/back.svg";
import defualtLogo from "../../Images/defaultImage/deliveryCompany.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Img } from "./Element";

const Header = ({ title, description, page, logo }) => {
  const navigate = useNavigate();
  useEffect(() => {}, []);

  return (
    <>
      <Container>
        <TopHeader>
          <DivHeaderBtn onClick={() => navigate(-1)}>
            <ImgHeaderBtn src={back} />
          </DivHeaderBtn>
          <DivTitleTopHeader>
            <TitleTopHeader>{title}</TitleTopHeader>
            <DescriptionTopHeader>{description}</DescriptionTopHeader>
          </DivTitleTopHeader>
          {page === "images" ? (
            <DivHeaderBtn>
              {logo ? (
                <ImgHeaderBtn src={logo} />
              ) : (
                <SvgHeaderBtn src={defualtLogo} />
              )}
            </DivHeaderBtn>
          ) : (
            <DivHeaderBtn hide></DivHeaderBtn>
          )}
        </TopHeader>
      </Container>
    </>
  );
};

export default Header;
