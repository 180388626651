import {
  Container,
  Buttons,
  Button,
  ButtonLink,
  ButtonImg,
  Dot,
  Count,
  BackgroundBottomNavigation,
} from "./Elements";
import { useEffect, useRef, useState } from "react";
import home from "../../Images/bottomNavigations/home.svg";
import products from "../../Images/bottomNavigations/products.svg";
import cart from "../../Images/bottomNavigations/cart.svg";
import search from "../../Images/bottomNavigations/search.svg";
import profile from "../../Images/bottomNavigations/profile.svg";
import backgroundBottomNavigation from "../../Images/bottomNavigations/background.svg";

const BottomNavigationComponent = ({
  countCartItems,
  activeBottomNavigation,
  updateBottomNavigation,
}) => {
  const [activeBottomNavigationId, setActiveBottomNavigationId] =
    useState(null);
  const [count, setCount] = useState(null);
  const bottomNavigations = [
    { id: 1, url: "home", icon: home },
    { id: 2, url: "categories", icon: products },
    { id: 5, url: "search", icon: search },
    { id: 3, url: "cart", icon: cart },
    { id: 4, url: "profile", icon: profile },
  ];
  const firstNavLinkRef = useRef(null);
  useEffect(() => {
    // Check if firstNavLinkRef is defined and click it
    if (activeBottomNavigation) {
      setActiveBottomNavigationId(activeBottomNavigation);
    } else {
      if (firstNavLinkRef.current) {
        firstNavLinkRef.current.click();
      }
    }
    setCount(countCartItems);
  }, [activeBottomNavigation, countCartItems]);

  const changeActive = (subMenu) => {
    updateBottomNavigation(subMenu.id);
  };
  let customTheme = "red";
  return (
    <>
      <Container className={customTheme}>
        <div>
          <BackgroundBottomNavigation src={backgroundBottomNavigation} />
          <Buttons>
            {bottomNavigations.map((bottomNavigation, index) => (
              <Button
                active={
                  bottomNavigation.id === activeBottomNavigationId
                    ? true
                    : false
                }
                key={bottomNavigation.id}
              >
                <ButtonLink
                  search={bottomNavigation?.id === 5}
                  withMargin={
                    bottomNavigation?.id === 2 || bottomNavigation?.id === 3
                  }
                  ref={index === 0 ? firstNavLinkRef : null} // Assign the ref to the first NavLink
                  active={
                    bottomNavigation.id === activeBottomNavigationId
                      ? true
                      : false
                  }
                  to={bottomNavigation.url}
                  onClick={() => changeActive(bottomNavigation)}
                >
                  {bottomNavigation.url === "cart" && <Count>{count}</Count>}
                  <ButtonImg
                    src={bottomNavigation.icon}
                    active={bottomNavigation.id === activeBottomNavigationId}
                    search={bottomNavigation?.id === 5}
                  />
                  <Dot
                    active={bottomNavigation.id === activeBottomNavigationId}
                    search={bottomNavigation?.id === 5}
                  />
                </ButtonLink>
              </Button>
            ))}
          </Buttons>
        </div>
      </Container>
    </>
  );
};

export default BottomNavigationComponent;
