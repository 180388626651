import styled, { keyframes } from "styled-components";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

export const Container = styled.div`
  /* margin-top: 138px; */
  height: calc(100vh - 76px);
  @supports (height: calc(100svh - 76px)) {
    height: calc(100svh - 76px);
  }

  background: var(--background);
  width: 100%;
  display: flex;
  align-content: flex-start;
  gap: 23px;
  flex-wrap: wrap;
  overflow-x: hidden;
  padding-top: 29px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const TextNoItems = styled.div`
  /* margin-top: 138px; */
  height: calc(90vh - 205px);
  @supports (height: calc(90svh - 205px)) {
    height: calc(90svh - 205px);
  }
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 11px;
    font-weight: 600;
  }
`;

export const DivSearch = styled.div`
  width: 85vw;
  height: 51px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  white-space: nowrap;
`;
export const DivInput = styled.div`
  position: relative;
  height: 51px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const Input = styled.input`
  padding-inline: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  width: 100%;
  height: 60%;
  text-align: center;
  background: none;
  border: none;
  margin: 0;
  outline: none;
  border-inline: 1px solid var(--strokeInput);
  transition: all 0.2s ease-in-out;
  &::placeholder {
    color: var(--placeholder);
    opacity: 1; /* Firefox */
  }
  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--placeholder);
  }
  &:active {
    background: white;
  }
`;
export const IconInput = styled.img`
  position: absolute;
  height: 51px;
  width: 51px;
  & path {
    stroke: var(--firstColor);
  }
  & circle {
    stroke: var(--firstColor);
    fill: var(--firstColor);
  }
`;
export const ButtonFilter = styled.button`
  height: 51px;
  min-width: 51px;
  outline: none;
  border: none;
  background: var(--firstColor);
  border-radius: 17px;
`;
export const ImgFilter = styled.img`
  height: 51px;
  max-width: 51px;
`;
export const DivRaedyCarts = styled.div`
  width: 88vw;
  border: 1px solid var(--firstColor);
  border-radius: 17px 17px 0 0;
  background: white;
`;
export const RaedyCart = styled.div`
  position: relative;
  margin-inline: 30px;
  width: calc(100% - 60px);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #6d4c10;
  border-bottom: 1px solid var(--separatorReadyCarts);

  &:last-child {
    border-bottom: none;
  }
  p {
    width: 100%;
    text-align: center;
    padding-inline: 42px;
  }
`;
export const ButtonAddRaedyCart = styled.div`
  width: 88vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: white;
  background: var(--firstColor);
  border-radius: ${({ emptyReadyCart }) =>
    emptyReadyCart ? "17px" : "0 0 17px 17px"};
  p {
    text-align: center;
    margin-left: 35px;
  }
`;
export const ImgReadyCart = styled(SVG)`
  position: absolute;
  right: 0;
  & path {
    &:nth-child(1) {
      stroke: var(--firstColor);
    }
    &:nth-child(3) {
      fill: var(--firstColor);
    }
    &:nth-child(4) {
      fill: var(--firstColor);
    }
  }
`;
export const DivItems = styled.div`
  width: 85vw;
  margin: auto;
  display: flex;
  align-content: flex-start;
  row-gap: 14px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: ${({ showShoppingCart }) =>
    showShoppingCart ? "calc(10svh + 36px)" : "36px"};
`;

export const Image = styled.img`
  background: white;
  max-height: 293px;
  width: 293px;
  max-width: 85vw;
  border-radius: 13px;
  margin-inline: auto;
`;

export const Item = styled.div`
  font-size: 13px;
  width: 100%;
  position: relative;
  background: white;
  border: 1px dashed
    ${({ withQuantity }) => (withQuantity ? "var(--firstColor)" : "#d8d1ca")};
  border-radius: 13px;
  padding: 12px 18px 18px 18px;
`;
export const ReadyCartItem = styled.div`
  font-size: 13px;
  width: 100%;
  position: relative;
  padding-inline: 2%;
`;
export const DivImg = styled.div`
  width: 15.8%;
  height: 100%;
  display: flex;
  background: white;
  border-radius: 50%;
`;
export const Img = styled.img`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  border-radius: 50%;
`;
export const DefualtImg = styled(SVG)`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  border-radius: 50%;
  & path {
    fill: var(--firstColor);
  }
`;
export const DivDiscount = styled.div`
  border-radius: 7px;
  padding: 4px 6px;
  background: var(--secondColor);
  color: white;
  font-size: 10px;
  font-weight: 900;
`;
export const BtnReadyCart = styled.div`
  position: absolute;
  ${({ type }) =>
    (type === "delete" && "right:0;top: 3.5%;") ||
    (type === "addToShoppingCart" && "left:0;bottom: 4.5%;")};
  height: 30.2%;
  width: 10%;
  display: flex;
  background: ${({ type }) =>
    type === "delete" ? "var(--red)" : "var(--firstColor)"};
  border-radius: 42%;
`;
export const Btn = styled.button`
  height: 100%;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  border-radius: ${({ direction }) =>
    direction === "right" ? "0 8px 8px 0" : "8px 0 0 8px"};
  transition: all 0.2s ease-in-out;
  &:active {
    background: var(--background);
  }
`;
export const DivQuantity = styled.div`
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  background: var(--backgroundInput);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Quantity = styled.p`
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
`;
export const MeasureUnit = styled.p`
  font-size: 7px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: var(--measureUnitColor);
`;
export const DivStar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 34px;
  min-width: 52px;
  background: var(--startBackgroundProductPage);
  display: flex;
  border-radius: 12px 0px 12px 0px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  gap: 3px;
  img {
    margin-top: -2px;
  }
`;
export const DivDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const Title = styled.p`
  font-size: 13px;
  font-weight: 800;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const DivAvailable = styled.div`
  font-size: 10px;
  font-weight: 700;
  width: 100%;
  color: black;
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 4%;
`;
export const Available = styled.div`
  font-size: 10px;
  font-weight: 700;
  color: var(--green);
  display: flex;
  align-items: center;
`;
export const UnAvailable = styled.div`
  font-size: 10px;
  font-weight: 700;
  color: var(--red);
  display: flex;
  align-items: center;
`;
export const Separator = styled.div`
  border-right: 1px solid var(--gray);
  height: 14px;
`;

export const DivMoreDetails = styled.div`
  width: 100%;
  color: black;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 4%;
  gap: 19px;
  p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const DivImages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  direction: ltr;
  img {
    min-width: 27%;
    max-width: 27%;
    min-height: 86px;
    max-height: 86px;
    border-radius: 50%;
    border: 2px solid var(--secondColor);
    margin-inline-start: -8.4%;
  }
`;
export const DivButtons = styled.div`
  width: 100%;
  height: 45px;
  gap: 13px;
  display: flex;
`;
export const ImgButton = styled(SVG)`
  min-height: 29px;
  width: 29px;
`;

export const Button = styled(Link)`
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  ${({ title }) =>
    title === "images"
      ? "background:var(--imagesButtonBackground);color:var(--secondColor);"
      : "background:var(--commentsButtonBackground);color:var(--firstColor);"}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-inline-end: 8px;
  white-space: nowrap;

  text-decoration: none;
`;
export const AddToCartButton = styled.button`
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  background: var(--firstColor);
  color: white;
  border: none;
  opacity: ${({ disabledBtn }) => (disabledBtn ? "0.7" : "1")};
  &:active {
    background-color: var(--middleFirstColor); /* رنگ در هنگام کلیک */
  }
`;
export const CompanyDefualtIcon = styled(SVG)`
  height: 20px;
  width: 20px;
  & path {
    fill: var(--firstColor);
  }
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  color: black;
  margin-top: 2%;
  height: 10%;
  text-align: justify;
`;
export const DescriptionProduct = styled.p`
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  color: black;
  margin-top: 1%;
  text-align: justify;
`;

export const Value = styled.div`
  height: 100%;
  background: var(--secondColor);
  padding-inline: 4px;
  border-radius: 7px;
  color: white;
  font-size: 10px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DetailItem = styled.div`
  position: absolute;
  inset-inline: 0.3vw;
  top: 58%;
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  align-items: center;
`;
export const DivReadyCartPrice = styled.div`
  position: absolute;
  z-index: 0;
  bottom: 14%;
  inset-inline-start: 8%;
`;

export const Price = styled.span`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: var(--placeholder) !important;
  text-decoration-line: line-through;
`;
export const DivDiscountPrice = styled.div`
  font-size: 11px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-block: 14px;
  padding-inline-start: 2px;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;
export const DiscountPrice = styled.div`
  font-size: 14px;
  font-weight: 900;
  color: var(--gray);
  margin-inline-end: ${({ type }) => (type === "unit" ? "11px" : "4px")};
  display: flex;
  gap: 3px;
  align-items: center;
  p {
    color: var(--firstColor);
  }
  span {
    font-size: 11px;
    font-weight: 700;
    color: black;
  }
`;
export const Icon = styled(SVG)`
  height: 100%;
  width: 100%;
  & path {
    fill: var(--firstColor);
  }
`;
export const ContainerTextBoxComment = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 20px 6vw;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(31.5px);
  -webkit-backdrop-filter: blur(31.5px);
  z-index: 0;
  display: flex;
  gap: 5%;
`;
export const DivInputAndBtn = styled.div`
  width: 84%;
`;
export const DivRate = styled.div`
  width: 11%;
  direction: ltr;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  background: white;
  border-radius: 17px;
  border: 1px solid var(--searchStrokeColor);
`;
export const ContainerShowShoppingCart = styled.div`
  position: fixed;
  height: 10vh;
  @supports (height: 10svh) {
    height: 10svh;
  }
  width: 100vw;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(31.5px);
  -webkit-backdrop-filter: blur(31.5px);
  z-index: 0;
  transform: ${({ show }) =>
    show ? "translate(0%, 0%) scale(1)" : "translate(0%, 100%) scale(1)"};
  transition: transform 0.3s ease-in-out;
`;
export const DivShowShoppingCart = styled.div`
  height: 10vh;
  @supports (height: 10svh) {
    height: 10svh;
  }
  width: 87vw;
  margin-inline: auto;
  display: flex;
  align-items: center;
`;
export const ButtonShowShoppingCart = styled(Link)`
  height: 6vh;
  @supports (height: 6svh) {
    height: 6svh;
  }
  min-width: 62%;
  background: var(--secondColor);
  border-radius: 13px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; // حذف هایلایت آبی در دستگاه‌های لمسی
  transition: all 0.2s ease-in-out;
  &:active {
    filter: brightness(0.75);
  }
`;
export const ButtonInsertComment = styled.div`
  height: 6vh;
  @supports (height: 6svh) {
    height: 6svh;
  }
  min-width: 62%;
  background: var(--firstColor);
  border-radius: 13px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:active {
    filter: brightness(0.75);
  }
`;
export const SumPrice = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  width: 100%;
  text-align: center;
`;
export const UnitSumPrice = styled.span`
  color: var(--secondColor);
  margin-inline-start: 5px;
`;
export const DivBtnBottomSheet = styled.div`
  height: 50px;
  width: 88vw;
  margin-inline: auto;
  display: flex;
  align-items: center;
  gap: 25px;
`;
export const BtnBottomSheet = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ type }) => (type === "ok" ? "var(--green)" : "var(--red)")};
  color: white;
  border-radius: 13px;
`;
export const bounce = keyframes`
  0%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-10px); }
`;

export const Loader = styled.div`
  display: flex;
  gap: 4px;

  div {
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
    animation: ${bounce} 0.6s infinite ease-in-out;
  }

  div:nth-child(1) {
    animation-delay: -0.2s;
  }
  div:nth-child(2) {
    animation-delay: -0.1s;
  }
  div:nth-child(3) {
    animation-delay: 0s;
  }
`;
