import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Application from "./Pages";
import Home from "./Pages/Home";
import Products from "./Pages/SubPages/Products";
import Categories from "./Pages/Categories";
import Search from "./Pages/Search";
import Cart from "./Pages/Cart";
import Profile from "./Pages/Profile";
import Invoices from "./Pages/Profile/Invoices.jsx";
import Invoice from "./Pages/Profile/Invoice.jsx";
import UpdateUserInformation from "./Pages/SubPages/UpdateUserInformation";
import Product from "./Pages/SubPages/Product";
import Images from "./Pages/SubPages/Product/Images.jsx";
import Comments from "./Pages/SubPages/Product/Comments.jsx";
import Address from "./Pages/SubPages/Address";
import AddAddress from "./Pages/SubPages/Address/Add.jsx";
import DateTime from "./Pages/SubPages/Date";
import Payment from "./Pages/SubPages/Peyment";
import SuccessfulTransaction from "./Pages/Transaction/SuccessfulTransaction.jsx";
import FailedTransaction from "./Pages/Transaction/FailedTransaction.jsx";
import ReadyCart from "./Pages/SubPages/ReadyCart";

import QRlogo from "./Images/QRlogo.png";
import "./App.css";

function App() {
  const [installPrompt, setInstallPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  useEffect(() => {
    // تشخیص نصب شدن PWA در آیفون یا اندروید
    setIsInstalled(isInStandaloneMode());
    // بررسی سیستم عامل کاربر (iOS)
    const userAgent = window.navigator.userAgent;
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setIsIOS(true);
    }
    // مدیریت رویداد beforeinstallprompt برای اندروید
    window.addEventListener("beforeinstallprompt", (e) => {
      setInstallPrompt(e);
      e.preventDefault();
    });
    return () => {
      window.removeEventListener("beforeinstallprompt", (e) => {});
    };
  }, []);

  const token = localStorage.getItem("tokenManiarApp");
  const redirectPath = !token || token === "" ? "login" : "app/home";

  return (
    <>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="app/categories/products" element={<Products />} />
        <Route path="app/categories/products/product" element={<Product />} />
        <Route path="app/search/product" element={<Product />} />
        <Route
          path="app/categories/products/product/images"
          element={<Images />}
        />
        <Route
          path="app/categories/products/product/comments"
          element={<Comments />}
        />
        <Route path="app/address" element={<Address />} />
        <Route path="app/address/add" element={<AddAddress />} />
        <Route path="app/date" element={<DateTime />} />
        <Route path="app/payment" element={<Payment />} />
        <Route path="app/profile/invoices" element={<Invoices />} />
        <Route path="app/profile/invoices/invoice" element={<Invoice />} />
        <Route
          path="app/profile/updateUserInformation"
          element={<UpdateUserInformation />}
        />
        <Route path="app/failedTransaction" element={<FailedTransaction />} />
        <Route path="app/cart/readyCart" element={<ReadyCart />} />
        <Route path="app/profile/invoices" element={<Invoices />} />
        <Route
          path="app/successfulTransaction"
          element={<SuccessfulTransaction />}
        />
        <Route path="app" element={<Application />} exact>
          <Route path="home" element={<Home />} />
          <Route path="categories" element={<Categories />} />
          <Route path="search" element={<Search />} />
          <Route path="cart" element={<Cart />} />
          <Route path="profile" element={<Profile />} />
        </Route>

        <Route path="/" element={<Navigate to={redirectPath} />} exact></Route>
        <Route path="*" element={<Navigate to={redirectPath} />} exact></Route>
      </Routes>
      <header className="App-header">
        {!isInstalled && isIOS && (
          <div className="divInstalledPromptIos red">
            <p>
              برای نصب نسخه PWA، دکمه "اشتراک‌گذاری" بزنید
              <br />و "Add to Home Screen" را انتخاب کنید.
            </p>
            <button onClick={() => setIsInstalled(true)}>متوجه شدم</button>
          </div>
        )}

        {installPrompt && (
          <div className="divInstalledPrompt red">
            <p onClick={() => setInstallPrompt(null)}>x</p>
            <p>نصب نسخه pwa نرم افزار</p>
            <button onClick={() => installPrompt.prompt()}>نصب</button>
          </div>
        )}
        <div className="mainQRCode">
          <p>لطفا اسکن کنید و روی موبایل از نرم افزار استفاده کنید.</p>
          <div>
            <img src={QRlogo} alt="Roysa" />
          </div>
        </div>
      </header>
    </>
  );
}

export default App;
