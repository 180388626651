import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";
import {
  Container,
  Img,
  DivShowShoppingCart,
  ContainerShowShoppingCart,
  ButtonNext,
  DivDetailes,
  Detail,
  DivTitle,
} from "./Element";

import successfulTransaction from "../../Images/successfulTransaction.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const customTheme = "red";
const Body = ({ data }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const location = useLocation(); // استفاده از useLocation برای دسترسی به آدرس URL
  const [item, setItem] = useState(null);
  useEffect(() => {
    setItem(data);
  }, [data]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleNext = async () => {
    navigate("/app/profile/invoices");
  };
  return (
    <>
      <Container className={customTheme}>
        <DivTitle type="successfulTransaction" class="status failed">
          <Img class="transactionIcon" src={successfulTransaction} />
          <h1>تراکنش موفق</h1>
        </DivTitle>
        <DivDetailes>
          <Detail type="successfulTransaction">
            <label>کد پیگیری : </label>
            <p>{item?.number}</p>
          </Detail>
          <tr />
          <Detail type="successfulTransaction">
            <label>مبلغ : </label>
            <p>{numberWithCommas(item?.total_amount)}</p>
          </Detail>
          <tr />
          <Detail type="successfulTransaction">
            <label>زمان پرداخت :</label>
            <p>
              <p>{item?.jalali_payment_date}</p>
            </p>
          </Detail>
        </DivDetailes>
      </Container>

      <ContainerShowShoppingCart show>
        <DivShowShoppingCart>
          <ButtonNext type="successfulTransaction" onClick={handleNext}>
            مشاهده فاکتورها
          </ButtonNext>
        </DivShowShoppingCart>
      </ContainerShowShoppingCart>
    </>
  );
};

export default Body;
