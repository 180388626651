import React, { useEffect, useState } from "react";
import Header from "../../Components/Home/Header";
import Body from "../../Components/Home/Body";
import axiosConfig from "../../ApiCall/axiosConfig";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useOutletContext } from "react-router-dom";

const Index = () => {
  const [items, setItems] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(false);
  let { updateBottomNavigation } = useOutletContext();

  useEffect(() => {
    updateBottomNavigation(1);
    async function fetchSliders() {
      setLoading(true);
      const token = localStorage.getItem("tokenManiarApp");
      const params = new URLSearchParams([
        ["per_page", 1000],
        ["page", 1],
      ]);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };
      try {
        const { data } = await axiosConfig.get(
          "/application/slider/list",
          config
        );
        const itemsData = data.data;
        setSliders(itemsData);
      } catch (error) {
        console.log("Error fetching users:", error);
        setLoading(false);
        showError();
      }
    }
    async function fetchProducts() {
      try {
        const token = localStorage.getItem("tokenManiarApp");
        const params = new URLSearchParams([
          ["per_page", 1000],
          ["page", 1],
        ]);
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
          params,
        };
        const { data } = await axiosConfig.get(
          "/application/product/discounts",
          config
        );
        const itemsData = data.data;
        setItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching users:", error);
        setLoading(false);
        showError();
      }
    }
    fetchSliders();
    fetchProducts();
  }, []);
  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };

  return (
    <>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />

      <Header />
      {loading ? (
        <div className="loadingList">
          <div className="spinner"></div>
        </div>
      ) : (
        <Body sliders={sliders} data={items} />
      )}
    </>
  );
};

export default Index;
