import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";
import {
  Container,
  Item,
  Card,
  Title,
  DivItems,
  Img,
  DiscountPrice,
  DivDiscountPrice,
  Price,
  DivImg,
  DivSearch,
  ButtonFilter,
  ImgFilter,
  DivInput,
  Input,
  Btn,
  Icon,
  DivDetails,
  Description,
  IconInput,
  DivDiscount,
  DivQuantity,
  Quantity,
  MeasureUnit,
  DivPrice,
  DivShowShoppingCart,
  ContainerShowShoppingCart,
  ButtonShowShoppingCart,
  SumPrice,
  UnitSumPrice,
} from "./Element";
import card from "../../Images/cards/product.svg";
import filter from "../../Images/productsPage/filter.svg";
import search from "../../Images/productsPage/search.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Body = ({ cart, data, updateData, handleFilter, showErrorQuantity }) => {
  const [items, setItems] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleOperation = async (item, operation, type) => {
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    if (type === "store") {
      if (item?.max_quantity === 0) {
        showErrorQuantity("موجودی بیشتری از کالا وجود ندارد");
      } else {
        try {
          await axiosConfig.post(
            "/shopping_cart/item/store",
            [
              {
                company_id: item?.company_id,
                product_id: item?.id,
                quantity: 1,
              },
            ],
            config
          );
          isOkResponse = true;
        } catch (error) {
          isOkResponse = false;
          console.log("Error response from server:", error);
        }
      }
    } else if (type === "update") {
      let quantity;
      if (operation === "add") {
        quantity = item?.quantity + 1;
      } else {
        quantity = item?.quantity - 1;
      }
      if (quantity > item?.max_quantity) {
        showErrorQuantity("موجودی بیشتری از کالا وجود ندارد");
      } else {
        try {
          await axiosConfig.put(
            "/shopping_cart/item/update/" + item?.itemId,
            {
              quantity: quantity,
            },
            config
          );
          isOkResponse = true;
        } catch (error) {
          isOkResponse = false;
          console.log("Error response from server:", error);
        }
      }
    } else if (type === "delete") {
      try {
        await axiosConfig.delete(
          "/shopping_cart/item/delete/" + item?.itemId,
          config
        );
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
        console.log("Error response from server:", error);
      }
    }

    if (isOkResponse) {
      updateData();
    }
  };
  const handleClick = (item) => {
    navigate("product", { state: item });
  };
  return (
    <>
      <Container>
        <DivSearch>
          <DivInput>
            <IconInput src={search} />
            <Input
              id="username"
              placeholder="جستجوی کنید ..."
              onChange={handleFilter}
            />
          </DivInput>
        </DivSearch>
        <DivItems showShoppingCart={cart?.items?.length !== 0}>
          {items.map((item, index) => (
            <Item to="/product" state={item} key={index}>
              <Card src={card} onClick={() => handleClick(item)} />
              <DivImg onClick={() => handleClick(item)}>
                <Img src={item?.image} />
                {item?.minimum_detail?.confirmed_price !==
                  item?.minimum_detail?.discount_price &&
                  item?.minimum_detail?.discount_type === "percentage" && (
                    <DivDiscount>
                      {item?.minimum_detail?.discount_value + "%"}
                    </DivDiscount>
                  )}
                {item?.minimum_detail?.confirmed_price !==
                  item?.minimum_detail?.discount_price &&
                  item?.minimum_detail?.discount_type === "fixed" && (
                    <DivDiscount>
                      {numberWithCommas(item?.minimum_detail?.discount_value) +
                        "-"}
                    </DivDiscount>
                  )}
              </DivImg>
              <DivDetails onClick={() => handleClick(item)}>
                <Title>{item?.title}</Title>
                <Description>{item?.description}</Description>
                {item?.minimum_detail ? (
                  <DivPrice>
                    {item?.minimum_detail?.confirmed_price !==
                      item?.minimum_detail?.discount_price &&
                      (item?.minimum_detail?.discount_type === "percentage" ||
                        item?.minimum_detail?.discount_type === "fixed") && (
                        <Price>
                          {numberWithCommas(
                            item?.minimum_detail?.confirmed_price
                          )}
                        </Price>
                      )}
                    <DivDiscountPrice>
                      <DiscountPrice>
                        {numberWithCommas(item?.minimum_detail?.discount_price)}
                      </DiscountPrice>
                      تومان
                    </DivDiscountPrice>
                  </DivPrice>
                ) : (
                  <DivDiscountPrice>
                    <DiscountPrice>ناموجود</DiscountPrice>
                  </DivDiscountPrice>
                )}
              </DivDetails>
            </Item>
          ))}
        </DivItems>
        <ContainerShowShoppingCart show={cart?.items?.length !== 0}>
          <DivShowShoppingCart>
            <ButtonShowShoppingCart to="/app/cart">
              مشاهده سبد خرید
            </ButtonShowShoppingCart>
            <SumPrice>
              {numberWithCommas(cart?.sum_price)}
              <UnitSumPrice>تومان</UnitSumPrice>
            </SumPrice>
          </DivShowShoppingCart>
        </ContainerShowShoppingCart>
      </Container>
    </>
  );
};

export default Body;
