import { Container, Logo } from "./HeaderElements";
import logo from "../../Images/logo.svg";
const Header = () => {
  return (
    <>
      <Container>
        <Logo src={logo} />
      </Container>
    </>
  );
};

export default Header;
