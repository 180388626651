import {
  Container,
  DivHeaderBtn,
  DivTitleTopHeader,
  ImgHeaderBtn,
  TitleTopHeader,
  TopHeader,
} from "./HeaderElements";
import back from "../../Images/headerIcons/back.svg";
import add from "../../Images/headerIcons/add.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = ({ data }) => {
  const [item, setItem] = useState(null);

  useEffect(() => {
    setItem(data);
  }, [data]);
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <TopHeader>
          <DivHeaderBtn onClick={() => navigate(-1)}>
            <ImgHeaderBtn src={back} />
          </DivHeaderBtn>
          <DivTitleTopHeader>
            <TitleTopHeader>{item?.title}</TitleTopHeader>
          </DivTitleTopHeader>
          <DivHeaderBtn to="add" hide>
            <ImgHeaderBtn src={add} />
          </DivHeaderBtn>
        </TopHeader>
      </Container>
    </>
  );
};

export default Header;
