import {
  BackgroundHeader,
  Container,
  DivBackgroundHeader,
  DivInput,
  DivSearch,
  IconInput,
  Input,
} from "./HeaderElements";
import header from "../../Images/headers/search.svg";
import search from "../../Images/productsPage/search.svg";

const Header = ({ handleSearch }) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value.replace(/ي/g, "ی").replace(/ك/g, "ک"));
    }
  };

  return (
    <>
      <Container>
        <div>
          <BackgroundHeader src={header} />
          <DivBackgroundHeader>
            <DivSearch>
              <DivInput>
                <IconInput src={search} />
                <Input
                  id="username"
                  type="search"
                  placeholder="جستجوی کنید ..."
                  onKeyDown={handleKeyDown}
                />
              </DivInput>
            </DivSearch>
          </DivBackgroundHeader>
        </div>
      </Container>
    </>
  );
};

export default Header;
