import React, { useEffect, useState } from "react";
import Header from "../../../Components/Address/Header";
import Body from "../../../Components/Address/Body";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { useLocation } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { MainContainer } from "../../../Components/SoftwareMain/MainElements";
const customTheme = "red";

const Index = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sumPrice, setSumPrice] = useState("0");
  const [shoppingCartId, setShoppingCartId] = useState(null);
  let location = useLocation();
  useEffect(() => {
    setSumPrice(location?.state?.sum_price);
    setShoppingCartId(location?.state?.items?.[0]?.shopping_cart_id);
    setLoading(true);
    async function fetchData() {
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        const { data } = await axiosConfig.get(
          "/branch/list/" +
            JSON.parse(localStorage.getItem("userManiarApp"))?.trade_units?.[0]
              ?.id,
          config
        );
        setItems(data.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching products:", error);
        showError();
      }
    }
    fetchData();
  }, [location]);
  const updateData = () => {
    async function fetchData() {
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        const { data } = await axiosConfig.get(
          "/branch/list/" +
            JSON.parse(localStorage.getItem("userManiarApp"))?.trade_units?.[0]
              ?.id,
          config
        );
        setItems(data.data);
        enqueueSnackbar("آدرس با موفقیت حذف شد.", {
          autoHideDuration: 2000,
          style: { backgroundColor: "#478620" },
        });
      } catch (error) {
        console.log("Error fetching products:", error);
        showError();
      }
    }
    fetchData();
  };

  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  const showWarning = (text) => {
    enqueueSnackbar(text, {
      autoHideDuration: 2000,
      style: { backgroundColor: "#DC981B" },
    });
  };

  return (
    <MainContainer subPage className={customTheme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />
      <Header />
      {loading ? (
        <div className="loadingList">
          <div className="spinner"></div>
        </div>
      ) : (
        <Body
          data={items}
          showError={showError}
          showWarning={showWarning}
          sumPrice={sumPrice}
          shopping_cart_id={shoppingCartId}
          updateData={updateData}
        />
      )}
    </MainContainer>
  );
};

export default Index;
