import axiosConfig from "../../ApiCall/axiosConfig";
import {
  BottomHeader,
  Container,
  DivHeaderBtn,
  DivHeaderButton,
  DivTitleTopHeader,
  ImgHeaderBtn,
  InvoiceDetail,
  StatusBackground,
  TitleTopHeader,
  TopHeader,
} from "./InvoicesHeaderElements";
import back from "../../Images/headerIcons/back.svg";
import add from "../../Images/cartPage/productsAddToReadyCart.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ButtonAddRaedyCart,
  CustomSheet,
  DivInput,
  DivRaedyCarts,
  ImgButton,
  ImgReadyCart,
  Input,
  RaedyCart,
} from "./InvoiceElement";
import { Sheet } from "react-modal-sheet";
import bottomSheateReadyCartIcon from "../../Images/cartPage/bottomSheateReadyCart.svg";
import addIcon from "../../Images/add.svg";
import status from "../../Images/status.svg";
const customTheme = "red";

const Header = ({ data, updateData, readyCartData, showError }) => {
  const [item, setItem] = useState(null);
  const [title, setTitle] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [readyCartItems, setReadyCartItems] = useState([]);
  const [showAddReadyCartSheet, setShowAddReadyCartSheet] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setItem(data);
    setReadyCartItems(readyCartData);
  }, [data, readyCartData]);
  const handleChange = (e) => {
    setTitle(e.currentTarget.value);
  };
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleAddInvoiceToNewReadyCart = async () => {
    let itemsArray = [];
    setShowAddReadyCartSheet(false);
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const { data } = await axiosConfig.post(
        "/ready_cart/store",
        { title: title },
        config
      );
      try {
        item?.shopping_cart_items?.forEach((item) => {
          itemsArray.push({
            ready_cart_id: data?.data?.id,
            company_id: item?.company?.id,
            product_id: item?.product?.id,
            quantity: item?.quantity,
          });
        });
        await axiosConfig.post("/ready_cart/item/store", itemsArray, config);
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
      }
    } catch (error) {
      isOkResponse = false;
    }
    if (isOkResponse) {
      setTitle("");
      updateData("addInvoiceToNewReadyCart");
    } else {
      showError();
      setTitle("");
    }
  };
  const handleAddInvoiceToReadyCart = async (readyCart) => {
    let itemsArray = [];
    setOpen(false);
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    item?.shopping_cart_items?.forEach((item) => {
      itemsArray.push({
        ready_cart_id: readyCart?.id,
        company_id: item?.company?.id,
        product_id: item?.product?.id,
        quantity: item?.quantity,
      });
    });
    try {
      await axiosConfig.post("/ready_cart/item/store", itemsArray, config);
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
    }
    if (isOkResponse) {
      updateData("addInvoiceToReadyCart");
    } else {
      showError();
    }
  };
  return (
    <>
      <Container haveBackground>
        <CustomSheet
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          detent="content-height"
          className={customTheme}
        >
          <Sheet.Container>
            <Sheet.Content>
              به کدام سبد آماده اضافه شود؟
              <Sheet.Scroller>
                {readyCartItems.length !== 0 && (
                  <DivRaedyCarts>
                    {readyCartItems.map((item, index) => (
                      <RaedyCart
                        key={index}
                        onClick={() => handleAddInvoiceToReadyCart(item)}
                      >
                        <ImgReadyCart src={bottomSheateReadyCartIcon} />
                        <p>{item?.title}</p>
                      </RaedyCart>
                    ))}
                  </DivRaedyCarts>
                )}
                <ButtonAddRaedyCart
                  emptyReadyCart={readyCartItems.length === 0}
                  onClick={() => {
                    setOpen(false);
                    setShowAddReadyCartSheet(true);
                  }}
                >
                  <ImgButton src={addIcon} />
                  <p>ایجاد سبد آماده جدید</p>
                </ButtonAddRaedyCart>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onClick={() => setOpen(false)} />
        </CustomSheet>
        <CustomSheet
          isOpen={showAddReadyCartSheet}
          onClose={() => setShowAddReadyCartSheet(false)}
          detent="content-height"
          className={customTheme}
        >
          <Sheet.Container>
            <Sheet.Content>
              <Sheet.Scroller>
                <DivInput>
                  <ImgReadyCart src={bottomSheateReadyCartIcon} />
                  <Input
                    id="title"
                    placeholder="نام سبد خرید"
                    onChange={handleChange}
                    value={title}
                  />
                </DivInput>
                <ButtonAddRaedyCart
                  emptyReadyCart
                  onClick={() => {
                    title !== "" && handleAddInvoiceToNewReadyCart();
                  }}
                >
                  ایجاد و افزودن کالا ها به این سبد
                </ButtonAddRaedyCart>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onClick={() => setShowAddReadyCartSheet(false)} />
        </CustomSheet>

        <TopHeader>
          <DivHeaderBtn onClick={() => navigate(-1)}>
            <ImgHeaderBtn src={back} />
          </DivHeaderBtn>
          <DivTitleTopHeader>
            <TitleTopHeader>{"فاکتور " + item?.number}</TitleTopHeader>
          </DivTitleTopHeader>
          <DivHeaderButton
            onClick={() => {
              setOpen(true);
            }}
          >
            <ImgHeaderBtn src={add} />
          </DivHeaderButton>
        </TopHeader>
        <BottomHeader>
          <InvoiceDetail>
            <label>وضعیت فاکتور :</label>
            {item?.status ? (
              <div>
                <StatusBackground color={item?.status?.color}>
                  <img src={status} alt="status" />
                </StatusBackground>
                <p>{item?.status?.title}</p>
              </div>
            ) : (
              <div>
                <StatusBackground color={"#B4C7C6"}>
                  <img src={status} alt="status" />
                </StatusBackground>
                <p>تعیین نشده</p>
              </div>
            )}
          </InvoiceDetail>
          <tr />
          <InvoiceDetail>
            <label>تاریخ تحویل :</label>
            <p>{item?.jalali_delivery_date}</p>
          </InvoiceDetail>
          <tr />
          <InvoiceDetail>
            <label>زمان تحویل :</label>
            <p>{item?.delivery_time?.title}</p>
          </InvoiceDetail>
          <tr />
          <InvoiceDetail>
            <label>
              {item?.payment_method === "ondoor"
                ? "مبلغ قابل پرداخت :"
                : "مبلغ پرداخت شده :"}
            </label>
            <p>{numberWithCommas(item?.total_amount) + " تومان"}</p>
          </InvoiceDetail>
          <tr />
          <InvoiceDetail>
            <label>شماره تحویل گیرنده :</label>
            <p>{item?.recipient_phone}</p>
          </InvoiceDetail>
          <tr />
          <InvoiceDetail>
            <label>نام تحویل گیرنده :</label>
            <p>{item?.recipient_full_name}</p>
          </InvoiceDetail>
          <tr />
          <InvoiceDetail>
            <label>محل تحویل :</label>
            <p>{item?.branch?.title}</p>
          </InvoiceDetail>
        </BottomHeader>
      </Container>
    </>
  );
};

export default Header;
