import styled from "styled-components";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

export const Container = styled.div`
  /* margin-top: 138px; */
  height: calc(100vh - 76px);
  @supports (height: calc(100svh - 76px)) {
    height: calc(100svh - 76px);
  }

  background: var(--background);
  width: 100%;
  display: flex;
  align-content: flex-start;
  gap: 23px;
  flex-wrap: wrap;
  overflow-x: hidden;
  padding-top: 29px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const TextNoItems = styled.div`
  /* margin-top: 138px; */
  height: calc(90vh - 205px);
  @supports (height: calc(90svh - 205px)) {
    height: calc(90svh - 205px);
  }
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 11px;
    font-weight: 600;
  }
`;

export const DivInput = styled.div`
  position: relative;
  height: 111px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const Input = styled.textarea`
  padding-inline-start: 50px;
  padding-inline-end: 14px;
  padding-block: 14px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 17px;
  border: 1px solid var(--searchStrokeColor);
  margin: 0;
  outline: none;
  resize: none;

  &::placeholder {
    color: var(--placeholder);
    opacity: 1; /* Firefox */
  }
  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--placeholder);
  }
`;
export const IconInput = styled.img`
  position: absolute;
  top: 0;
  height: 51px;
  width: 51px;
  & path {
    stroke: var(--firstColor);
  }
  & circle {
    stroke: var(--firstColor);
    fill: var(--firstColor);
  }
`;
export const DivItems = styled.div`
  width: 85vw;
  margin: auto;
  display: flex;
  align-content: flex-start;
  row-gap: 14px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 230px;
`;
export const Item = styled.div`
  font-size: 13px;
  width: 100%;
  border-bottom: 1px dashed var(--gray);
`;
export const DivUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const DivAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const DivImage = styled.div`
  max-width: 35px;
  min-width: 35px;
  max-height: 35px;
  min-height: 35px;
  border-radius: 8px;
  background: var(--firstColor);
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;
export const DivName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
`;
export const CreatedAt = styled.p`
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  color: var(--lable);
`;
export const DivStar = styled.div`
  width: 52px;
  height: 34px;
  background: var(--startBackgroundCommentsPage);
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  gap: 3px;
  img {
    margin-top: -2px;
  }
`;
export const BodyComment = styled.p`
  text-align: justify;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  margin-block: 10px;
  white-space: pre-line;
`;
