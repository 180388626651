import { Link } from "react-router-dom";
import styled from "styled-components";
export const Container = styled.div`
  height: calc(100vh - 100px);
  @supports (height: calc(100svh - 100px)) {
    height: calc(100svh - 100px);
  }
  width: 100%;
  display: flex;
  align-content: flex-start;
  gap: 23px;
  flex-wrap: wrap;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const DivSlider = styled.div`
  width: 86vw;
  height: 165px;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
`;
export const DivTitle = styled.div`
  font-size: 13px;
  font-weight: 900;
  width: 78vw;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  white-space: nowrap;
`;
export const DashedLine = styled.hr`
  border: none;
  border-top: 1px dashed #c6c6c6;
  width: 100%;
`;
export const Slider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 165px;
`;
export const DivItems = styled.div`
  width: 76vw;
  margin: auto;
  display: flex;
  align-content: flex-start;
  row-gap: 26px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 27vw;
`;
export const Item = styled(Link)`
  font-size: 13px;
  width: 46%;
  position: relative;
  padding-top: 44px;
  color: black;
  -webkit-tap-highlight-color: transparent; // حذف هایلایت آبی در دستگاه‌های لمسی
  transition: all 0.2s ease-in-out;
  &:active {
    filter: brightness(0.98);
  }
`;
export const Card = styled.img`
  height: 100%;
  width: 100%;
`;
export const DivImg = styled.div`
  position: absolute;
  right: calc(50% - 41.5px);
  top: 0;
  height: 83px;
  width: 83px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Img = styled.img`
  min-height: 83px;
  width: 83px;
  border-radius: 13px;
  position: relative;
`;
export const DiscountValue = styled.div`
  height: 23px;
  width: 100%;
  position: absolute;
  right: 0;
  top: -11.5px;
  display: flex;
  justify-content: center;
`;
export const Value = styled.div`
  height: 100%;
  background: var(--secondColor);
  padding-inline: 4px;
  border-radius: 7px;
  color: white;
  font-size: 10px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DetailItem = styled.div`
  position: absolute;
  inset-inline: 0.3vw;
  top: 58%;
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  align-items: center;
`;
export const Title = styled.p`
  font-size: 13px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Price = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: var(--placeholder);
  text-decoration-line: line-through;
  margin-bottom: -10px;
`;
export const DivDiscountPrice = styled.p`
  font-size: 11px;
  font-weight: 700;
  text-align: center;
`;
export const DiscountPrice = styled.span`
  font-size: 14px;
  font-weight: 900;
  color: var(--firstColor);
  margin-inline-end: 4px;
`;
export const Btn = styled.button`
  position: absolute;
  inset-inline-end: 0.7vw;
  bottom: 1.2vw;
  height: 2.7vw;
  width: 2.7vw;
  border-radius: 1vw;
  border: none;
  margin-right: auto;
  background: var(--orange);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: var(--lightBrown);
  }
`;
export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;
