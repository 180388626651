import {
  Container,
  Item,
  Title,
  DivItems,
  Img,
  DivImg,
  DivItem,
  DivSubItems,
  SubItem,
} from "./Element";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

const Body = ({
  cart,
  data,
  updateData,
  selectCategory,
  showCategories,
  showErrorQuantity,
}) => {
  const [items, setItems] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  // const { t } = useTranslation();
  // const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      setItems(data);
    }
    if (showCategories) {
      setActiveCategory(null);
    }
  }, [data, showCategories]);
  // const numberWithCommas = (x) => {
  //   return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // };
  // const handleOperation = async (item, operation, type) => {
  //   let isOkResponse;
  //   const token = localStorage.getItem("tokenManiarApp");
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //   };
  //   if (type === "store") {
  //     if (item?.max_quantity === 0) {
  //       showErrorQuantity("موجودی بیشتری از کالا وجود ندارد");
  //     } else {
  //       try {
  //         await axiosConfig.post(
  //           "/shopping_cart/item/store",
  //           [
  //             {
  //               company_id: item?.company_id,
  //               product_id: item?.id,
  //               quantity: 1,
  //             },
  //           ],
  //           config
  //         );
  //         isOkResponse = true;
  //       } catch (error) {
  //         isOkResponse = false;
  //         console.log("Error response from server:", error);
  //       }
  //     }
  //   } else if (type === "update") {
  //     let quantity;
  //     if (operation === "add") {
  //       quantity = item?.quantity + 1;
  //     } else {
  //       quantity = item?.quantity - 1;
  //     }
  //     if (quantity > item?.max_quantity) {
  //       showErrorQuantity("موجودی بیشتری از کالا وجود ندارد");
  //     } else {
  //       try {
  //         await axiosConfig.put(
  //           "/shopping_cart/item/update/" + item?.itemId,
  //           {
  //             quantity: quantity,
  //           },
  //           config
  //         );
  //         isOkResponse = true;
  //       } catch (error) {
  //         isOkResponse = false;
  //         console.log("Error response from server:", error);
  //       }
  //     }
  //   } else if (type === "delete") {
  //     try {
  //       await axiosConfig.delete(
  //         "/shopping_cart/item/delete/" + item?.itemId,
  //         config
  //       );
  //       isOkResponse = true;
  //     } catch (error) {
  //       isOkResponse = false;
  //       console.log("Error response from server:", error);
  //     }
  //   }

  //   if (isOkResponse) {
  //     updateData();
  //   }
  // };
  const handleSelectCategory = (item) => {
    setActiveCategory(item);
    selectCategory("selectCategory");
  };
  return (
    <>
      <Container>
        <DivItems showShoppingCart={false} selectedCategory={activeCategory}>
          {items.map((item, index) => (
            <DivItem key={index} active={activeCategory?.id === item?.id}>
              <Item
                state={item}
                onClick={() => handleSelectCategory(item)}
                active={activeCategory?.id === item?.id}
                nonActive={activeCategory === null}
              >
                <DivImg>
                  <Img src={item?.image} />
                </DivImg>
                <Title>{item?.title}</Title>
              </Item>
              <DivSubItems
                active={activeCategory?.id === item?.id}
                height={item?.childs?.length * 55}
              >
                {item?.childs.map((subItem, index) => (
                  <SubItem to="products" state={subItem}>
                    <div>{subItem?.title}</div>
                  </SubItem>
                ))}
              </DivSubItems>
            </DivItem>
          ))}
        </DivItems>
        {/* <ContainerShowShoppingCart show={cart?.items?.length !== 0}>
          <DivShowShoppingCart>
            <ButtonShowShoppingCart to="/app/cart">
              مشاهده سبد خرید
            </ButtonShowShoppingCart>
            <SumPrice>
              {numberWithCommas(cart?.sum_price)}
              <UnitSumPrice>تومان</UnitSumPrice>
            </SumPrice>
          </DivShowShoppingCart>
        </ContainerShowShoppingCart> */}
      </Container>
    </>
  );
};

export default Body;
