import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";
import {
  Container,
  Img,
  DivShowShoppingCart,
  ContainerShowShoppingCart,
  ButtonNext,
  DivDetailes,
  Detail,
  DivTitle,
} from "./Element";

import failedTransaction from "../../Images/failedTransaction.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const customTheme = "red";
const Body = ({ data }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const location = useLocation(); // استفاده از useLocation برای دسترسی به آدرس URL
  const [item, setItem] = useState(null);
  useEffect(() => {
    setItem(data);
  }, [data]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleNext = async () => {
    navigate("/app/home");
  };
  return (
    <>
      <Container className={customTheme}>
        <DivTitle class="status failed">
          <Img class="transactionIcon" src={failedTransaction} />
          <h1>تراکنش ناموفق</h1>
        </DivTitle>
        <DivDetailes>
          <Detail>
            <label>کد پیگیری : </label>
            <p>{item?.number}</p>
          </Detail>
          <tr />
          <Detail>
            <label>مبلغ : </label>
            <p>{numberWithCommas(item?.total_amount)}</p>
          </Detail>
          <tr />
          <Detail>
            <label>زمان پرداخت :</label>
            <p>{item?.jalali_payment_date}</p>
          </Detail>
        </DivDetailes>
      </Container>

      <ContainerShowShoppingCart show>
        <DivShowShoppingCart>
          <ButtonNext onClick={handleNext}>بازگشت</ButtonNext>
        </DivShowShoppingCart>
      </ContainerShowShoppingCart>
    </>
  );
};

export default Body;
