import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";

import {
  Container,
  Item,
  Card,
  Title,
  DivItems,
  Img,
  DiscountPrice,
  DivDiscountPrice,
  Price,
  DivImg,
  Btn,
  Icon,
  DivDetails,
  Description,
  DivDiscount,
  DivQuantity,
  Quantity,
  MeasureUnit,
  DivPrice,
  DivShowShoppingCart,
  ContainerShowShoppingCart,
  ButtonShowShoppingCart,
  SumPrice,
  UnitSumPrice,
  Company,
  CompanyDefualtIcon,
  TextNoItems,
  DivTitle,
  DivMoreDetails,
  DivImages,
  DivButtons,
  Button,
  ImgButton,
  DefualtImg,
  DivStar,
  Image,
  DescriptionProduct,
  AddToCartButton,
  Input,
  Loader,
} from "./Element";
import addIcon from "../../Images/add.svg";
import minusIcon from "../../Images/minus.svg";
import deleteIcon from "../../Images/delete.svg";
import imagesIcon from "../../Images/productPage/images.svg";
import commentsIcon from "../../Images/productPage/coments.svg";
import defualtLogo from "../../Images/defaultImage/deliveryCompany.svg";
import star from "../../Images/star.svg";
import { useEffect, useState } from "react";
const customTheme = "red";
const Body = ({
  data,
  cart,
  updateData,
  detailsProduct,
  loadingId,
  showError,
  showWarning,
}) => {
  const [items, setItems] = useState([]);
  const [product, setProduct] = useState(null);
  const [cartItems, setCart] = useState([]);
  const [loading, setLoading] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    const updatedData = data.map((item) => ({
      ...item,
      newQuantity: item?.quantity, // مقدار اولیه برای newQuantity
    }));
    setItems(updatedData);
    setProduct(detailsProduct);
    setCart(cart);
    setLoading((prevLoading) => ({ ...prevLoading, [loadingId]: false }));
  }, [data, cart, detailsProduct, loadingId]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleIncrease = (id) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, newQuantity: item.newQuantity + 1 } : item
      )
    );
  };

  const handleDecrease = (id) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, newQuantity: Math.max(item.newQuantity - 1, 0) }
          : item
      )
    );
  };

  const replacePersianNumbersWithEnglish = (text) => {
    if (!text) return "";
    return text.replace(/[۰-۹]/g, (char) =>
      String.fromCharCode(char.charCodeAt(0) - 1728)
    );
  };

  const handleChange = (id, value, maxQuantity) => {
    let inputValue = replacePersianNumbersWithEnglish(value);
    inputValue = parseInt(inputValue, 10);
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id && !isNaN(inputValue) && inputValue >= 0
          ? { ...item, newQuantity: inputValue }
          : { ...item, newQuantity: 0 }
      )
    );
  };
  const handleOperation = async (item) => {
    if (item?.quantity !== item?.newQuantity) {
      setLoading((prevLoading) => ({ ...prevLoading, [item?.id]: true }));
      if (item?.newQuantity > item?.max_quantity) {
        showWarning(
          <p
            dangerouslySetInnerHTML={{
              __html: `درخواست شما بیشتراز موجودی محصول می باشد.<br/> موجودی : ${item?.max_quantity} ${item?.measure_unit}`,
            }}
          />
        );
        setLoading((prevLoading) => ({ ...prevLoading, [item?.id]: false }));
      } else {
        let isOkResponse;
        const token = localStorage.getItem("tokenManiarApp");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        if (item?.quantity === 0) {
          try {
            await axiosConfig.post(
              "/shopping_cart/item/store",
              [
                {
                  company_id: item?.id,
                  product_id: item?.detail?.product_id,
                  quantity: item?.newQuantity,
                },
              ],
              config
            );
            isOkResponse = true;
          } catch (error) {
            isOkResponse = false;
            console.log("Error response from server:", error);
          }
        } else if (
          item?.quantity !== item?.newQuantity &&
          item?.newQuantity !== 0
        ) {
          try {
            await axiosConfig.put(
              "/shopping_cart/item/update/" + item?.itemId,
              {
                quantity: item?.newQuantity,
              },
              config
            );
            isOkResponse = true;
          } catch (error) {
            isOkResponse = false;
            console.log("Error response from server:", error);
          }
        } else if (item?.newQuantity === 0) {
          try {
            await axiosConfig.delete(
              "/shopping_cart/item/delete/" + item?.itemId,
              config
            );
            isOkResponse = true;
          } catch (error) {
            isOkResponse = false;
            console.log("Error response from server:", error);
          }
        }
        if (isOkResponse) {
          updateData(item?.id);
        } else {
          showError();
          setLoading((prevLoading) => ({ ...prevLoading, [item?.id]: false }));
        }
      }
    }
  };
  return (
    <>
      {items?.length === 0 ? (
        <TextNoItems>
          <p>هیچ شرکت پخشی این محصول را ندارد</p>
        </TextNoItems>
      ) : (
        <Container className={customTheme}>
          <DivItems showShoppingCart={items?.length !== 0}>
            <Image src={product?.image} />
            {product?.description && (
              <DescriptionProduct>
                {"توضیحات : " + product?.description}
              </DescriptionProduct>
            )}
            {items?.map((item, index) => (
              <Item key={index}>
                <DivStar>
                  <img src={star} alt="star" />
                  {item?.rate ? item?.rate : "_"}
                </DivStar>
                <DivDetails>
                  <DivTitle>
                    <DivImg>
                      {item?.logo ? (
                        <Img src={item?.logo} />
                      ) : (
                        <DefualtImg src={defualtLogo} />
                      )}
                    </DivImg>
                    <Title>{item?.title}</Title>
                  </DivTitle>
                  {item?.detail?.description && (
                    <Description>{item?.detail?.description}</Description>
                  )}
                  <DivMoreDetails>
                    {item?.images?.length !== 0 && (
                      <DivImages>
                        {item?.images.map((image, index) => {
                          if (index >= 5) return null;
                          return (
                            <img
                              src={image?.image}
                              alt="companyLogo"
                              key={index}
                            />
                          );
                        })}
                      </DivImages>
                    )}

                    <DivButtons>
                      {item?.images?.length !== 0 && (
                        <Button
                          title="images"
                          to="images"
                          state={{
                            images: item?.images,
                            title: product?.title,
                            description: item?.detail?.description,
                            companyName: item?.title,
                            companyLogo: item?.logo,
                          }}
                        >
                          <ImgButton src={imagesIcon} />
                          مشاهده تصاویر
                        </Button>
                      )}

                      <Button
                        title="comments"
                        to="comments"
                        state={{
                          title: product?.title,
                          companyName: item?.title,
                          companyLogo: item?.logo,
                          company_id: item?.id,
                          product_id: item?.detail?.product_id,
                        }}
                      >
                        <ImgButton src={commentsIcon} />
                        مشاهده نظرات
                      </Button>
                    </DivButtons>
                  </DivMoreDetails>
                  {item?.detail ? (
                    <DivDiscountPrice>
                      <DiscountPrice>
                        <p>{numberWithCommas(item?.detail?.discount_price)}</p>
                        <span>{"تومان / " + item?.measure_unit}</span>
                      </DiscountPrice>
                      {item?.detail?.confirmed_price !==
                        item?.detail?.discount_price &&
                        (item?.detail?.discount_type === "percentage" ||
                          item?.detail?.discount_type === "fixed") && (
                          <div>
                            <Price>
                              {numberWithCommas(item?.detail?.confirmed_price)}
                            </Price>
                            {item?.detail?.confirmed_price !==
                              item?.detail?.discount_price &&
                              item?.detail?.discount_type === "percentage" && (
                                <DivDiscount>
                                  {item?.detail?.discount_value + "%"}
                                </DivDiscount>
                              )}
                            {item?.detail?.confirmed_price !==
                              item?.detail?.discount_price &&
                              item?.detail?.discount_type === "fixed" && (
                                <DivDiscount>
                                  {numberWithCommas(
                                    item?.detail?.discount_value
                                  ) + "-"}
                                </DivDiscount>
                              )}
                          </div>
                        )}
                    </DivDiscountPrice>
                  ) : (
                    <DivDiscountPrice>
                      <DiscountPrice>ناموجود</DiscountPrice>
                    </DivDiscountPrice>
                  )}
                  <DivButtons>
                    <DivQuantity>
                      <Btn
                        direction="right"
                        onClick={() => handleIncrease(item?.id)}
                      >
                        <Icon src={addIcon} />
                      </Btn>
                      <Input
                        inputMode="numeric"
                        max={item?.max_quantity}
                        value={item?.newQuantity}
                        onChange={(e) =>
                          handleChange(
                            item?.id,
                            e.target.value,
                            item?.max_quantity
                          )
                        }
                      />
                      <Btn
                        direction="left"
                        onClick={() => handleDecrease(item?.id)}
                      >
                        <Icon src={minusIcon} />
                      </Btn>
                    </DivQuantity>
                    <AddToCartButton
                      disabled={loading[item.id]}
                      disabledBtn={loading[item.id]}
                      onClick={() => handleOperation(item)}
                    >
                      {loading[item.id] ? (
                        <Loader>
                          <div></div>
                          <div></div>
                          <div></div>
                        </Loader>
                      ) : item?.quantity !== 0 && item?.newQuantity === 0 ? (
                        "حذف از سبد خرید"
                      ) : (
                        "افزودن به سبد خرید"
                      )}
                    </AddToCartButton>
                  </DivButtons>

                  {/* {item?.quantity === 0 ? (
                    <Btn onClick={() => handleOperation(item, "add", "store")}>
                      <Icon src={addIcon} />
                    </Btn>
                  ) : (
                    <>
                      <Btn
                        type="add"
                        onClick={() => handleOperation(item, "add", "update")}
                      >
                        <Icon src={addIcon} />
                      </Btn>
                      <DivQuantity>
                        <Quantity>{item?.quantity}</Quantity>
                        <MeasureUnit>{item?.measure_unit}</MeasureUnit>
                      </DivQuantity>
                      {item?.quantity !== 1 ? (
                        <Btn
                          onClick={() =>
                            handleOperation(item, "minus", "update")
                          }
                        >
                          <Icon type="mines" src={minusIcon} />
                        </Btn>
                      ) : (
                        <Btn
                          onClick={() =>
                            handleOperation(item, "delete", "delete")
                          }
                        >
                          <Icon type="delete" src={deleteIcon} />
                        </Btn>
                      )}
                    </>
                  )} */}
                </DivDetails>
              </Item>
            ))}
          </DivItems>
          <ContainerShowShoppingCart show={cartItems?.items?.length !== 0}>
            <DivShowShoppingCart>
              <ButtonShowShoppingCart to="/app/cart">
                مشاهده سبد خرید
              </ButtonShowShoppingCart>
              <SumPrice>
                {numberWithCommas(cartItems?.sum_price)}
                <UnitSumPrice>تومان</UnitSumPrice>
              </SumPrice>
            </DivShowShoppingCart>
          </ContainerShowShoppingCart>
        </Container>
      )}
    </>
  );
};

export default Body;
