import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";
import {
  Container,
  Item,
  Card,
  Title,
  DivItems,
  Img,
  DiscountPrice,
  DivDiscountPrice,
  Price,
  DivImg,
  DivSearch,
  ButtonFilter,
  ImgFilter,
  DivInput,
  Input,
  Btn,
  Icon,
  DivDetails,
  Description,
  IconInput,
  DivDiscount,
  DivQuantity,
  Quantity,
  MeasureUnit,
  DivPrice,
  DivShowShoppingCart,
  ContainerShowShoppingCart,
  ButtonShowShoppingCart,
  SumPrice,
  UnitSumPrice,
} from "./Element";
import card from "../../Images/cards/product.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Body = ({ data }) => {
  const [items, setItems] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleClick = (item) => {
    navigate("product", { state: item });
  };
  return (
    <>
      <Container>
        <DivItems>
          {items.map((item, index) => (
            <Item to="/product" state={item} key={index}>
              <Card src={card} onClick={() => handleClick(item)} />
              <DivImg onClick={() => handleClick(item)}>
                <Img src={item?.image} />
                {item?.minimum_detail?.confirmed_price !==
                  item?.minimum_detail?.discount_price &&
                  item?.minimum_detail?.discount_type === "percentage" && (
                    <DivDiscount>
                      {item?.minimum_detail?.discount_value + "%"}
                    </DivDiscount>
                  )}
                {item?.minimum_detail?.confirmed_price !==
                  item?.minimum_detail?.discount_price &&
                  item?.minimum_detail?.discount_type === "fixed" && (
                    <DivDiscount>
                      {numberWithCommas(item?.minimum_detail?.discount_value) +
                        "-"}
                    </DivDiscount>
                  )}
              </DivImg>
              <DivDetails onClick={() => handleClick(item)}>
                <Title>{item?.title}</Title>
                <Description>{item?.description}</Description>
                {item?.minimum_detail ? (
                  <DivPrice>
                    {item?.minimum_detail?.confirmed_price !==
                      item?.minimum_detail?.discount_price &&
                      (item?.minimum_detail?.discount_type === "percentage" ||
                        item?.minimum_detail?.discount_type === "fixed") && (
                        <Price>
                          {numberWithCommas(
                            item?.minimum_detail?.confirmed_price
                          )}
                        </Price>
                      )}
                    <DivDiscountPrice>
                      <DiscountPrice>
                        {numberWithCommas(item?.minimum_detail?.discount_price)}
                      </DiscountPrice>
                      تومان
                    </DivDiscountPrice>
                  </DivPrice>
                ) : (
                  <DivDiscountPrice>
                    <DiscountPrice>ناموجود</DiscountPrice>
                  </DivDiscountPrice>
                )}
              </DivDetails>
            </Item>
          ))}
        </DivItems>
      </Container>
    </>
  );
};

export default Body;
