import {
  Container,
  Item,
  DivTitle,
  DivItems,
  Img,
  Line,
  Description,
} from "./Element";
import editData from "../../Images/profilePage/editData.svg";
import cart from "../../Images/profilePage/cart.svg";
import logOut from "../../Images/profilePage/logOut.svg";
import { useNavigate } from "react-router-dom";
const Body = ({ user }) => {
  const navigate = useNavigate();

  const handleRedirect = (page) => {
    if (page === "invoicesPage") {
      navigate("invoices");
    } else {
      navigate("updateUserInformation", { state: user });
    }
  };
  const handleLogOut = () => {
    localStorage.setItem("tokenManiarApp", "");
    localStorage.setItem("userManiarApp", "");
    navigate("/login");
  };
  return (
    <>
      <Container>
        <DivItems>
          <Item onClick={() => handleRedirect("updateUserInformation")}>
            <Img src={editData} />
            <Line />
            <DivTitle>
              ویرایش اطلاعات فردی
              <Description>نام و نام خانوادگی</Description>
            </DivTitle>
          </Item>
          <Item onClick={() => handleRedirect("invoicesPage")}>
            <Img src={cart} />
            <Line />
            <DivTitle>
              سفارش ها
              <Description>مشاهده لیست</Description>
            </DivTitle>
          </Item>
          <Item onClick={handleLogOut}>
            <Img src={logOut} />
            <Line />
            <DivTitle>خروج </DivTitle>
          </Item>
        </DivItems>
      </Container>
    </>
  );
};

export default Body;
