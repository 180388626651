import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosConfig from "../ApiCall/axiosConfig";

// import i18n from "i18next";
import cookies from "js-cookie";
import BottomNavigation from "../Components/BottomNavigation";
import SoftwareMain from "../Components/SoftwareMain";
const languages = [
  { code: "en", name: "English", dir: "ltr" },
  { code: "fa", name: "فارسی" },
];

const Home = () => {
  const currentLanguageCode = cookies.get("i18next") || "fa";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [countCartItems, setCountCartItems] = useState(
    localStorage.getItem("countCartItems")
  );
  const [activeBottomNavigation, setActiveBottomNavigation] = useState(1);

  const { t } = useTranslation();
  useEffect(() => {
    setCountCartItems(localStorage.getItem("countCartItems"));
    document.body.dir = currentLanguage.dir || "rtl";
    document.title = t("app_title");
  }, [currentLanguage, t]);
  const updateBottomNavigation = (menu) => {
    setActiveBottomNavigation(menu);
  };
  const updateCountCart = (count) => {
    setCountCartItems(count);
  };

  return (
    <>
      <SoftwareMain
        updateCountCart={updateCountCart}
        updateBottomNavigation={updateBottomNavigation}
      />
      <BottomNavigation
        countCartItems={countCartItems}
        activeBottomNavigation={activeBottomNavigation}
        updateBottomNavigation={updateBottomNavigation}
      />
    </>
  );
};

export default Home;
