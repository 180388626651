import axiosConfig from "../../ApiCall/axiosConfig";
import {
  Container,
  Item,
  DivItems,
  TextNoItems,
  DivImage,
  DivUser,
  Name,
  CreatedAt,
  DivName,
  DivStar,
  DivAvatar,
  BodyComment,
  DivInput,
  Input,
  IconInput,
} from "./CommentsElement";
import star from "../../Images/star.svg";
import comment from "../../Images/commentsPage/comment.svg";
import ReactStars from "react-rating-stars-component";
import { useEffect, useState } from "react";
import defaultAvatar from "../../Images/defaultImage/avatar.svg";
import {
  ButtonInsertComment,
  ContainerTextBoxComment,
  DivInputAndBtn,
  DivRate,
} from "./Element";
const customTheme = "red";
const Body = ({
  data,
  company_id,
  product_id,
  updateData,
  showError,
  showWarning,
}) => {
  const [items, setItems] = useState([]);
  const [body, setBody] = useState("");
  const [rate, setRate] = useState(3);

  useEffect(() => {
    setItems(data);
  }, [data]);
  const handleChange = (e) => {
    setBody(e.currentTarget.value);
  };
  const ratingChanged = (newRating) => {
    setRate(newRating);
  };
  const handleAddComment = async () => {
    if (body === "") {
      showWarning("لطفا متن نظر خود را وارد نمایید.");
    } else {
      let isOkResponse;
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        await axiosConfig.post(
          "/application/comment/store",
          {
            company_id: company_id,
            product_id: product_id,
            rate: rate,
            body: body,
            status: 1,
          },
          config
        );
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
      }
      if (isOkResponse) {
        updateData();
        setBody("");
      } else {
        showError();
      }
    }
  };

  return (
    <>
      <Container className={customTheme}>
        {items?.length === 0 ? (
          <TextNoItems>
            <p>هیچ نظری برای این محصول ثبت نشده است</p>
          </TextNoItems>
        ) : (
          <DivItems showShoppingCart={items?.length !== 0}>
            {items?.map((item, index) => (
              <Item>
                <DivUser>
                  <DivAvatar>
                    <DivImage>
                      <img
                        src={
                          item?.user?.avatar
                            ? item?.user?.avatar
                            : defaultAvatar
                        }
                        alt="avatar"
                      />
                    </DivImage>
                    <DivName>
                      <Name>
                        {item?.user?.full_name !== " "
                          ? item?.user?.full_name
                          : "بدون نام"}
                      </Name>
                      <CreatedAt>{item?.jalali_created_at}</CreatedAt>
                    </DivName>
                  </DivAvatar>
                  <DivStar>
                    <img src={star} alt="star" />
                    {item?.rate ? item?.rate : "_"}
                  </DivStar>
                </DivUser>
                <BodyComment>{item?.body}</BodyComment>
              </Item>
            ))}
          </DivItems>
        )}

        <ContainerTextBoxComment show>
          <DivRate>
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={21}
              isHalf={false}
              value={rate}
              activeColor="var(--firstColor)"
              color="#D9D9D9"
            />
          </DivRate>
          <DivInputAndBtn>
            <DivInput>
              <IconInput src={comment} />
              <Input
                placeholder="نظر شما ..."
                id="body"
                value={body}
                onChange={handleChange}
              />
            </DivInput>
            <ButtonInsertComment onClick={handleAddComment}>
              ثبت نظر
            </ButtonInsertComment>
          </DivInputAndBtn>
        </ContainerTextBoxComment>
      </Container>
    </>
  );
};

export default Body;
