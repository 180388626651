import React, { useEffect, useState } from "react";
import Header from "../../../Components/ReadyCart/Header";
import Body from "../../../Components/ReadyCart/Body";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { useLocation } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { MainContainer } from "../../../Components/SoftwareMain/MainElements";
const customTheme = "red";

const Index = () => {
  const [item, setItem] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  let location = useLocation();
  useEffect(() => {
    setLoading(true);
    setItem(location?.state);
    async function fetchData() {
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        const { data } = await axiosConfig.get(
          "/ready_cart/item/list/" + location?.state?.id,
          config
        );
        setItems(data.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching products:", error);
        showError();
      }
    }
    fetchData();
  }, [location]);
  const updateData = (type) => {
    if (type === "addShoppingCartToReadyCart") {
      enqueueSnackbar("سبد خرید با موفقیت به سبد آماده اضافه شد.", {
        autoHideDuration: 2000,
        style: { backgroundColor: "#478620" },
      });
    } else if ("addProductToReadyCart") {
      enqueueSnackbar("محصول با موفقیت به سبد آماده اضافه شد.", {
        autoHideDuration: 2000,
        style: { backgroundColor: "#478620" },
      });
    } else {
      async function fetchReadyCart() {
        const token = localStorage.getItem("tokenManiarApp");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        try {
          const { data } = await axiosConfig.get(
            "/ready_cart/item/list/" + item?.id,
            config
          );
          setItems(data.data);

          enqueueSnackbar("سبد آماده با موفقیت به سبد خرید اضافه شد.", {
            autoHideDuration: 2000,
            style: { backgroundColor: "#478620" },
          });
        } catch (error) {
          console.log("Error fetching products:", error);
          showError();
        }
      }
      fetchReadyCart();
    }
  };
  const updateItems = () => {
    async function fetchData() {
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        const { data } = await axiosConfig.get(
          "/ready_cart/item/list/" + item?.id,
          config
        );
        setItems(data.data);
      } catch (error) {
        console.log("Error fetching products:", error);
        showError();
      }
    }
    fetchData();
  };

  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  const showWarning = (text) => {
    enqueueSnackbar(text, {
      autoHideDuration: 2000,
      style: { backgroundColor: "#DC981B" },
    });
  };

  return (
    <MainContainer subPage className={customTheme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />
      <Header data={item} />
      {loading ? (
        <div className="loadingList">
          <div className="spinner"></div>
        </div>
      ) : (
        <Body
          data={items}
          detailReadyCart={item}
          showError={showError}
          showWarning={showWarning}
          updateData={updateData}
          updateItems={updateItems}
        />
      )}
    </MainContainer>
  );
};

export default Index;
