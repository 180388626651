import styled from "styled-components";
import SVG from "react-inlinesvg";

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 37.2vw;
  text-align: center;
  z-index: 10;
  div {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;
export const DivBackgroundHeader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  padding-bottom: 17vw;
`;
export const BackgroundHeader = styled.img`
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
`;
export const DivImgProfile = styled.div`
  border: 2px #a91010;
  background: linear-gradient(174deg, #9f1d14 16.23%, #b72018 52.63%);
  height: 50%;
  width: 30%;
  border-radius: 50%;
`;
export const ImgProfile = styled(SVG)`
  width: 100%;
  height: 100%;
`;
export const DivProfileDetail = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  color: black;
  text-align: right;
  margin-inline-start: 8%;
  margin-top: 3%;
`;
export const Role = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  color: var(--roleColor);
  text-align: right;
`;
export const DivSearch = styled.div`
  max-width: 85vw;
  max-height: 51px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  white-space: nowrap;
`;
export const DivInput = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;
export const Input = styled.input`
  padding-inline-start: 50px;
  padding-inline-end: 14px;
  font-size: 12px;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 1.6vh;
  border: 1px solid var(--StrokeInputColorSearchPage);
  margin: 0;
  outline: none;
  &::placeholder {
    color: var(--placeholder);
    opacity: 1; /* Firefox */
  }
  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--placeholder);
  }
`;
export const IconInput = styled(SVG)`
  position: absolute;
  height: 51px;
  width: 51px;
  & path {
    fill: var(--StrokeInputColorSearchPage);
  }
`;
