import axiosConfig from "../../ApiCall/axiosConfig";
import {
  BottomHeader,
  Cart,
  Container,
  DivHeaderBtn,
  ImgCart,
  ImgHeaderBtn,
  TitleTopHeader,
  TopHeader,
} from "./HeaderElements";
import add from "../../Images/headerIcons/add.svg";
import addToReadyCart from "../../Images/cartPage/productsAddToReadyCart.svg";
import deleteCart from "../../Images/cartPage/deleteCart.svg";
import shoppingCart from "../../Images/cartPage/shoppingCart.svg";
import readyCart from "../../Images/cartPage/readyCart.svg";
import { useEffect, useState } from "react";
import {
  BtnBottomSheet,
  ButtonAddRaedyCart,
  CustomSheet,
  DivBtnBottomSheet,
  DivInput,
  DivRaedyCarts,
  ImgButton,
  ImgReadyCart,
  Input,
  RaedyCart,
} from "./Element";
import { Sheet } from "react-modal-sheet";
import bottomSheateReadyCartIcon from "../../Images/cartPage/bottomSheateReadyCart.svg";
import addIcon from "../../Images/add.svg";

const customTheme = "red";

const Header = ({
  countCart,
  changeCart,
  readyCartData,
  updateData,
  showError,
  data,
  shoppingCartItemsId,
}) => {
  const [title, setTitle] = useState("");
  const [items, setItems] = useState([]);
  const [readyCartItems, setReadyCartItems] = useState([]);

  const [isActive, setIsActive] = useState("shoppingCart");
  const [isOpen, setOpen] = useState(false);
  const [showAddReadyCartSheet, setShowAddReadyCartSheet] = useState(false);
  const [showDeleteCartSheet, setShowDeleteCartSheet] = useState(false);
  useEffect(() => {
    setItems(data);
    setReadyCartItems(readyCartData);
  }, [data, readyCartData]);

  const handleSelectCart = (cart) => {
    setIsActive(cart);
    changeCart(cart);
  };
  const handleChange = (e) => {
    setTitle(e.currentTarget.value);
  };

  const handleDeleteCart = async () => {
    setShowDeleteCartSheet(false);
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: shoppingCartItemsId,
    };
    try {
      await axiosConfig.delete("/shopping_cart/item/delete_multiple", config);
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
    }
    if (isOkResponse) {
      updateData("deleteCart");
    } else {
      showError();
    }
  };

  const handleAddShoppingCartToNewReadyCart = async () => {
    let itemsArray = [];
    setShowAddReadyCartSheet(false);
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const { data } = await axiosConfig.post(
        "/ready_cart/store",
        { title: title },
        config
      );
      if (isActive === "shoppingCart") {
        try {
          items?.items.forEach((item) => {
            itemsArray.push({
              ready_cart_id: data?.data?.id,
              company_id: item?.company_id,
              product_id: item?.product_id,
              quantity: item?.quantity,
            });
          });
          await axiosConfig.post("/ready_cart/item/store", itemsArray, config);
          isOkResponse = true;
        } catch (error) {
          isOkResponse = false;
        }
      } else {
        isOkResponse = true;
      }
    } catch (error) {
      isOkResponse = false;
    }
    if (isOkResponse) {
      setTitle("");
      if (isActive === "shoppingCart") {
        updateData("addShoppingCartToNewReadyCart");
      } else {
        updateData("addNewReadyCart");
      }
    } else {
      showError();
      setTitle("");
    }
  };
  const handleAddSoppingCartToReadyCart = async (readyCart) => {
    let itemsArray = [];
    setOpen(false);
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    items?.items.forEach((item) => {
      itemsArray.push({
        ready_cart_id: readyCart?.id,
        company_id: item?.company_id,
        product_id: item?.product_id,
        quantity: item?.quantity,
      });
    });
    try {
      await axiosConfig.post("/ready_cart/item/store", itemsArray, config);
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
    }
    if (isOkResponse) {
      updateData("addShoppingCartToReadyCart");
    } else {
      showError();
    }
  };

  return (
    <>
      <Container>
        <CustomSheet
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          detent="content-height"
          className={customTheme}
        >
          <Sheet.Container>
            <Sheet.Content>
              به کدام سبد آماده اضافه شود؟
              <Sheet.Scroller>
                {readyCartItems.length !== 0 && (
                  <DivRaedyCarts>
                    {readyCartItems.map((item, index) => (
                      <RaedyCart
                        key={index}
                        onClick={() => handleAddSoppingCartToReadyCart(item)}
                      >
                        <ImgReadyCart src={bottomSheateReadyCartIcon} />
                        <p>{item?.title}</p>
                      </RaedyCart>
                    ))}
                  </DivRaedyCarts>
                )}
                <ButtonAddRaedyCart
                  emptyReadyCart={readyCartItems.length === 0}
                  onClick={() => {
                    setOpen(false);
                    setShowAddReadyCartSheet(true);
                  }}
                >
                  <ImgButton src={addIcon} />
                  <p>ایجاد سبد آماده جدید</p>
                </ButtonAddRaedyCart>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onClick={() => setOpen(false)} />
        </CustomSheet>
        <CustomSheet
          isOpen={showAddReadyCartSheet}
          onClose={() => setShowAddReadyCartSheet(false)}
          detent="content-height"
          className={customTheme}
        >
          <Sheet.Container>
            <Sheet.Content>
              <Sheet.Scroller>
                <DivInput>
                  <ImgReadyCart src={bottomSheateReadyCartIcon} />
                  <Input
                    id="title"
                    placeholder="نام سبد خرید"
                    onChange={handleChange}
                    value={title}
                  />
                </DivInput>
                <ButtonAddRaedyCart
                  emptyReadyCart
                  onClick={() => {
                    title !== "" && handleAddShoppingCartToNewReadyCart();
                  }}
                >
                  {isActive === "shoppingCart"
                    ? "ایجاد و افزودن کالا ها به این سبد"
                    : "ایجاد سبد آماده جدید"}
                </ButtonAddRaedyCart>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onClick={() => setShowAddReadyCartSheet(false)} />
        </CustomSheet>
        <CustomSheet
          isOpen={showDeleteCartSheet}
          onClose={() => setShowDeleteCartSheet(false)}
          detent="content-height"
          className={customTheme}
        >
          <Sheet.Container>
            <Sheet.Content>
              <p>آیا مایل هستید که سبد خرید خود را خالی حذف شود؟</p>

              <Sheet.Scroller>
                <DivBtnBottomSheet>
                  <BtnBottomSheet
                    type="cancel"
                    onClick={() => {
                      setShowDeleteCartSheet(false);
                    }}
                  >
                    خیر
                  </BtnBottomSheet>
                  <BtnBottomSheet
                    type="ok"
                    onClick={() => {
                      handleDeleteCart();
                    }}
                  >
                    بله
                  </BtnBottomSheet>
                </DivBtnBottomSheet>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onClick={() => setShowDeleteCartSheet(false)} />
        </CustomSheet>

        <TopHeader>
          <DivHeaderBtn>
            <ImgHeaderBtn
              src={deleteCart}
              onClick={() => {
                setShowDeleteCartSheet(true);
              }}
            />
          </DivHeaderBtn>
          <TitleTopHeader>خرید ها</TitleTopHeader>
          {isActive === "shoppingCart" ? (
            <DivHeaderBtn>
              <ImgHeaderBtn
                src={addToReadyCart}
                onClick={() => {
                  setOpen(true);
                }}
              />
            </DivHeaderBtn>
          ) : (
            <DivHeaderBtn>
              <ImgHeaderBtn
                src={add}
                onClick={() => {
                  setShowAddReadyCartSheet(true);
                }}
              />
            </DivHeaderBtn>
          )}
        </TopHeader>
        <BottomHeader>
          <Cart
            active={isActive === "shoppingCart"}
            onClick={() => handleSelectCart("shoppingCart")}
          >
            <ImgCart active={isActive === "shoppingCart"} src={shoppingCart} />
            <p>سبد خرید</p>
            <div>{countCart}</div>
          </Cart>
          <Cart
            active={isActive === "readyCart"}
            onClick={() => handleSelectCart("readyCart")}
          >
            <ImgCart active={isActive === "readyCart"} src={readyCart} />
            <p>سبد آماده</p>
          </Cart>
        </BottomHeader>
      </Container>
    </>
  );
};

export default Header;
