import styled from "styled-components";
import SVG from "react-inlinesvg";

export const Container = styled.div`
  height: 100px;
  width: 100vw;
  text-align: center;
`;
export const Logo = styled(SVG)`
  height: 100%;
`;
