import styled from "styled-components";
import SVG from "react-inlinesvg";
import { Sheet } from "react-modal-sheet";
import { Link } from "react-router-dom";

export const Container = styled.div`
  /* margin-top: 138px; */
  height: calc(90vh - 39px);
  @supports (height: calc(90svh - 39px)) {
    height: calc(90svh - 39px);
  }

  width: 100%;
  display: flex;
  align-content: flex-start;
  gap: 23px;
  flex-wrap: wrap;
  overflow-x: hidden;
  margin-top: 29px;
  padding-bottom: 20px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const TextNoItems = styled.div`
  /* margin-top: 138px; */
  height: calc(90vh - 27px);
  @supports (height: calc(90svh - 27px)) {
    height: calc(90svh - 27px);
  }
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 11px;
    font-weight: 600;
  }
`;

export const DivSearch = styled.div`
  width: 85vw;
  height: 51px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  white-space: nowrap;
`;
export const DivInput = styled.div`
  position: relative;
  height: 51px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const Input = styled.input`
  padding-inline-start: 50px;
  padding-inline-end: 14px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 17px;
  border: 1px solid var(--searchStrokeColor);
  margin: 0;
  outline: none;
  &::placeholder {
    color: var(--placeholder);
    opacity: 1; /* Firefox */
  }
  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--placeholder);
  }
`;
export const IconInput = styled.img`
  position: absolute;
  height: 51px;
  width: 51px;
  & path {
    stroke: var(--firstColor);
  }
  & circle {
    stroke: var(--firstColor);
    fill: var(--firstColor);
  }
`;
export const ButtonFilter = styled.button`
  height: 51px;
  min-width: 51px;
  outline: none;
  border: none;
  background: var(--firstColor);
  border-radius: 17px;
`;
export const ImgFilter = styled.img`
  height: 51px;
  max-width: 51px;
`;
export const DivRaedyCarts = styled.div`
  width: 88vw;
  border: 1px solid var(--firstColor);
  border-radius: 17px 17px 0 0;
  background: white;
`;
export const RaedyCart = styled.div`
  position: relative;
  margin-inline: 30px;
  width: calc(100% - 60px);
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #6d4c10;
  border-bottom: 1px solid var(--separatorReadyCarts);

  &:last-child {
    border-bottom: none;
  }
  p {
    width: 100%;
    text-align: center;
    padding-inline: 42px;
  }
`;
export const ButtonAddRaedyCart = styled.div`
  width: 88vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: white;
  background: var(--firstColor);
  border-radius: ${({ emptyReadyCart }) =>
    emptyReadyCart ? "17px" : "0 0 17px 17px"};
  p {
    text-align: center;
    margin-left: 35px;
  }
`;
export const ImgButton = styled(SVG)``;

export const ImgReadyCart = styled(SVG)`
  position: absolute;
  right: 0;
  & path {
    &:nth-child(1) {
      stroke: var(--firstColor);
    }
    &:nth-child(3) {
      fill: var(--firstColor);
    }
    &:nth-child(4) {
      fill: var(--firstColor);
    }
  }
`;
export const DivItems = styled.div`
  width: 85vw;
  margin: auto;
  display: flex;
  align-content: flex-start;
  row-gap: 14px;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const Item = styled(Link)`
  font-size: 13px;
  width: 100%;
  position: relative;
  color: black;
  -webkit-tap-highlight-color: transparent; // حذف هایلایت آبی در دستگاه‌های لمسی
  transition: all 0.2s ease-in-out;
  &:active {
    filter: brightness(0.98);
  }
`;
export const Card = styled.img`
  height: 100%;
  width: 100%;
`;
export const ReadyCartItem = styled.div`
  font-size: 13px;
  width: 100%;
  position: relative;
  padding-inline: 2%;
`;
export const DivImg = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 51%;
  width: 24%;
  display: flex;
  background: white;
  margin-top: 1%;
  border-radius: 16%;
`;
export const Img = styled.img`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  border-radius: 16%;
  position: relative;
`;
export const DivDiscount = styled.div`
  border-radius: 11px;
  padding: 4px 6px;
  position: absolute;
  bottom: -17%;
  right: -12%;
  background: var(--secondColor);
  color: white;
  font-size: 10px;
  font-weight: 900;
  border: solid 4px var(--background);
`;
export const BtnReadyCart = styled.div`
  position: absolute;
  ${({ type }) =>
    (type === "delete" && "right:0;top: 3.5%;") ||
    (type === "addToShoppingCart" && "left:0;bottom: 4.5%;")};
  height: 30.2%;
  width: 10%;
  display: flex;
  background: ${({ type }) =>
    type === "delete" ? "var(--red)" : "var(--firstColor)"};
  border-radius: 42%;
`;
export const Btn = styled.div`
  position: absolute;
  left: 0;
  left: 0;
  top: 4%;
  height: 33%;
  width: 33.8%;
  display: flex;
  background: var(--firstColor);
  border-radius: 16px;
  color: white;
  font-size: 11px;
  font-style: normal;
  font-weight: 800;
  justify-content: center;
  align-items: center;
`;
export const DivQuantity = styled.div`
  position: absolute;
  left: 9.5%;
  bottom: 0;
  height: 20.9%;
  width: 9%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1%;
  justify-content: center;
`;
export const Quantity = styled.p`
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
`;
export const MeasureUnit = styled.p`
  font-size: 7px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: var(--measureUnitColor);
`;
export const DivReadyCartDetails = styled.div`
  position: absolute;
  right: 16%;
  top: 18%;
  width: 62%;
  display: flex;
  flex-direction: column;
`;
export const DivDetails = styled.div`
  position: absolute;
  right: 7%;
  top: 22%;
  width: 62%;
  display: flex;
  flex-direction: column;
`;
export const Title = styled.p`
  font-size: 13px;
  font-weight: 800;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const DivAvailable = styled.div`
  font-size: 10px;
  font-weight: 700;
  width: 100%;
  color: black;
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 4%;
`;
export const Available = styled.div`
  font-size: 10px;
  font-weight: 700;
  color: var(--green);
  display: flex;
  align-items: center;
`;
export const UnAvailable = styled.div`
  font-size: 10px;
  font-weight: 700;
  color: var(--red);
  display: flex;
  align-items: center;
`;
export const Separator = styled.div`
  border-right: 1px solid var(--gray);
  height: 14px;
`;

export const Company = styled.div`
  font-size: 10px;
  font-weight: 700;
  width: 100%;
  color: black;
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 4%;
  img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
  p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const CompanyDefualtIcon = styled(SVG)`
  height: 20px;
  width: 20px;
  & path {
    fill: var(--firstColor);
  }
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: 600;
  width: 140%;
  color: var(--gray);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 7%;
`;

export const Value = styled.div`
  height: 100%;
  background: var(--secondColor);
  padding-inline: 4px;
  border-radius: 7px;
  color: white;
  font-size: 10px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DetailItem = styled.div`
  position: absolute;
  inset-inline: 0.3vw;
  top: 58%;
  display: flex;
  row-gap: 5px;
  flex-direction: column;
  align-items: center;
`;
export const DivReadyCartPrice = styled.div`
  position: absolute;
  z-index: 0;
  bottom: 14%;
  inset-inline-start: 8%;
`;

export const DivPrice = styled.div`
  position: absolute;
  z-index: 0;
  bottom: 8%;
  inset-inline-start: 18%;
`;
export const Price = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: var(--placeholder);
  text-decoration-line: line-through;
  margin-inline: 4%;
`;
export const DivDiscountPrice = styled.div`
  font-size: 11px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-top: 2%;
`;
export const DiscountPrice = styled.span`
  font-size: 14px;
  font-weight: 900;
  color: ${({ type }) =>
    type === "unit" ? "var(--gray)" : "var(--firstColor)"};
  margin-inline-end: ${({ type }) => (type === "unit" ? "11px" : "4px")};
  display: flex;
  align-items: center;
`;
export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;
export const ContainerShowShoppingCart = styled.div`
  position: fixed;
  height: 20vh;
  @supports (height: 20svh) {
    height: 20svh;
  }
  width: 100vw;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(31.5px);
  -webkit-backdrop-filter: blur(31.5px);
  z-index: 0;
  transform: ${({ show }) =>
    show ? "translate(0%, 0%) scale(1)" : "translate(0%, 58%) scale(1)"};
  transition: transform 0.3s ease-in-out;
`;
export const DivShowShoppingCart = styled.div`
  height: 10vh;
  @supports (height: 10svh) {
    height: 10svh;
  }
  width: 87vw;
  margin-inline: auto;
  display: flex;
  align-items: center;
`;
export const ButtonShowShoppingCart = styled(Link)`
  height: 6vh;
  @supports (height: 6svh) {
    height: 6svh;
  }
  min-width: 62%;
  background: var(--secondColor);
  border-radius: 13px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  text-decoration: none;
`;
export const SumPrice = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  width: 100%;
  text-align: center;
`;
export const UnitSumPrice = styled.span`
  color: var(--secondColor);
  margin-inline-start: 5px;
`;
export const DivBtnBottomSheet = styled.div`
  height: 50px;
  width: 88vw;
  margin-inline: auto;
  display: flex;
  align-items: center;
  gap: 25px;
`;
export const BtnBottomSheet = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ type }) => (type === "ok" ? "var(--green)" : "var(--red)")};
  color: white;
  border-radius: 13px;
`;
export const CustomSheet = styled(Sheet)`
  .react-modal-sheet-backdrop {
    background-color: rgba(0, 0, 0, 0.4) !important;
  }
  .react-modal-sheet-container {
    border-radius: 0 !important;
    background: var(--background) !important;
    box-shadow: none !important;
  }
  .react-modal-sheet-scroller {
    flex-wrap: wrap;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .react-modal-sheet-content {
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 14px;
    align-items: center;
  }
`;
export const SpanContent = styled.span`
  color: ${({ type }) => (type === "add" ? "var(--green)" : "var(--red)")};
`;
