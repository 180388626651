import styled from "styled-components";
import SVG from "react-inlinesvg";
export const Container = styled.div`
  height: 73vh;
  @supports (height: 73svh) {
    height: 73svh;
  }
  width: 100%;
  display: flex;
  align-content: flex-start;
  gap: 23px;
  flex-wrap: wrap;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const DivItems = styled.div`
  width: 84vw;
  margin: auto;
  display: flex;
  align-content: flex-start;
  row-gap: 14px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 36px;
`;
export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  border-radius: 14px;
  transition: all 0.2s ease-in-out;
  &:active {
    filter: brightness(0.98);
  }
`;
export const Img = styled(SVG)`
  min-height: 54px;
  width: 54px;
  border-radius: 13px;
  margin-inline-start: 5px;
`;
export const Line = styled.div`
  width: 1px;
  height: 70%;
  flex-shrink: 0;
  border-radius: 2px;
  background: linear-gradient(
    180deg,
    rgba(243, 15, 15, 0.3) 0%,
    rgba(58, 35, 35, 0.3) 100%
  );
`;
export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  color: var(--middleFirstColor);
  margin-inline-start: 17px;
`;
export const Description = styled.p`
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
`;
