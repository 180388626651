import React, { useEffect, useState } from "react";
import Header from "../../Components/Categories/Header";
import Body from "../../Components/Categories/Body";
import axiosConfig from "../../ApiCall/axiosConfig";
import { useOutletContext } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const Index = () => {
  const [items, setItems] = useState([]);
  const [showBack, setShowBack] = useState(false);
  const [loading, setLoading] = useState(false);
  let { updateBottomNavigation } = useOutletContext();

  useEffect(() => {
    updateBottomNavigation(2);
    setLoading(true);
    let haveError = false;
    async function fetchData() {
      // Fetch categories
      async function fetchCategories() {
        const token = localStorage.getItem("tokenManiarApp");
        const params = new URLSearchParams([
          ["per_page", 1000],
          ["page", 1],
        ]);
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
          params,
        };
        try {
          const { data } = await axiosConfig.get(
            "/application/category/list",
            config
          );
          const itemsData = data.data;
          setItems(itemsData);
          setLoading(false);
        } catch (error) {
          console.log("Error fetching categories:", error);
          haveError = true;
        }
      }
      // Fetch cart
      // Execute fetchCategories first, then fetchProducts
      await fetchCategories();
      if (haveError) {
        showError();
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const selectCategory = (type) => {
    if (type === "selectCategory") {
      setShowBack(true);
    } else {
      setShowBack(false);
    }
  };
  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  const showErrorQuantity = (text) => {
    enqueueSnackbar(text, {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  return (
    <>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />
      <Header showBack={showBack} selectCategory={selectCategory} />
      {loading ? (
        <div className="loadingList">
          <div className="spinner"></div>
        </div>
      ) : (
        <Body
          data={items}
          selectCategory={selectCategory}
          showCategories={!showBack}
          showErrorQuantity={showErrorQuantity}
        />
      )}
    </>
  );
};

export default Index;
