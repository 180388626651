import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";
import {
  Container,
  Item,
  Title,
  DivItems,
  Img,
  DivImg,
  DivDetails,
  DivShowShoppingCart,
  ContainerShowShoppingCart,
  SumPrice,
  UnitSumPrice,
  Helper,
  BoxItems,
  ButtonNext,
  BoxCalendar,
  CustomSheet,
  ButtonCalendar,
  DivBtnBottomSheet,
} from "./Element";
import { Sheet } from "react-modal-sheet";
import { Calendar } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import "react-multi-date-picker/styles/layouts/mobile.css";
import moment from "jalali-moment";

import time from "../../Images/datePage/time.svg";
import calendar from "../../Images/datePage/calendar.svg";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const customTheme = "red";
const weekDays = [
  ["شنبه", "شنبه"], //[["نام","نام کوتاه"], ... ]
  ["یکشنبه", "یکشنبه"],
  ["دوشنبه", "دوشنبه"],
  ["سه شنبه", "سه شنبه"],
  ["چهارشنبه", "چهارشنبه"],
  ["پنجشنبه", "پنجشنبه"],
  ["جمعه", "جمعه"],
];
const Body = ({ data, sumPrice, invoice_id, showWarning, showError }) => {
  const [items, setItems] = useState([]);
  const [sum, setSum] = useState("");
  const [invoiceId, setInvoiceId] = useState(null);
  const [deliveryTimeId, setDeliveryTimeId] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [date, setDate] = useState(undefined);
  const [jalalyDate, setJalalyDate] = useState(undefined);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setItems(data);
    setSum(sumPrice);
    setInvoiceId(invoice_id);
  }, [data, sumPrice, invoice_id]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleNext = async () => {
    if (deliveryDate === null) {
      showWarning("لطفا تاریخ را انتخاب نمایید.");
    } else if (deliveryTimeId === null) {
      showWarning("لطفا بازه زمانی را انتخاب نمایید.");
    } else {
      let isOkResponse;
      let response;
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        response = await axiosConfig.put(
          "/invoice/date_time/update/" + invoiceId,
          {
            delivery_date: deliveryDate,
            delivery_time_id: deliveryTimeId,
          },
          config
        );
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
      }
      if (isOkResponse) {
        navigate("/app/payment", {
          state: {
            sumPrice: sumPrice,
            invoiceId: invoiceId,
            invoiceDetail: response?.data?.data,
          },
        });
        // navigate("/app/date");
      } else {
        showError();
      }
    }
  };
  const handleSelectTime = (item) => {
    setDeliveryTimeId(item?.id);
  };
  const handleChangeDate = () => {
    setOpen(false);
    setJalalyDate(date?.day + " " + date?.month?.name + " " + date?.year);
    const dateSelected = date.format().split("T");
    const jalaliDate = toEnglishDigits(dateSelected[0]); // Replace this with your Jalali date string
    const gregorianDate = moment(jalaliDate, "jYYYY/jMM/jDD")
      .locale("en")
      .format("YYYY-MM-DD");
    setDeliveryDate(gregorianDate);
  };
  const toEnglishDigits = (str) => {
    // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
    var e = "۰".charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function (t) {
      return t.charCodeAt(0) - e;
    });

    // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
    e = "٠".charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    return str;
  };
  return (
    <>
      <Container className={customTheme}>
        <CustomSheet
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          detent="content-height"
          className={customTheme}
        >
          <Sheet.Container>
            <Sheet.Content>
              <Sheet.Scroller>
                <Calendar
                  weekDays={weekDays}
                  calendar={persian}
                  locale={persian_fa}
                  className="rmdp-mobile"
                  monthYearSeparator=" "
                  value={date}
                  minDate={new Date(Date.now() + 48 * 60 * 60 * 1000)}
                  onChange={(val) => {
                    setDate(val);
                  }}
                />
                <DivBtnBottomSheet>
                  <div>
                    <ButtonCalendar
                      onClick={() => {
                        setOpen(false);
                        setDate(undefined);
                      }}
                    >
                      انصراف
                    </ButtonCalendar>
                    <ButtonCalendar
                      onClick={() => {
                        handleChangeDate();
                        // setDate(undefined);
                      }}
                    >
                      تایید
                    </ButtonCalendar>
                  </div>
                </DivBtnBottomSheet>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop
            onClick={() => {
              setOpen(false);
              setDate(undefined);
            }}
          />
        </CustomSheet>

        <Helper>لطفا تاریخ تحویل را انتخاب کنید.</Helper>
        <BoxCalendar>
          <DivItems>
            <Item active={jalalyDate} onClick={() => setOpen(true)}>
              <DivDetails>
                <DivImg active={jalalyDate}>
                  <Img src={calendar} />
                </DivImg>
                {jalalyDate ? (
                  <Title>{jalalyDate}</Title>
                ) : (
                  <Title>انتخاب تاریخ تحویل</Title>
                )}
              </DivDetails>
            </Item>
          </DivItems>
        </BoxCalendar>

        <Helper>لطفا بازه زمانی تحویل را انتخاب کنید.</Helper>
        <BoxItems>
          <DivItems showShoppingCart={items?.length !== 0}>
            {items?.map((item, index) => (
              <Item
                key={index}
                active={item?.id === deliveryTimeId}
                onClick={() => handleSelectTime(item)}
              >
                <DivDetails>
                  <DivImg active={item?.id === deliveryTimeId}>
                    <Img src={time} />
                  </DivImg>
                  <Title>{item?.title}</Title>
                </DivDetails>
              </Item>
            ))}
          </DivItems>
        </BoxItems>
      </Container>

      <ContainerShowShoppingCart show>
        <DivShowShoppingCart>
          <ButtonNext onClick={handleNext}>ادامه فرآیند خرید</ButtonNext>
          <SumPrice>
            {numberWithCommas(sum)}
            <UnitSumPrice>تومان</UnitSumPrice>
          </SumPrice>
        </DivShowShoppingCart>
      </ContainerShowShoppingCart>
    </>
  );
};

export default Body;
