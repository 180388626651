import React, { useEffect, useState } from "react";
import Header from "../../Components/Profile/Header";
import Body from "../../Components/Profile/Body";
import { useOutletContext } from "react-router-dom";

const Index = () => {
  const [user, setUser] = useState([]);
  let { updateBottomNavigation } = useOutletContext();

  useEffect(() => {
    updateBottomNavigation(4);
    setUser(JSON.parse(localStorage.getItem("userManiarApp")));
  }, []);
  return (
    <>
      <Header user={user} />
      <Body user={user} />
    </>
  );
};

export default Index;
