import React from "react";
import { MainContainer, Wrapper } from "./MainElements";
import { Outlet } from "react-router";
let customTheme = "red";

const SoftwareMain = ({ updateCountCart, updateBottomNavigation }) => {
  return (
    <MainContainer className={customTheme}>
      <Wrapper>
        <Outlet context={{ updateCountCart, updateBottomNavigation }} />
      </Wrapper>
    </MainContainer>
  );
};

export default SoftwareMain;
