import React, { useEffect, useState } from "react";
import Header from "../../../Components/Product/Header";
import Body from "../../../Components/Product/Body";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { useLocation } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { MainContainer } from "../../../Components/SoftwareMain/MainElements";
const customTheme = "red";

const Index = () => {
  const [items, setItems] = useState([]);
  const [cart, setCart] = useState("shoppingCart");
  const [title, setTitle] = useState("");
  const [detailsProduct, setDetailsProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingID] = useState(null);
  let location = useLocation();
  useEffect(() => {
    setTitle(location?.state?.title);
    setDetailsProduct(location?.state);
    setLoading(true);
    let haveError = false;
    async function fetchData() {
      let cartItems = [];
      async function fetchCart() {
        const token = localStorage.getItem("tokenManiarApp");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        try {
          const { data } = await axiosConfig.get(
            "/shopping_cart/item/list",
            config
          );
          setCart(data.data);
          localStorage.setItem("countCartItems", data.data?.items.length);
          cartItems = data.data;
        } catch (error) {
          console.log("Error fetching products:", error);
          haveError = true;
        }
      }
      async function fetchProduct() {
        const token = localStorage.getItem("tokenManiarApp");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        try {
          const { data } = await axiosConfig.get(
            "/application/product/single/" + location?.state?.id,
            config
          );
          let itemsData = data?.data?.companies;
          itemsData.forEach((item1) => {
            const match = cartItems?.items.find(
              (item2) =>
                item2.company_id === item1.id &&
                item2.product_id === data?.data?.id
            );
            if (match) {
              item1.quantity = match.quantity;
              item1.itemId = match.id;
            } else {
              item1.quantity = 0;
              item1.itemId = null;
            }
          });
          setItems(itemsData);
          setLoading(false);
        } catch (error) {
          console.log("Error fetching products:", error);
          haveError = true;
        }
      }
      await fetchCart();
      await fetchProduct();
      if (haveError) {
        showError();
        setLoading(false);
      }
    }
    fetchData();
  }, [location]);

  const updateData = (loadingId) => {
    async function fetchShoppingCart() {
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        const { data } = await axiosConfig.get(
          "/shopping_cart/item/list",
          config
        );
        setCart(data.data);
        let itemsData = items;
        itemsData.forEach((item1) => {
          const match = data.data?.items.find(
            (item2) =>
              item2.company_id === item1.id &&
              item2.product_id === item1?.detail?.product_id
          );
          if (match) {
            item1.quantity = match.quantity;
            item1.itemId = match.id;
          } else {
            item1.quantity = 0;
            item1.itemId = null;
          }
        });
        setItems(itemsData);
        localStorage.setItem("countCartItems", data.data?.items.length);
        setLoadingID(loadingId);
      } catch (error) {
        console.log("Error fetching products:", error);
        setLoadingID(loadingId);
        showError();
      }
    }
    fetchShoppingCart();
  };
  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  const showWarning = (text) => {
    enqueueSnackbar(text, {
      autoHideDuration: 2000,
      style: { backgroundColor: "#DC981B" },
    });
  };

  return (
    <MainContainer subPage className={customTheme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />
      <Header title={title} />
      {loading ? (
        <div className="loadingList">
          <div className="spinner"></div>
        </div>
      ) : (
        <Body
          data={items}
          cart={cart}
          showError={showError}
          showWarning={showWarning}
          updateData={updateData}
          detailsProduct={detailsProduct}
          loadingId={loadingId}
        />
      )}
    </MainContainer>
  );
};

export default Index;
