import React, { useEffect, useState } from "react";
import Header from "../../../Components/Address/HeaderAdd";
import Body from "../../../Components/Address/AddressBody";
import { useLocation } from "react-router-dom";
import { MainContainer } from "../../../Components/SoftwareMain/MainElements";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const customTheme = "red";

const Index = () => {
  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };
  const showWarning = (text) => {
    enqueueSnackbar(text, {
      autoHideDuration: 2000,
      style: { backgroundColor: "#DC981B" },
    });
  };
  return (
    <MainContainer subPage className={customTheme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />

      <Header />
      <Body showError={showError} showWarning={showWarning} />
    </MainContainer>
  );
};

export default Index;
