import styled from "styled-components";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

export const Container = styled.div`
  /* margin-top: 138px; */
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }

  background: var(--background);
  width: 100%;
  display: flex;
  align-content: flex-start;
  gap: 23px;
  flex-wrap: wrap;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const TextNoItems = styled.div`
  /* margin-top: 138px; */
  height: calc(90vh - 205px);
  @supports (height: calc(90svh - 205px)) {
    height: calc(90svh - 205px);
  }

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 11px;
    font-weight: 600;
  }
`;
export const Company = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  width: 85vw;
  margin-inline: auto;
`;
export const DivImg = styled.div`
  max-width: 85vw;
  min-width: 85vw;
  height: 40vh;
  margin-top: 2vh;
  margin-inline: auto;
  display: flex;
  border-radius: 8px;
`;
export const Img = styled.img`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
`;
export const DefualtImg = styled(SVG)`
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  border-radius: 50%;
  & path {
    fill: var(--firstColor);
  }
`;
export const MainDiv = styled.div`
  position: relative;
  margin-inline: auto;
`;
export const DivItems = styled.div`
  width: 85vw;
  height: 76px;
  display: flex;
  flex-direction: row;
  gap: 18px;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  white-space: nowrap; /* جلوگیری از شکستن خط */
  &::-webkit-scrollbar {
    display: none; /* مخفی کردن اسکرول بار در Webkit-based browsers */
  }
`;
export const DivItem = styled.div`
  min-width: 76px;
  max-width: 76px;
  height: 100%;
  background: white;
  border-radius: 8px;
  ${({ active }) => !active && "opacity: 0.5;"}
`;
export const Item = styled.img`
  min-height: 76px;
  max-height: 76px;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  ${({ active }) => active && "border:4px solid var(--secondColor);"}
`;
export const FadeDiv = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 27px;
  background: linear-gradient(90deg, #f7efef 0%, rgba(247, 239, 239, 0) 100%);
`;
export const MapBox = styled.div`
  max-width: 40.8vw;
  height: 48vh;
  font-size: 10px !important;
  background-color: var(--inputBackground);
  border-radius: 1.6vh;
  border: 0.11vw solid var(--inputStroke);
  margin: 0;
  overflow: hidden;
`;
export const Marker = styled(SVG)`
  width: 42px;
  height: 42px;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
  cursor: pointer;
  & path {
    fill: var(--firstColor);
  }
`;

export const DivMyLocation = styled.div`
  position: fixed;
  height: 50px;
  width: 50px;
  bottom: 278px;
  right: 22px;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
  padding: 8px;
  transition: all 0.2s ease-in-out;
  &:active {
    filter: brightness(0.75);
  }
`;

export const ContainerTextBoxAddress = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 20px 6vw;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(31.5px);
  -webkit-backdrop-filter: blur(31.5px);
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

export const DivSearch = styled.div`
  top: 79px;
  position: fixed;
  width: 100%;
  padding-inline: 7.5vw;
`;
export const BoxSearch = styled.div`
  top: 140px;
  position: fixed;
  width: 85vw;
  margin-inline: 7.5vw;
  max-height: 37vh;
  @supports (max-height: 37svh) {
    max-height: 37svh;
  }

  background: #b4b4b4;
  z-index: 1;
  border-radius: 17px;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const SearchItem = styled.div`
  height: 51px;
  width: 92%;
  margin-inline: 4%;
  display: flex;
  align-items: center;
  color: white;
  padding-inline: 2%;
  border-bottom: 1px dashed #d3d2cf;
  font-weight: 800;
  text-align: start;
  &:last-child {
    border-bottom: none;
  }
`;
export const Search = styled.input`
  padding-inline-start: 50px;
  padding-inline-end: 14px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  width: 100%;
  background-color: #fffcf6;
  border-radius: 17px;
  border: 1px solid #b4b4b4;
  margin: 0;
  outline: none;
  &::placeholder {
    color: #c7bdb4;
    opacity: 1; /* Firefox */
  }
  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #c7bdb4;
  }
`;
export const DivInput = styled.div`
  position: relative;
  height: 51px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 17px;
`;
export const Input = styled.input`
  padding-inline-start: 50px;
  padding-inline-end: 14px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 17px;
  border: 1px solid var(--searchStrokeColor);
  margin: 0;
  outline: none;
  &::placeholder {
    color: var(--placeholder);
    opacity: 1; /* Firefox */
  }
  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--placeholder);
  }
`;
export const DivTextarea = styled.div`
  position: relative;
  height: 87px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 17px;
`;
export const Textarea = styled.textarea`
  padding-inline-start: 50px;
  padding-inline-end: 14px;
  padding-block: 14px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 17px;
  border: 1px solid var(--searchStrokeColor);
  margin: 0;
  outline: none;
  resize: none;

  &::placeholder {
    color: var(--placeholder);
    opacity: 1; /* Firefox */
  }
  &::-ms-input-placeholder {
    /* Edge 12-18 */
    color: var(--placeholder);
  }
`;
export const IconInput = styled.img`
  position: absolute;
  top: 0;
  height: 51px;
  width: 51px;
  & path {
    stroke: var(--firstColor);
  }
  & circle {
    stroke: var(--firstColor);
    fill: var(--firstColor);
  }
`;
export const ButtonInsertAddress = styled.div`
  height: 6vh;
  @supports (height: 6svh) {
    height: 6svh;
  }

  min-width: 62%;
  background: var(--firstColor);
  border-radius: 13px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:active {
    filter: brightness(0.75);
  }
`;
