import React, { useEffect, useState } from "react";
import axiosConfig from "../../ApiCall/axiosConfig";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import Header from "../../Components/Search/Header";
import Body from "../../Components/Search/Body";
import { useOutletContext } from "react-router-dom";

const Index = () => {
  const [items, setItems] = useState([]);
  let { updateBottomNavigation } = useOutletContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    updateBottomNavigation(5);
  }, []);

  const handleSearch = (word) => {
    setLoading(true);
    async function fetchData() {
      const token = localStorage.getItem("tokenManiarApp");
      const params = new URLSearchParams([
        ["word", word],
        ["per_page", 1000],
        ["page", 1],
      ]);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
        params,
      };
      try {
        const { data } = await axiosConfig.get(
          "/application/product/list",
          config
        );
        const itemsData = data?.data?.data;
        setItems(itemsData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching products:", error);
        showError();
        setLoading(false);
      }
    }
    fetchData();
  };
  const showError = () => {
    enqueueSnackbar("خطا در اتصال به سرور", {
      autoHideDuration: 2000,
      style: { backgroundColor: "#9A2827" },
    });
  };

  return (
    <>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />
      <Header handleSearch={handleSearch} />
      {loading ? (
        <div className="loadingList">
          <div className="spinner"></div>
        </div>
      ) : (
        <Body data={items} />
      )}
    </>
  );
};

export default Index;
