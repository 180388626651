import React, { useEffect, useState } from "react";
// import ReactDOM from "react-dom";
// import Countdown, { CountdownApi } from "react-countdown";

import { useTranslation } from "react-i18next";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import axiosConfig from "../../ApiCall/axiosConfig";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { Slide } from "react-toastify";
import Cookies from "js-cookie";

import {
  BoxInputLogin,
  BoxLogin,
  BoxOrganization,
  Container,
  Input,
  DivInput,
  LogoOrganization,
  Btn,
  Footer,
  DivFooterTitle,
  InputPassword,
  BtnShowPassword,
  ImgShowPassword,
  DivInputPassword,
  IconInput,
  DivInputUsername,
  FooterTitle,
  PHelper,
  FooterRaw,
  FooterRawLink,
  BtnSendAgain,
  Mobile,
} from "./LoginElements";

import logo from "../../Images/whiteLogo.svg";
import enLogo from "../../Images/enWhiteLogo.svg";
import mobileIcon from "../../Images/inputIcons/mobile.svg";
import codeIcon from "../../Images/inputIcons/code.svg";
import { useNavigate } from "react-router-dom";

let customTheme = "red";
const Login = () => {
  const [mobile, setMobile] = useState("");
  const [code, setCode] = useState("");
  const [showVerifiedOtp, setShowVerifiedOtp] = useState(false);

  const currentLanguageCode = Cookies.get("i18next");

  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(0); // initial countdown time in seconds
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showSendAgainText, setShowSendAgainText] = useState(false);

  const [clickButtonEnabled, setClickButtonEnabled] = useState(true);
  const theme = "red"; // برای مثال، تم انتخاب‌شده را مشخص کنید

  useEffect(() => {
    // Exit early when we reach zero
    if (timeLeft === 0) {
      setIsButtonEnabled(true);
      setShowSendAgainText(true);
      return;
    }

    // Save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [timeLeft]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };
  const replacePersianNumbersWithEnglish = (text) => {
    if (!text) return "";
    return text.replace(/[۰-۹]/g, (char) =>
      String.fromCharCode(char.charCodeAt(0) - 1728)
    );
  };
  const handleChangeUsername = (e) => {
    setMobile(replacePersianNumbersWithEnglish(e.currentTarget.value));
  };

  const handleChangePassword = (e) => {
    setCode(replacePersianNumbersWithEnglish(e.currentTarget.value));
  };

  const handleClickButton = () => {
    if (!showVerifiedOtp && clickButtonEnabled) {
      setClickButtonEnabled(false);
      sendOtp(mobile);
    } else if (showVerifiedOtp && clickButtonEnabled) {
      setClickButtonEnabled(false);
      verifyOtp(mobile, code);
    }
  };

  const handleCorrectionNumber = () => {
    setShowVerifiedOtp(false);
    setCode("");
  };

  const handleSendAgain = () => {
    if (isButtonEnabled) {
      setIsButtonEnabled(false);
      sendOtp(mobile);
    }
  };
  const { t } = useTranslation();

  const sendOtp = async (mobile) => {
    if (mobile === "") {
      enqueueSnackbar("لطفا شماره موبایل خود را وارد نمایید .", {
        autoHideDuration: 2000,
        style: { backgroundColor: "#DC981B" },
      });
      setClickButtonEnabled(true);
    } else if (mobile.length !== 11) {
      enqueueSnackbar("لطفا درستی شماره موبایل خود را بررسی نمایید .", {
        autoHideDuration: 2000,
        style: { backgroundColor: "#DC981B" },
      });
      setClickButtonEnabled(true);
    } else {
      await axiosConfig
        .post("/application/send_otp", { mobile: mobile })
        .then(function (response) {
          if (response?.data?.success) {
            setIsButtonEnabled(false);
            setShowSendAgainText(false);
            setShowSendAgainText(false);
            setTimeLeft(90);
            setShowVerifiedOtp(true);
          } else {
            enqueueSnackbar("لطفا درستی شماره موبایل خود را بررسی نمایید .", {
              autoHideDuration: 2000,
              style: { backgroundColor: "#DC981B" },
            });
          }
          setClickButtonEnabled(true);
        })
        .catch(function (error) {
          console.log(error);
          enqueueSnackbar("خطا در اتصال به سرور", {
            autoHideDuration: 2000,
            style: { backgroundColor: "#9A2827" },
          });
          setClickButtonEnabled(true);
        });
    }
  };
  const verifyOtp = async (mobile, code) => {
    let isOkResponse;
    if (code === "") {
      enqueueSnackbar("کد وارد شده معتبر نیست .", {
        autoHideDuration: 2000,
        style: { backgroundColor: "#DC981B" },
      });
      setClickButtonEnabled(true);
    } else if (code.length !== 4) {
      enqueueSnackbar("کد وارد شده معتبر نیست .", {
        autoHideDuration: 2000,
        style: { backgroundColor: "#DC981B" },
      });
      setClickButtonEnabled(true);
    } else {
      await axiosConfig
        .post("/application/verify_otp", { mobile: mobile, code: code })
        .then(function (response) {
          if (response?.data?.success) {
            localStorage.setItem("tokenManiarApp", response.data.data.token);
            localStorage.setItem(
              "userManiarApp",
              JSON.stringify(response.data.data.user)
            );
            localStorage.setItem("themeManiarApp", "red");
            document.body.classList.add(theme);
            setIsButtonEnabled(false);
            setShowSendAgainText(false);
            setTimeLeft(90);
            setShowVerifiedOtp(true);
            navigate("/app/home");
          } else {
            enqueueSnackbar("کد وارد شده معتبر نیست .", {
              autoHideDuration: 2000,
              style: { backgroundColor: "#DC981B" },
            });
            isOkResponse = false;
          }
          setClickButtonEnabled(true);
        })
        .catch(function (error) {
          isOkResponse = false;
          console.log(error);
          enqueueSnackbar("خطا در اتصال به سرور", {
            autoHideDuration: 2000,
            style: { backgroundColor: "#9A2827" },
          });
          setClickButtonEnabled(true);
        });
    }
  };

  return (
    <>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        hideIconVariant={true}
        // preventDuplicate={true}
        dense={true}
        maxSnack={1}
        style={{
          fontSize: "14px",
          fontWeight: "900",
          background: "#DC981B",
          display: "flex",
          justifyContent: "center",
          height: "88px",
          borderRadius: "0",
        }}
      />

      <Container>
        <BoxLogin>
          <BoxOrganization>
            <LogoOrganization
              src={currentLanguageCode === "fa" ? logo : enLogo}
            />
          </BoxOrganization>
          <BoxInputLogin>
            {!showVerifiedOtp && (
              <PHelper>جهت ورود شماره موبایل خود را وارد نمایید .</PHelper>
            )}
            {showVerifiedOtp && (
              <PHelper>
                کد تایید به شماره <Mobile>{mobile}</Mobile> ارسال شد .
              </PHelper>
            )}

            {!showVerifiedOtp && (
              <DivInput>
                <DivInputUsername>
                  <IconInput src={mobileIcon} />
                  <Input
                    id="mobile"
                    placeholder="شماره موبایل"
                    maxLength={11}
                    inputMode="numeric"
                    value={mobile}
                    onChange={handleChangeUsername}
                  />
                </DivInputUsername>
              </DivInput>
            )}
            {showVerifiedOtp && (
              <DivInput>
                <DivInputPassword>
                  <IconInput src={codeIcon} />
                  <InputPassword
                    maxLength={4}
                    inputMode="numeric"
                    id="code"
                    placeholder="کد تایید"
                    value={code}
                    onChange={handleChangePassword}
                  />
                  <BtnSendAgain
                    disabledBtn={!isButtonEnabled}
                    onClick={handleSendAgain}
                  >
                    {!showSendAgainText && formatTime(timeLeft)}
                    {showSendAgainText && "ارسال مجدد"}
                  </BtnSendAgain>
                </DivInputPassword>
              </DivInput>
            )}
            {!showVerifiedOtp && (
              <DivInput>
                <Btn
                  onClick={handleClickButton}
                  disabledBtn={!clickButtonEnabled}
                  okBtn
                >
                  ارسال کد تایید
                </Btn>
              </DivInput>
            )}
            {showVerifiedOtp && (
              <DivInput>
                <Btn
                  onClick={handleClickButton}
                  disabledBtn={!clickButtonEnabled}
                  okBtn
                >
                  ورود
                </Btn>
                <Btn onClick={handleCorrectionNumber} correctionNumber>
                  تصحیح شماره
                </Btn>
              </DivInput>
            )}
          </BoxInputLogin>
        </BoxLogin>
        {/* <Footer>
          <DivFooterTitle>
            <FooterTitle>Royal Services Application</FooterTitle>
          </DivFooterTitle>
        </Footer> */}
        <Footer>
          <FooterRaw>
            با ثبت نام در مانیار <FooterRawLink>شرايط و قوانين</FooterRawLink>{" "}
            را می پذيرم.
          </FooterRaw>
        </Footer>
      </Container>
    </>
  );
};
export default Login;
