import styled from "styled-components";

export const MainContainer = styled.aside`
  width: 100vw;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  background: var(--background);
`;
export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-auto-columns: 100%;
  grid-template-rows: auto;
  align-items: start;
`;
