import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";
import {
  Container,
  Item,
  Title,
  DivItems,
  Img,
  DivImg,
  DivDetails,
  DivShowShoppingCart,
  ContainerShowShoppingCart,
  SumPrice,
  UnitSumPrice,
  Helper,
  BoxItems,
  ButtonNext,
  BoxCalendar,
  Input,
  DivInput,
  IconInput,
  DivDetailes,
  Detail,
} from "./Element";

import userIcon from "../../Images/peymentPage/user.svg";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const customTheme = "red";
const Body = ({ data, showWarning, showError }) => {
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [family, setFamily] = useState("");
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setUser(data);
    setName(data?.name);
    setFamily(data?.family);
  }, [data]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleChangeName = (e) => {
    setName(e.currentTarget.value);
  };
  const handleFamily = (e) => {
    setFamily(e.currentTarget.value);
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.put(
        "/application/user/update",
        {
          name: name,
          family: family,
        },
        config
      );
      user.name = name;
      user.family = family;
      localStorage.setItem("userManiarApp", JSON.stringify(user));
      navigate(-1);
    } catch (error) {
      showError();
    }
  };
  return (
    <>
      <Container className={customTheme}>
        <BoxCalendar>
          <DivItems>
            <DivInput>
              <IconInput src={userIcon} />
              <Input
                placeholder="نام"
                id="name"
                value={name}
                onChange={handleChangeName}
              />
            </DivInput>
            <DivInput>
              <IconInput src={userIcon} />
              <Input
                placeholder="نام خانوادگی"
                id="family"
                value={family}
                maxLength={11}
                inputMode="numeric"
                onChange={handleFamily}
              />
            </DivInput>
          </DivItems>
        </BoxCalendar>
      </Container>

      <ContainerShowShoppingCart show>
        <DivShowShoppingCart>
          <ButtonNext onClick={handleUpdate}>ویرایش اطلاعات فردی</ButtonNext>
        </DivShowShoppingCart>
      </ContainerShowShoppingCart>
    </>
  );
};

export default Body;
