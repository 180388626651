import {
  Container,
  DescriptionTopHeader,
  DivHeaderBtn,
  DivTitleTopHeader,
  ImgHeaderBtn,
  SvgHeaderBtn,
  TitleTopHeader,
  TopHeader,
} from "./HeaderElements";
import back from "../../Images/headerIcons/back.svg";
import add from "../../Images/headerIcons/add.svg";
import defualtLogo from "../../Images/defaultImage/deliveryCompany.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Img } from "./Element";

const Header = ({ title, description, page, logo }) => {
  const navigate = useNavigate();
  useEffect(() => {}, []);

  return (
    <>
      <Container>
        <TopHeader>
          <DivHeaderBtn onClick={() => navigate(-1)}>
            <ImgHeaderBtn src={back} />
          </DivHeaderBtn>
          <DivTitleTopHeader>
            <TitleTopHeader>ویرایش اطلاعات فردی</TitleTopHeader>
          </DivTitleTopHeader>
          <DivHeaderBtn to="add" hide>
            <ImgHeaderBtn src={add} />
          </DivHeaderBtn>
        </TopHeader>
      </Container>
    </>
  );
};

export default Header;
