import styled from "styled-components";
import SVG from "react-inlinesvg";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  text-align: center;
`;
export const DivBackgroundHeader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  padding-top: 2%;
  padding-inline-start: 14%;
`;
export const BackgroundHeader = styled.img`
  width: 100vw;
`;
export const DivImgProfile = styled.div`
  border: 2px #a91010;
  background: linear-gradient(174deg, #9f1d14 16.23%, #b72018 52.63%);
  height: 50%;
  width: 30%;
  border-radius: 50%;
`;
export const ImgProfile = styled(SVG)`
  width: 100%;
  height: 100%;
`;
export const DivProfileDetail = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  color: black;
  text-align: right;
  margin-inline-start: 8%;
  margin-top: 3%;
`;
export const Role = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  color: var(--roleColor);
  text-align: right;
`;
