import axiosConfig from "../../ApiCall/axiosConfig";
import { useTranslation } from "react-i18next";

import {
  Container,
  Item,
  Title,
  DivItems,
  Img,
  Price,
  DivImg,
  DivDetails,
  DivShowShoppingCart,
  ContainerShowShoppingCart,
  ButtonShowShoppingCart,
  SumPrice,
  UnitSumPrice,
  TextNoItems,
  DivTitle,
  Address,
  Helper,
  BoxItems,
  DivPrices,
  ButtonNext,
  Btn,
  Card,
  DivBtnBottomSheet,
  BtnBottomSheet,
  Icon,
} from "./Element";
import branch from "../../Images/addressPage/branch.svg";
import card from "../../Images/cards/address.svg";
import deleteIcon from "../../Images/delete.svg";
import { Sheet } from "react-modal-sheet";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomSheet, SpanContent } from "../Cart/Element";
const customTheme = "red";

const Body = ({
  data,
  sumPrice,
  shopping_cart_id,
  showWarning,
  showError,
  updateData,
}) => {
  const [items, setItems] = useState([]);
  const [sum, setSum] = useState("");
  const [branchId, setBranchId] = useState(null);
  const [shoppingCartId, setShoppingCartId] = useState(null);
  const [detailItem, setDetailItem] = useState([]);
  const [deleteSheet, setDeleteSheet] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    setItems(data);
    setSum(sumPrice);
    setShoppingCartId(shopping_cart_id);
  }, [data, sumPrice, shopping_cart_id]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleNext = async () => {
    let response;
    if (branchId === null) {
      showWarning("لطفا آدرس را انتخاب نمایید.");
    } else {
      let isOkResponse;
      const token = localStorage.getItem("tokenManiarApp");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
      try {
        response = await axiosConfig.post(
          "/invoice/store",
          {
            shopping_cart_id: shoppingCartId,
            branch_id: branchId,
          },
          config
        );
        isOkResponse = true;
      } catch (error) {
        isOkResponse = false;
      }
      if (isOkResponse) {
        navigate("/app/date", {
          state: {
            sumPrice: sumPrice,
            data: response?.data?.data,
          },
        });
      } else {
        showError();
      }
    }
  };
  const handleSelectBranch = (item) => {
    setBranchId(item?.id);
  };
  const handleDeleteBranch = async () => {
    setDeleteSheet(false);
    let isOkResponse;
    const token = localStorage.getItem("tokenManiarApp");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.delete("branch/delete/" + detailItem?.id, config);
      isOkResponse = true;
    } catch (error) {
      isOkResponse = false;
    }
    if (isOkResponse) {
      if (detailItem?.id === branchId) {
        setBranchId(null);
      }
      updateData();
    } else {
      showError();
    }
  };

  return (
    <>
      {items?.length === 0 ? (
        <TextNoItems>
          <p>هیچ آدرسی ثبت نشده است</p>
        </TextNoItems>
      ) : (
        <>
          <Container className={customTheme}>
            <CustomSheet
              isOpen={deleteSheet}
              onClose={() => setDeleteSheet(false)}
              detent="content-height"
              className={customTheme}
            >
              <Sheet.Container>
                <Sheet.Content>
                  <p>آیا مایل هستید که آدرس حذف شود؟</p>

                  <Sheet.Scroller>
                    <DivBtnBottomSheet>
                      <BtnBottomSheet
                        type="cancel"
                        onClick={() => {
                          setDeleteSheet(false);
                        }}
                      >
                        خیر
                      </BtnBottomSheet>
                      <BtnBottomSheet
                        type="ok"
                        onClick={() => {
                          handleDeleteBranch();
                        }}
                      >
                        بله
                      </BtnBottomSheet>
                    </DivBtnBottomSheet>
                  </Sheet.Scroller>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop onClick={() => setDeleteSheet(false)} />
            </CustomSheet>

            <Helper>لطفا آدرس محل تحویل مرسوله را انتخاب کنید.</Helper>
            <BoxItems>
              <DivItems showShoppingCart={items?.length !== 0}>
                {items?.map((item, index) => (
                  <Item
                    key={index}
                    active={item?.id === branchId}
                    onClick={() => handleSelectBranch(item)}
                  >
                    <Card src={card} active={item?.id === branchId} />
                    <DivDetails>
                      <div>
                        <Btn
                          onClick={(e) => {
                            e.stopPropagation();
                            setDetailItem(item);
                            setDeleteSheet(true);
                          }}
                        >
                          <Icon src={deleteIcon} />
                        </Btn>
                        <DivTitle>
                          <DivImg>
                            <Img src={branch} />
                          </DivImg>
                          <Title>{item?.title}</Title>
                        </DivTitle>
                      </div>
                      <Address>{item?.address}</Address>
                    </DivDetails>
                  </Item>
                ))}
              </DivItems>
            </BoxItems>
            <DivPrices>
              <Price>
                <lable>قیمت کل :</lable>
                <p>
                  {numberWithCommas(sum)}
                  <span>تومان</span>
                </p>
              </Price>
              <Price>
                <lable>هزینه ارسال :</lable>
                <p>
                  0<span>تومان</span>
                </p>
              </Price>
              <tr />
              <Price final>
                <lable>قیمت نهایی :</lable>
                <p>
                  {numberWithCommas(sum)}
                  <span>تومان</span>
                </p>
              </Price>
            </DivPrices>
          </Container>

          <ContainerShowShoppingCart show>
            <DivShowShoppingCart>
              <ButtonNext onClick={handleNext}>ادامه فرآیند خرید</ButtonNext>
              <SumPrice>
                {numberWithCommas(sum)}
                <UnitSumPrice>تومان</UnitSumPrice>
              </SumPrice>
            </DivShowShoppingCart>
          </ContainerShowShoppingCart>
        </>
      )}
    </>
  );
};

export default Body;
